/****************************************************************************************************
 * Provides ready-to-use GenericCriteria and BuilderGenericCriteria objects.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import GcOperatorModel from './gc-operators.model';

export const INTEGER_MIN_VALUE = 0;
export const INTEGER_MAX_VALUE = 65535;

export const DEFAULT_DISPLAY_ORDER = 100000;

function getRandomInteger (min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

function getUniqueKey () {
    return 'bgc-' + new Date().getTime() + '-' + getRandomInteger(1, 100000);
}

/**
 * GenericCriteria structure.
 */
const Gc = {
    type: null,
    field: '',
    lang: null,
    operator: null,
    value: null,
    value2: null,
    children: []
};

/**
 * BuilderGenericCriteria structure.
 */
const Bgc = {
    ...Gc,
    specific: {
        /**
         * The PropertyDefinition name.
         * It also can be an Entity's property name.
         *
         * Required for specific operations.
         * It may represent different things and it's up to the source to use this properly.
         * NOTICE. Cannot guarantee that will exist.
         */
        name: '',

        /**
         * Human readable name - label.
         *
         * Usually the PropertyDefinition label.
         */
        label: '',

        /**
         * Usually the PropertyDefinition displayOrder.
         */
        displayOrder: DEFAULT_DISPLAY_ORDER,

        /**
         * The group name.
         *
         * Usually the PropertyGroup name.
         */
        group: '',

        /**
         * The group label.
         *
         * Usually the PropertyGroup label.
         */
        groupLabel: '',

        /**
         * Usually the PropertyGroup displayOrder.
         */
        groupDisplayOrder: DEFAULT_DISPLAY_ORDER,

        /**
         * The UI control (if this GC supports more than one controls).
         */
        control: 'input',

        /**
         * The foreignType of the foreignKey.
         * It is used by components to request remotely the entity for the provided input.
         */
        foreignType: null,

        /**
         * Operators options.
         * Each option must have at least 'label' and 'value'.
         * 'value' must be unique.
         */
        operators: [],

        /**
         * Options in case of select.
         * Each option must have at least 'label' and 'value'.
         * 'value' must be unique.
         */
        options: [],

        /**
         * Min value in case of number.
         */
        minValue: INTEGER_MIN_VALUE,

        /**
         * Max value in case of number.
         */
        maxValue: INTEGER_MAX_VALUE,

        /**
         * A unique key for reactivity purposes.
         */
        rId: null
    }
};

const GcModel = {};

GcModel.getBgcCommonGroup = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_GROUP';
    bgc.operator = GcOperatorModel.getAndOperatorAsAllValue();
    bgc.specific.operators = GcOperatorModel.getOperatorsForGroup();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_String = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = 'STRING';
    bgc.operator = GcOperatorModel.getContainOperatorValue();
    bgc.value = '';
    bgc.specific.operators = GcOperatorModel.getOperatorsForString();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_StringAndSelect = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = 'STRING';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.specific.control = 'select';
    bgc.specific.operators = GcOperatorModel.getOperatorsForSelect();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_Integer = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = 'INTEGER';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.value = 0;
    bgc.specific.operators = GcOperatorModel.getOperatorsForNumber();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_Decimal = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = 'DECIMAL';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.value = 0.0;
    bgc.specific.operators = GcOperatorModel.getOperatorsForNumber();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_Boolean = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = 'BOOLEAN';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.value = false;
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonFilter_DateOrDateTime = function (dateType = 'DATE') {
    if (dateType !== 'DATE' && dateType !== 'DATETIME') dateType = 'DATE';
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_FILTER';
    bgc.lang = dateType;
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.value = null;
    bgc.specific.operators = GcOperatorModel.getOperatorsForDateAndDateTime();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonRangeFilter_Integer = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_RANGE_FILTER';
    bgc.lang = 'INTEGER';
    bgc.operator = GcOperatorModel.getBetweenOperatorValue();
    bgc.value = 0;
    bgc.value2 = 0;
    bgc.specific.operators = GcOperatorModel.getOperatorsForRange();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonRangeFilter_Decimal = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_RANGE_FILTER';
    bgc.lang = 'DECIMAL';
    bgc.operator = GcOperatorModel.getBetweenOperatorValue();
    bgc.value = 0.0;
    bgc.value2 = 0.0;
    bgc.specific.operators = GcOperatorModel.getOperatorsForRange();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonRangeFilter_DateOrDateTime = function (dateType = 'DATE') {
    if (dateType !== 'DATE' && dateType !== 'DATETIME') dateType = 'DATE';
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_RANGE_FILTER';
    bgc.lang = dateType;
    bgc.operator = GcOperatorModel.getBetweenOperatorValue();
    bgc.value = null;
    bgc.value2 = null;
    bgc.specific.operators = GcOperatorModel.getOperatorsForRange();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommonArrayFilter_String = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMON_ARRAY_FILTER';
    bgc.lang = 'STRING';
    bgc.operator = GcOperatorModel.getAllOperatorValue();
    bgc.value = [];
    bgc.specific.operators = GcOperatorModel.getOperatorsForArray();
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getBgcCommunicationSubscriptionFilter = function () {
    const bgc = cloneDeep(Bgc);
    bgc.type = 'COMMUNICATION_SUBSCRIPTION_FILTER';
    // This is invalid. But it's expected that user will change it.
    // If not, it does not affect the search.
    bgc.value = null;
    bgc.value2 = true;
    bgc.specific.rId = getUniqueKey();
    return bgc;
};

GcModel.getUniqueRId = function () {
    return getUniqueKey();
};

export default GcModel;
