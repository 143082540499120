/**
 * People module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 3 July 2020
 */
const routes = [
    {
        path: '/:tenantId/people',
        name: 'people',
        redirect: {
            name: 'people-workspace'
        }
    },
    {
        path: '/:tenantId/people/workspace/:id?',
        name: 'people-workspace',
        component: () => import(/* webpackChunkName: "people" */ './views/PeopleWorkspaceView')
    }
];

export default routes;
