/**
 * Person module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
const routes = [
    {
        path: '/:tenantId/people/person/:id',
        name: 'person',
        component: () => import(/* webpackChunkName: "person" */ '@/modules/Person/views/PersonView.vue')
    }
];

export default routes;
