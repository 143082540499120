/****************************************************************************************************
 * Operations sub-module for SmsCampaign module
 * - Implements CRUD operations for SmsCampaign model.
 * - After CRUD operations, actions dispatch other actions in order to sync information across sub-modules.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { SmsCampaignService } from '@/common/services/api.service';

const state = {
    smsCampaign: null,
    // State Indicators //////////
    smsCampaignGetting: false,
    smsCampaignDeleting: false,
    smsCampaignUpdating: false,
    // Status //////////
    smsCampaignNotFound: false,
    // Errors //////////
    smsCampaignGetError: null,
    smsCampaignDeleteError: null,
    smsCampaignUpdateError: null
};

const getters = {
    smsCampaign (state) {
        return state.smsCampaign;
    },
    // State Indicators //////////
    smsCampaignGetting (state) {
        return state.smsCampaignGetting;
    },
    smsCampaignDeleting (state) {
        return state.smsCampaignDeleting;
    },
    smsCampaignUpdating (state) {
        return state.smsCampaignUpdating;
    },
    // Status //////////
    smsCampaignNotFound (state) {
        return state.smsCampaignNotFound;
    },
    // Errors //////////
    smsCampaignGetError (state) {
        return state.smsCampaignGetError;
    },
    smsCampaignDeleteError (state) {
        return state.smsCampaignDeleteError;
    },
    smsCampaignUpdateError (state) {
        return state.smsCampaignUpdateError;
    }
};

const actions = {
    async synchronizeDraftSmsCampaign ({ dispatch, commit, state }) {
        // Check if smsCampaign is DRAFT.
        if (state.smsCampaign.status !== 'DRAFT') {
            return;
        }

        // Get extra data (Broadcaster, Workspace, Workspace Count)
        // All actions handle the fails.
        // But they do not change SmsCampaign instance in any case.
        const broadcasterResult = await dispatch('getBroadcaster').then(() => true).catch(() => false);
        const workspaceResult = await dispatch('getWorkspace').then(() => true).catch(() => false);

        // If broadcaster is null, reset broadcasterId from smsCampaign instance.
        if (broadcasterResult === false) {
            commit('setSmsCampaignField', {
                field: 'broadcasterId',
                value: null
            });
        }

        // If workspace is null, reset workspaceId from smsCampaign instance.
        if (workspaceResult === false) {
            commit('setSmsCampaignField', {
                field: 'workspaceId',
                value: null
            });
        }

        // @future : Ensure that .then(() => void 0).catch(() => void 0) exists in all promises (use this only when results handled from the caller!).
        await dispatch('countWorkspace', true).then(() => void 0).catch(() => void 0); // Count total people.
        await dispatch('countWorkspace', false).then(() => void 0).catch(() => void 0); // Count subscribed people.
        await dispatch('getUnitBalanceBalance').then(() => void 0).catch(() => void 0); // Get current Unit Balance.
        await dispatch('validateSmsCampaignLocally');
    },
    getSmsCampaign ({ dispatch, commit, state }, { id }) {
        // Start loader.
        commit('setSmsCampaignGetting', true);

        // Reset state indicators and statuses.
        commit('setSmsCampaignNotFound', false);
        // @future : Proposition for UI/UX improvement: Perform reset in here and not in then-catch? Maybe yes!

        // Get SmsCampaign.
        return SmsCampaignService.getSmsCampaign(id)
            .then(({ data }) => {
                // Set data.
                commit('setSmsCampaign', data);
                commit('setSmsCampaignGetError', null);
                commit('setSmsCampaignNotFound', false);

                // Sync.
                dispatch('synchronizeDraftSmsCampaign');

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                commit('setSmsCampaign', null);
                commit('setSmsCampaignGetError', reason);
                commit('setSmsCampaignNotFound', true);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setSmsCampaignGetting', false);
            });
    },
    updateSmsCampaign ({ dispatch, commit, state }) {
        // Start loader.
        commit('setSmsCampaignUpdating', true);

        // Update SmsCampaign.
        return SmsCampaignService.updateSmsCampaign(state.smsCampaign)
            .then(({ data }) => {
                // Set data.
                commit('setSmsCampaign', data);
                commit('setSmsCampaignUpdateError', null);

                // Sync.
                dispatch('synchronizeDraftSmsCampaign');

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                // commit('setSmsCampaign', null);
                commit('setSmsCampaignUpdateError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setSmsCampaignUpdating', false);
            });
    },
    deleteSmsCampaign ({ dispatch, commit, state }) {
        // Start loader.
        commit('setSmsCampaignDeleting', true);

        // @future : Validate smsCampaign and id? I think that it is not necessary.

        // Reset data.
        commit('setSmsCampaignDeleteError', null);

        // Delete SmsCampaign.
        return SmsCampaignService.deleteSmsCampaign(state.smsCampaign.id)
            .then(() => {
                // Reset store.
                dispatch('resetModule');
                return Promise.resolve();
            })
            .catch((reason) => {
                commit('setSmsCampaignDeleteError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setSmsCampaignDeleting', false);
            });
    },
    resetOperationsSubModule ({ commit }) {
        commit('setSmsCampaign', null);
        // State Indicators
        commit('setSmsCampaignGetting', false);
        commit('setSmsCampaignDeleting', false);
        commit('setSmsCampaignUpdating', false);
        // Status
        commit('setSmsCampaignNotFound', false);
        // Errors
        commit('setSmsCampaignGetError', null);
        commit('setSmsCampaignDeleteError', null);
        commit('setSmsCampaignUpdateError', null);
    }
};

const mutations = {
    setSmsCampaign (state, data) {
        Vue.set(state, 'smsCampaign', data);
    },
    // Mutations for SmsCampaign fields //////////
    setSmsCampaignField (state, { field, value }) {
        Vue.set(state.smsCampaign, field, value);
    },
    setSmsCampaignSettingsField (state, { field, value }) {
        // @future : Check smsCampaign?
        Vue.set(state.smsCampaign.settings, field, value);
    },
    // State Indicators //////////
    setSmsCampaignGetting (state, data) {
        Vue.set(state, 'smsCampaignGetting', data);
    },
    setSmsCampaignDeleting (state, data) {
        Vue.set(state, 'smsCampaignDeleting', data);
    },
    setSmsCampaignUpdating (state, data) {
        Vue.set(state, 'smsCampaignUpdating', data);
    },
    // Status //////////
    setSmsCampaignNotFound (state, data) {
        Vue.set(state, 'smsCampaignNotFound', data);
    },
    // Errors //////////
    setSmsCampaignGetError (state, data) {
        Vue.set(state, 'smsCampaignGetError', data);
    },
    setSmsCampaignDeleteError (state, data) {
        Vue.set(state, 'smsCampaignDeleteError', data);
    },
    setSmsCampaignUpdateError (state, data) {
        Vue.set(state, 'smsCampaignUpdateError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
