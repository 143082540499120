/****************************************************************************************************
 * Workspace sub-module for EmailCampaign module
 * - Handles the Workspace instance during editing
 * Assumes DRAFT EmailCampaign (!)
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import {
    WorkspaceService,
    WorkspacePeopleService
} from '@/common/services/api.service';

const COMMUNICATION_SUBSCRIPTION_TYPE = 'MARKETING_EMAIL';

const state = {
    workspace: null,
    workspaceError: null,
    workspaceLoading: false,
    // Counts //////////
    workspaceCountTotal: null,
    workspaceCountSubscribed: null // Subscribed to email campaign (MARKETING_EMAIL)
};

const getters = {
    workspace (state) {
        return state.workspace;
    },
    workspaceError (state) {
        return state.workspaceError;
    },
    workspaceLoading (state) {
        return state.workspaceLoading;
    },
    // Counts //////////
    workspaceCountTotal (state) {
        return state.workspaceCountTotal;
    },
    workspaceCountSubscribed (state) {
        return state.workspaceCountSubscribed;
    }
};

const actions = {
    getWorkspace ({ commit, state }) {
        commit('setWorkspaceLoading', true);

        // Get workspaceId.
        const workspaceId = state.emailCampaign.workspaceId;

        // Check emailCampaign status.
        if (state.emailCampaign.status !== 'DRAFT') {
            // @future @important : We shall add a mapper that converts error to error.model.
            return Promise.reject(new Error('Email Campaign is not Draft'));
        }

        // Check if workspaceId and reset.
        // This is accepted behavior. For example user resets workspace.
        if (workspaceId === null) {
            commit('setWorkspace', null);
            commit('setWorkspaceError', null);
            commit('setWorkspaceLoading', false);

            // @future @important : We shall add a mapper that converts error to error.model.
            return Promise.reject(new Error('Email Campaign Workspace ID is null'));
        }

        // Get Workspace.
        return WorkspaceService.getWorkspace(workspaceId)
            .then(({ data }) => {
                // Set new data and reset error.
                commit('setWorkspace', data);
                commit('setWorkspaceError', null);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setWorkspace', null);
                commit('setWorkspaceError', error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setWorkspaceLoading', false);
            });
    },
    countWorkspace ({ commit, state }, total = true) {
        // Get workspaceId.
        const workspaceId = state.emailCampaign.workspaceId;
        let communicationSubscriptionType = null;
        if (total === false) {
            communicationSubscriptionType = COMMUNICATION_SUBSCRIPTION_TYPE;
        }

        // Check if workspaceId and reset.
        // This is accepted behavior. For example user resets workspace.
        if (workspaceId === null) {
            if (total === true) {
                commit('setWorkspaceCountTotal', null);
            } else {
                commit('setWorkspaceCountSubscribed', null);
            }
            return Promise.resolve(null);
        }

        // Get Workspace.
        return WorkspacePeopleService.countWorkspace(workspaceId, communicationSubscriptionType)
            .then(({ data }) => {
                if (total === true) {
                    commit('setWorkspaceCountTotal', data);
                } else {
                    commit('setWorkspaceCountSubscribed', data);
                }
                return Promise.resolve(data);
            })
            .catch((reason) => {
                if (total === true) {
                    commit('setWorkspaceCountTotal', null);
                } else {
                    commit('setWorkspaceCountSubscribed', null);
                }
                return Promise.reject(reason);
            });
    },
    resetWorkspaceSubModule ({ commit }) {
        commit('setWorkspace', null);
        commit('setWorkspaceError', null);
        commit('setWorkspaceLoading', false);
        // Counts
        commit('setWorkspaceCountTotal', null);
        commit('setWorkspaceCountSubscribed', null);
    }
};

const mutations = {
    setWorkspace (state, data) {
        Vue.set(state, 'workspace', data);
    },
    setWorkspaceError (state, data) {
        Vue.set(state, 'workspaceError', data);
    },
    setWorkspaceLoading (state, data) {
        Vue.set(state, 'workspaceLoading', data);
    },
    // Counts //////////
    setWorkspaceCountTotal (state, data) {
        Vue.set(state, 'workspaceCountTotal', data);
    },
    setWorkspaceCountSubscribed (state, data) {
        Vue.set(state, 'workspaceCountSubscribed', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
