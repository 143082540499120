/****************************************************************************************************
 * Sorting sub-module of people store module.
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';

// State.
const state = {
    // available fields for sorting are provided on view load
    // Source provides fields with all necessary details such as path, translated label, etc.
    sortingAvailableFieldOptions: [],
    sortingAvailableDirectionOptions: [
        'ASC',
        'DESC'
    ]
};

// Getters.
const getters = {
    sortingSorters (state) {
        return state.workspaceWorking.searchDetails.sorters;
    },
    sortingEnabledFieldOptions (state) {
        return state.sortingAvailableFieldOptions.filter(item => item.used === false);
    },
    sortingEnabledDirectionOptions (state) {
        return state.sortingAvailableDirectionOptions;
    }
};

// Actions.
const actions = {
    ensureSortingV0SubModule ({ state, commit }, data) {
        if (state.sortingAvailableFieldOptions.length > 0) {
            // no need to set (already set).
            commit('sortingSyncAvailableFieldOptions');
        } else {
            commit('sortingSetSortingAvailableFieldOptions', data);
            // no need to sync.
        }
    },
    sortingAddSorter ({ dispatch, commit, state }, sorter) {
        commit('sortingAddSorter', sorter); // Add sorter.
        commit('sortingSyncAvailableFieldOptions'); // Sync the available field options.
        return dispatch('searchPeople'); // Search people.
    },
    sortingRemoveSorterByIndex ({ dispatch, commit, state }, index) {
        commit('sortingRemoveSorterByIndex', index); // Remove sorter.
        commit('sortingSyncAvailableFieldOptions'); // Sync the available field options.
        return dispatch('searchPeople'); // Search people.
    }
};

// Mutations.
const mutations = {
    sortingSetSortingAvailableFieldOptions (state, data) {
        Vue.set(state, 'sortingAvailableFieldOptions', data);
    },
    sortingAddSorter (state, { field, direction }) {
        if (typeof field !== 'string') return;
        if (typeof direction !== 'string') return;
        if (field === '') return;
        if (direction !== 'ASC' && direction !== 'DESC') return;
        // @future : Check if sorter with this field exists.
        // Clone list to ensure reactivity, add sorter and reassign.
        const list = cloneDeep(state.workspaceWorking.searchDetails.sorters);
        list.push(cloneDeep({
            field: field,
            direction: direction
        }));
        Vue.set(state.workspaceWorking.searchDetails, 'sorters', list);
    },
    sortingRemoveSorterByIndex (state, index) {
        if (typeof index !== 'number') return;
        if (index < 0) return;
        // @future : Check if index is in range...
        // Clone list to ensure reactivity, remove sorter and reassign.
        // Finally, remove sorter and ensure reactivity.
        const list = cloneDeep(state.workspaceWorking.searchDetails.sorters);
        list.splice(index, 1);
        Vue.set(state.workspaceWorking.searchDetails, 'sorters', list);
    },
    sortingSyncAvailableFieldOptions (state) {
        const availableFieldOptionsList = cloneDeep(state.sortingAvailableFieldOptions);
        const sortersList = cloneDeep(state.workspaceWorking.searchDetails.sorters);
        const sortersFieldsList = sortersList.map(item => item.field);
        const availableFieldOptionsListNew = availableFieldOptionsList.map((item) => {
            // Check if this field exists in sorters list and change the 'used' field.
            item.used = sortersFieldsList.includes(item.field);
            return item;
        });
        Vue.set(state, 'sortingAvailableFieldOptions', availableFieldOptionsListNew);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
