/* eslint-disable no-useless-escape */
const ValidationUtils = {};

/**
 * Validates an email.
 *
 * @see : https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 *
 * @param email the email to validate.
 * @returns {boolean} true if valid, false if invalid.
 */
ValidationUtils.validateEmail = function (email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export default ValidationUtils;
