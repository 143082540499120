<template>
    <div class="ViewContainer">
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/**
 * Basic container for views main.
 * For fluid layout use: "container-fluid for-view"
 *
 * @author Dimitris Gkoulis
 * @lastModifiedAt 24 June 2020 (switched to max width container)
 */
export default {
    name: 'ViewContainer'
};
</script>
