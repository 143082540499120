<template>
    <div class="EmailTemplateCreator position-relative">
        <element-loading :active="loading" :lock-parent="true"></element-loading>
        <el-form :model="fields"
                 :rules="fieldsRules"
                 ref="newInstanceForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 @submit.native.prevent>
            <el-form-item :label="$t('Name')" prop="name" for="nameGcEmailTemplateCreator">
                <el-input id="nameGcEmailTemplateCreator" v-model="fields.name"></el-input>
            </el-form-item>
            <!--
            <el-form-item :label="$t('Description')" prop="description" for="descriptionGcEmailTemplateCreator">
                <el-input id="descriptionGcEmailTemplateCreator" type="textarea" v-model="fields.description"></el-input>
            </el-form-item>
            -->
            <el-form-item :label="$t('Type')" prop="editor" for="typeGcEmailTemplateCreator">
                <el-select id="typeGcEmailTemplateCreator" class="w-100" v-model="fields.editor" :placeholder="$t('Type')">
                    <el-option :label="$t('HTML Editor')" value="HTML">{{ $t('HTML Editor') }}</el-option>
                    <el-option :label="$t('Builder')" value="BUILDER">{{ $t('Builder') }}</el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="mt-2 mb-0">
                <button type="button" class="btn btn-primary btn-block" @click="submitForm(true)">{{ $t('common_action_create') }}</button>
                <button type="button" class="btn btn-secondary btn-block" @click="cancelForm" v-if="displayCancel">{{ $t('common_action_cancel') }}</button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { EmailTemplateService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import DomainModel from '@/common/logic/domain-models';

/**
 * EmailTemplate Creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 2 November 2020
 */
export default {
    name: 'EmailTemplateCreator',
    props: {
        displayCancel: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            loading: false,
            fields: {
                name: '',
                description: '',
                editor: 'BUILDER'
            },
            fieldsRules: {
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                description: [],
                editor: [
                    ElValidationUtils.requiredValidator()
                ]
            }
        };
    },
    methods: {
        prepareEmailTemplateFromForm () {
            let instance = DomainModel.getEmailTemplate();
            instance.name = this.fields.name;
            instance.description = this.fields.description;
            instance.editor = this.fields.editor;
            return instance;
        },
        saveInstance (redirect) {
            let instanceToSave = this.prepareEmailTemplateFromForm();
            EmailTemplateService.createEmailTemplate(instanceToSave)
                .then(({ data }) => {
                    // Refresh data in store.
                    this.$store.dispatch('emailTemplates/getData');
                    // Update EntityQlu for this entity.
                    // 2020-03-13-@future-reduce-scattered-calls.
                    this.$store.dispatch('application/quotasFindOneEntityQlu', 'emailTemplate');
                    // Reset form.
                    this.resetForm();
                    this.toastDefault('Email Template created');
                    // Send signal to active listeners to perform their next actions.
                    this.$emit('create-success', data.id);
                    if (redirect === true) {
                        this.$router.push({ name: 'email-template', params: { id: data.id } });
                    }
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                    // Send signal to active listeners to perform their next actions.
                    this.$emit('create-error', true);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Form Controls //////////
        submitForm (redirect) {
            this.loading = true;
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance(redirect);
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newInstanceForm'].resetFields();
        },
        cancelForm () {
            this.resetForm();
            // Send signal to active listeners to perform their next actions.
            this.$emit('cancel', true);
        }
    }
};
</script>
