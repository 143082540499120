/****************************************************************************************************
 * PersonLifeEvent person store sub-module.
 *
 * An almost independent sub-module of person store module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 November 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { PersonLifeEventService } from '@/common/services/api.service';

const state = {
    personLifeEvents: [],
    personLifeEventsLoading: false,
    personLifeEventsError: null
};

const getters = {
    personLifeEvents (state) {
        return state.personLifeEvents;
    },
    personLifeEventsLoading (state) {
        return state.personLifeEventsLoading;
    },
    personLifeEventsError (state) {
        return state.personLifeEventsError;
    }
};

const actions = {
    getPersonLifeEvents ({ commit, state }) {
        commit('setPersonLifeEventsLoading', true);
        commit('setPersonLifeEventsError', null);

        const id = state.personId;
        if (id === null) return Promise.reject(new Error('personId must not be null!'));

        return PersonLifeEventService.getPersonLifeEvents(id)
            .then(({ data }) => {
                commit('setPersonLifeEvents', data);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setPersonLifeEventsError', error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setPersonLifeEventsLoading', false);
            });
    },
    resetPersonLifeEventsSubModule ({ commit }) {
        commit('setPersonLifeEvents', []);
        commit('setPersonLifeEventsLoading', false);
        commit('setPersonLifeEventsError', null);
    }
};

const mutations = {
    setPersonLifeEvents (state, data) {
        Vue.set(state, 'personLifeEvents', data);
    },
    setPersonLifeEventsLoading (state, data) {
        Vue.set(state, 'personLifeEventsLoading', data);
    },
    setPersonLifeEventsError (state, data) {
        Vue.set(state, 'personLifeEventsError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
