/**
 * SmsCampaign module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 */
const routes = [
    {
        path: '/:tenantId/campaigns/sms-campaign/:id',
        name: 'sms-campaign',
        component: () => import(/* webpackChunkName: "smscampaign" */ '@/modules/SmsCampaign/views/SmsCampaignView.vue')
    },
    {
        path: '/:tenantId/campaigns/sms-campaign/:id/redirect',
        name: 'sms-campaign-redirect',
        component: () => import(/* webpackChunkName: "smscampaign" */ '@/modules/SmsCampaign/views/SmsCampaignRedirectView.vue')
    }
];

export default routes;
