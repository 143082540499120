/****************************************************************************************************
 * PropertyDefinition create/update/delete operations.
 *
 * ATTENTION: This sub-module supports the components and not the other way around.
 * Einai aparaititi i ulopoiisi sto store gia na yparxei diamoirasmos pliroforion metaxi ton components
 * kai ton allon store sub-modules (p.x. se periptosi error, state indicator, etc).
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import { ModelDefinitionService } from '@/common/services/api.service';

const state = {
    propertyGroupDTO: null,

    propertyGroupCreating: false,
    propertyGroupCreateError: null,

    propertyDefinitionCreateRequest: null, // DTO
    propertyDefinitionUpdateRequest: null, // DTO

    propertyDefinitionCreating: false,
    propertyDefinitionCreatingError: null,

    propertyDefinitionUpdating: false,
    propertyDefinitionUpdateError: null,

    propertyDefinitionDeleting: false,
    propertyDefinitionDeleteError: null
};

const getters = {};

const actions = {
    createPropertyGroup ({ state, commit }) {
        commit('setPropertyGroupCreating', true);
        commit('setPropertyGroupCreateError', null);

        // Checks.
        if (state.schemaDefinition === null) {
            commit('setPropertyGroupCreating', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }
        if (state.propertyGroupDTO === null) {
            commit('setPropertyGroupCreating', false);
            return Promise.reject(new Error('propertyGroupDTO must not be null!'));
        }

        const domain = state.schemaDefinition.name;
        const dto = state.propertyGroupDTO;

        return ModelDefinitionService.savePropertyGroup(domain, dto)
            .then(({ data }) => {
                // Do not reset DTO.
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setPropertyDefinitionCreateError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setPropertyDefinitionCreating', false);
            });
    },

    createPropertyDefinition ({ state, commit }) {
        commit('setPropertyDefinitionCreating', true);
        commit('setPropertyDefinitionCreateError', null);

        // Checks.
        if (state.schemaDefinition === null) {
            commit('setPropertyDefinitionCreating', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }
        if (state.propertyDefinitionCreateRequest === null) {
            commit('setPropertyDefinitionCreating', false);
            return Promise.reject(new Error('propertyDefinitionCreateRequest must not be null!'));
        }

        const domain = state.schemaDefinition.name;
        const dto = state.propertyDefinitionCreateRequest;

        return ModelDefinitionService.createPropertyDefinition(domain, dto)
            .then(({ data }) => {
                // Do not reset DTO.
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setPropertyDefinitionCreateError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setPropertyDefinitionCreating', false);
            });
    },
    updatePropertyDefinition ({ state, commit }) {
        commit('setPropertyDefinitionUpdating', true);
        commit('setPropertyDefinitionUpdateError', null);

        // Checks.
        if (state.schemaDefinition === null) {
            commit('setPropertyDefinitionUpdating', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }
        if (state.propertyDefinitionUpdateRequest === null) {
            commit('setPropertyDefinitionUpdating', false);
            return Promise.reject(new Error('propertyDefinitionUpdateRequest must not be null!'));
        }

        const domain = state.schemaDefinition.name;
        const dto = state.propertyDefinitionUpdateRequest;

        return ModelDefinitionService.updatePropertyDefinition(domain, dto)
            .then(({ data }) => {
                // Do not reset DTO.
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setPropertyDefinitionUpdateError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setPropertyDefinitionUpdating', false);
            });
    },
    deletePropertyDefinition ({ state, commit }) {
        commit('setPropertyDefinitionDeleting', true);
        commit('setPropertyDefinitionDeleteError', null);

        // Checks.
        if (state.schemaDefinition === null) {
            commit('setPropertyDefinitionDeleting', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }
        if (state.propertyDefinition === null) {
            commit('setPropertyDefinitionDeleting', false);
            return Promise.reject(new Error('propertyDefinition must not be null!'));
        }

        const domain = state.schemaDefinition.name;
        const name = state.propertyDefinition.name; // @future Is this the right object to get name from?

        return ModelDefinitionService.deletePropertyDefinition(domain, name)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setPropertyDefinitionDeleteError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setPropertyDefinitionDeleting', false);
            });
    },

    resetOperationsSubModule ({ commit }) {
        commit('setPropertyGroupDTO', null);

        commit('setPropertyGroupCreating', false);
        commit('setPropertyGroupCreateError', null);

        commit('setPropertyDefinitionCreateRequest', null);
        commit('setPropertyDefinitionUpdateRequest', null);

        commit('setPropertyDefinitionCreating', false);
        commit('setPropertyDefinitionCreateError', null);

        commit('setPropertyDefinitionUpdating', false);
        commit('setPropertyDefinitionUpdateError', null);

        commit('setPropertyDefinitionDeleting', false);
        commit('setPropertyDefinitionDeleteError', null);
    }
};

const mutations = {
    setPropertyGroupDTO (state, data) {
        Vue.set(state, 'propertyGroupDTO', data);
    },

    setPropertyGroupCreating (state, data) {
        Vue.set(state, 'propertyGroupCreating', data);
    },
    setPropertyGroupCreateError (state, data) {
        Vue.set(state, 'propertyGroupCreateError', data);
    },

    setPropertyDefinitionCreateRequest (state, data) {
        Vue.set(state, 'propertyDefinitionCreateRequest', data);
    },
    setPropertyDefinitionUpdateRequest (state, data) {
        Vue.set(state, 'propertyDefinitionUpdateRequest', data);
    },

    setPropertyDefinitionCreating (state, data) {
        Vue.set(state, 'propertyDefinitionCreating', data);
    },
    setPropertyDefinitionCreateError (state, data) {
        Vue.set(state, 'propertyDefinitionCreateError', data);
    },

    setPropertyDefinitionUpdating (state, data) {
        Vue.set(state, 'propertyDefinitionUpdating', data);
    },
    setPropertyDefinitionUpdateError (state, data) {
        Vue.set(state, 'propertyDefinitionUpdateError', data);
    },

    setPropertyDefinitionDeleting (state, data) {
        Vue.set(state, 'propertyDefinitionDeleting', data);
    },
    setPropertyDefinitionDeleteError (state, data) {
        Vue.set(state, 'propertyDefinitionDeleteError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
