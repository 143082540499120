import Vue from 'vue';

import { ClvIframeSync, ClvInteractionLocker } from '../../../core';
import vuetiful, { ClvHeadMetaOptions } from 'vuetiful/src/main';

import Logo from '@/components/Logo';
import CoreLoader from '@/components/patterns/CoreLoader';
import ElementLoading from '@/components/loading/ElementLoading';
import ViewHeader from '@/components/layout/ViewHeader';
import ViewContainer from '@/components/layout/ViewContainer';
import OffCanvasPanel from '@/components/patterns/OffCanvasPanel';
import OffCanvasPanelSkeleton from '@/components/patterns/OffCanvasPanelSkeleton';
import DockedModalPanelV1 from '@/components/patterns/DockedModalPanelV1';
import CampaignStatusTag from '@/components/Campaign/CampaignStatusTag';
import GlobalSelect from '@/components/Shared/GlobalSelect';
import GlobalSelectMultiple from '@/components/Shared/GlobalSelectMultiple';
import PersonTaxonomyLabelTag from '@/components/Person/PersonTaxonomyLabelTag';
import PersonPoliticsSupportStatusTag from '@/components/Person/PersonPoliticsSupportStatusTag';
import PersonPoliticsVolunteeringStatusTag from '@/components/Person/PersonPoliticsVolunteeringStatusTag';
import PersonPoliticsMobilizationStatusTag from '@/components/Person/PersonPoliticsMobilizationStatusTag';
import CustomCvPagination from '@/components/custom-cv-pagination';

import SimpleStateEmpty from '@/components/simple-state-empty';
import SimpleStateError from '@/components/simple-state-error';
import SimpleStateNotFound from '@/components/simple-state-not-found';

const AppComponents = {
    init () {
        // Vuetiful Components Registration //////////
        Vue.use(vuetiful, [
            'ClvHeadMeta'
        ]);
        ClvHeadMetaOptions.setOption('titleOd', 'Cloutlayer');
        ClvHeadMetaOptions.setOption('ods', 'disable');

        // Core Components Registration //////////
        ClvIframeSync.install(Vue);
        ClvInteractionLocker.install(Vue);

        // App Global Registration //////////
        // Register components that are being used 3 ore more times.
        Vue.component('logo', Logo);
        Vue.component('core-loader', CoreLoader);
        Vue.component('element-loading', ElementLoading);
        Vue.component('view-header', ViewHeader);
        Vue.component('view-container', ViewContainer);
        Vue.component('off-canvas-panel', OffCanvasPanel);
        Vue.component('off-canvas-panel-skeleton', OffCanvasPanelSkeleton);
        Vue.component('docked-modal-panel-v1', DockedModalPanelV1);
        Vue.component('person-taxonomy-label-tag', PersonTaxonomyLabelTag);
        Vue.component('person-politics-support-status-tag', PersonPoliticsSupportStatusTag);
        Vue.component('person-politics-volunteering-status-tag', PersonPoliticsVolunteeringStatusTag);
        Vue.component('person-politics-mobilization-status-tag', PersonPoliticsMobilizationStatusTag);
        Vue.component('campaign-status-tag', CampaignStatusTag);
        Vue.component('global-select', GlobalSelect);
        Vue.component('global-select-multiple', GlobalSelectMultiple);
        Vue.component('custom-cv-pagination', CustomCvPagination);
        // @future Check usage. Do not register globally if it's not used more than 2 times.

        Vue.component('simple-state-empty', SimpleStateEmpty);
        Vue.component('simple-state-error', SimpleStateError);
        Vue.component('simple-state-not-found', SimpleStateNotFound);
    }
};

export default AppComponents;
