import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import DomainTranslations from '@/modules/DomainTranslations';

const HardCodedDetails = [
    {
        pathToField: 'meta.createdBy',
        name: 'createdBy',
        label: 'Created By',
        description: null,
        group: 'origin',
        displayOrder: 1,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'meta.createdDate',
        name: 'createdDate',
        label: 'Created Date',
        description: null,
        group: 'origin',
        displayOrder: 2,
        displayAs: 'dateLiteral',
        value: null
    },
    {
        pathToField: 'meta.lastModifiedBy',
        name: 'lastModifiedBy',
        label: 'Last Modified By',
        description: null,
        group: 'origin',
        displayOrder: 3,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'meta.lastModifiedDate',
        name: 'lastModifiedDate',
        label: 'Last Modified Date',
        description: null,
        group: 'origin',
        displayOrder: 4,
        displayAs: 'dateLiteral',
        value: null
    },
    {
        pathToField: 'metadata.sourceType',
        name: 'sourceType',
        label: 'Source Type',
        description: null,
        group: 'origin',
        displayOrder: 5,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'metadata.sourceDescription',
        name: 'sourceDescription',
        label: 'Source Description',
        description: null,
        group: 'origin',
        displayOrder: 6,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'id',
        name: 'id',
        label: 'ID',
        description: null,
        group: 'metadata',
        displayOrder: 1,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'metadata.emailIdentityProfileId',
        name: 'emailIdentityProfileId',
        label: 'Email Identity Profile ID',
        description: null,
        group: 'metadata',
        displayOrder: 2,
        displayAs: 'default',
        value: null
    },
    {
        pathToField: 'metadata.phoneNumberIdentityProfileId',
        name: 'phoneNumberIdentityProfileId',
        label: 'Phone Number Identity Profile ID',
        description: null,
        group: 'metadata',
        displayOrder: 3,
        displayAs: 'default',
        value: null
    }
];

const HardCodedGroups = [
    {
        name: 'origin',
        label: 'Origin',
        description: null,
        displayOrder: 10000,
        details: []
    },
    {
        name: 'metadata',
        label: 'Metadata',
        description: null,
        displayOrder: 10001,
        details: []
    }
];

const DetailsHcHelper = {};

DetailsHcHelper.buildAndGet = function (person) {
    if (person === null || person === undefined) return [];

    const hardCodedDetailsByGroup = HardCodedDetails
        .map(function (detail) {
            const detailClone = cloneDeep(detail);
            // Get value from Person.
            detailClone.value = get(person, detailClone.pathToField, null);
            // Ugly but what can I do...
            if (detailClone.name === 'sourceType') {
                detailClone.value = DomainTranslations.personEnum(detailClone.value, 'sourceType', detailClone.value);
            }
            return detailClone;
        })
        .reduce(function (accumulator, current) {
            if (accumulator[current.group] === null || accumulator[current.group] === undefined) {
                accumulator[current.group] = [];
            }
            accumulator[current.group].push(current);
            return accumulator;
        }, {});

    return HardCodedGroups
        .map(function (group) {
            const groupClone = cloneDeep(group);
            groupClone.details = hardCodedDetailsByGroup.hasOwnProperty(groupClone.name) ? hardCodedDetailsByGroup[groupClone.name] : [];
            return groupClone;
        });
};

export default DetailsHcHelper;
