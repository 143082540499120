/****************************************************************************************************
 * Broadcaster sub-module for EmailCampaign module
 * - Handles the Broadcaster instance during editing
 * Assumes DRAFT EmailCampaign (!)
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { BroadcasterService } from '@/common/services/api.service';

const state = {
    broadcaster: null,
    broadcasterError: null,
    broadcasterLoading: false
};

const getters = {
    broadcaster (state) {
        return state.broadcaster;
    },
    broadcasterError (state) {
        return state.broadcasterError;
    },
    broadcasterLoading (state) {
        return state.broadcasterLoading;
    }
};

const actions = {
    getBroadcaster ({ commit, state }) {
        commit('setBroadcasterLoading', true);

        // Get broadcasterId.
        const broadcasterId = state.emailCampaign.broadcasterId;

        // Check emailCampaign status.
        if (state.emailCampaign.status !== 'DRAFT') {
            // @future @important : We shall add a mapper that converts error to error.model.
            return Promise.reject(new Error('Email Campaign is not Draft'));
        }

        // Check if broadcasterId and reset.
        // This is accepted behavior. For example user resets broadcaster.
        if (broadcasterId === null) {
            commit('setBroadcaster', null);
            commit('setBroadcasterError', null);
            commit('setBroadcasterLoading', false);

            // @future @important : We shall add a mapper that converts error to error.model.
            return Promise.reject(new Error('Email Campaign Broadcaster ID is null'));
        }

        // Get Broadcaster.
        return BroadcasterService.getBroadcaster(broadcasterId)
            .then(({ data }) => {
                // Set new data and reset error.
                commit('setBroadcaster', data);
                commit('setBroadcasterError', null);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setBroadcaster', null);
                commit('setBroadcasterError', error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setBroadcasterLoading', false);
            });
    },
    resetBroadcasterSubModule ({ commit }) {
        commit('setBroadcaster', null);
        commit('setBroadcasterError', null);
        commit('setBroadcasterLoading', false);
    }
};

const mutations = {
    setBroadcaster (state, data) {
        Vue.set(state, 'broadcaster', data);
    },
    setBroadcasterError (state, data) {
        Vue.set(state, 'broadcasterError', data);
    },
    setBroadcasterLoading (state, data) {
        Vue.set(state, 'broadcasterLoading', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
