<template>
    <div class="communication-subscription-creator">

        <!-- LOADER -->
        <div class="ElementLoading-Placeholder" v-if="displayLoading">
            <element-loading :active="true" background-color="transparent"></element-loading>
        </div>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found v-if="displayNotFound">
            <div class="mt-2" slot="description">
                <p class="mb-0">{{ $t('The requested Identity Profile does not exist.') }}</p>
            </div>
            <div class="mt-2" slot="action">
                <p class="mb-0 cursor-pointer text-primary" @click="cancelFormWithoutReset">
                    <i class="fas fa-chevron-left"></i> {{ $t('common_action_close') }}
                </p>
            </div>
        </simple-state-not-found>

        <div v-if="displayMain">
            <p class="mb-0 weight-4 small" v-if="displayNotValidIdentityProfileType">{{ $t('Communication Subscriptions are supported only for Emails and Phone Numbers') }}</p>
            <el-form :model="formFields"
                     :rules="formFieldsRules"
                     :disabled="disableForm"
                     ref="newInstanceForm"
                     :label-position="'top'"
                     class="cltlr-form-label-style-1"
                     @submit.native.prevent>
                <el-form-item :label="$t('Name')" prop="name" for="nameGcComSubCreator">
                    <el-input id="nameGcComSubCreator" v-model="identityProfile.name" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Type')" prop="type" for="typeGcComSubCreator">
                    <el-select id="typeGcComSubCreator" class="w-100" v-model="formFields.type">
                        <el-option v-for="typeItem in formOptions.types"
                                   :key="typeItem.value"
                                   :label="$t(typeItem.value)"
                                   :value="typeItem.value">{{ $t(typeItem.value) }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('Status')" prop="status" for="statusGcComSubCreator">
                    <el-select id="statusGcComSubCreator" class="w-100" v-model="formFields.status">
                        <el-option v-for="statusItem in formOptions.statuses"
                                   :key="statusItem.value"
                                   :label="$t(statusItem.value)"
                                   :value="statusItem.value">{{ $t(statusItem.value) }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('Lawful Basis')" prop="gdprLawfulBasis" for="gdprLawfulBasisGcComSubCreator" v-if="displayBaseAndExplanation">
                    <el-select id="gdprLawfulBasisGcComSubCreator" class="w-100" v-model="formFields.gdprLawfulBasis">
                        <el-option v-for="lawfulBasisItem in formOptions.lawfulBasis"
                                   :key="lawfulBasisItem.value"
                                   :label="$t(lawfulBasisItem.value)"
                                   :value="lawfulBasisItem.value">{{ $t(lawfulBasisItem.value) }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('Explanation')" prop="explanation" for="explanationGcComSubCreator" v-if="displayBaseAndExplanation">
                    <el-input id="explanationGcComSubCreator" type="textarea" v-model="formFields.explanation"></el-input>
                </el-form-item>
                <el-form-item class="mt-2 mb-0">
                    <button type="button" class="btn btn-primary btn-block" @click="submitForm" :disabled="disableSubmit">{{ $t('common_action_create') }}</button>
                    <button type="button" class="btn btn-secondary btn-block" @click="cancelForm" v-if="displayCancel">{{ $t('common_action_cancel') }}</button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { IdentityProfileService, CommunicationSubscriptionService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * Communication Subscription Creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 14 July 2020
 */
export default {
    name: 'CommunicationSubscriptionCreator',
    props: {
        identityProfileId: {
            type: String,
            default: null,
            required: false
        },
        displayCancel: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            fetchingIdentityProfile: false,
            identityProfile: null,
            identityProfileError: null,
            identityProfileNotFound: false,
            // Labels are not used.
            // We provide the value to i18n plugin and returns the corresponding label.
            formOptions: {
                typesForEmail: [
                    {
                        label: 'Marketing Email',
                        value: 'MARKETING_EMAIL'
                    },
                    {
                        label: 'One To One Email',
                        value: 'ONE_TO_ONE_EMAIL'
                    }
                ],
                typeForPhoneNumber: [
                    {
                        label: 'Marketing SMS',
                        value: 'MARKETING_SMS'
                    },
                    {
                        label: 'One To One SMS',
                        value: 'ONE_TO_ONE_SMS'
                    },
                    {
                        label: 'Marketing Call',
                        value: 'MARKETING_CALL'
                    },
                    {
                        label: 'One To One Call',
                        value: 'ONE_TO_ONE_CALL'
                    }
                ],
                types: [],
                statuses: [
                    {
                        label: 'Opted In',
                        value: 'OPTED_IN'
                    },
                    {
                        label: 'Opted Out',
                        value: 'OPTED_OUT'
                    },
                    {
                        label: 'Not Opted In or Opted Out (Reset)',
                        value: 'NOT_OPTED_IN_OR_OUT'
                    }
                ],
                lawfulBasis: [
                    {
                        label: 'Consent',
                        value: 'CONSENT'
                    },
                    {
                        label: 'Contract',
                        value: 'CONTRACT'
                    },
                    {
                        label: 'Legitimate Interests',
                        value: 'LEGITIMATE_INTERESTS'
                    },
                    {
                        label: 'Not Applicable',
                        value: 'NOT_APPLICABLE'
                    }
                ]
            },
            formFields: {
                dataSubjectIdentityProfileId: null,
                gdprLawfulBasis: null,
                type: null,
                status: null,
                explanation: ''
            },
            formFieldsRules: {
                gdprLawfulBasis: [
                    ElValidationUtils.requiredValidator()
                ],
                type: [
                    ElValidationUtils.requiredValidator()
                ],
                status: [
                    ElValidationUtils.requiredValidator()
                ],
                explanation: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 255)
                ]
            },
            communicationSubscriptionLoading: false,
            communicationSubscriptionResult: null,
            communicationSubscriptionError: null,

            // UI
            displayBaseAndExplanation: true
        };
    },
    computed: {
        displayLoading () {
            return this.fetchingIdentityProfile || this.communicationSubscriptionLoading;
        },
        displayNotFound () {
            if (this.fetchingIdentityProfile) {
                return false;
            }
            return this.identityProfileNotFound;
        },
        displayMain () {
            if (this.fetchingIdentityProfile) {
                return false;
            }
            if (this.communicationSubscriptionLoading) {
                return false;
            }
            return !this.identityProfileNotFound && this.identityProfile !== null;
        },
        displayNotValidIdentityProfileType () {
            return this.identityProfile.type !== 'EMAIL' && this.identityProfile.type !== 'PHONE_NUMBER';
        },
        disableForm () {
            if (!this.identityProfile) {
                return true;
            }
            return this.identityProfile.type !== 'EMAIL' && this.identityProfile.type !== 'PHONE_NUMBER';
        },
        disableSubmit () {
            if (!this.identityProfile) {
                return true;
            }
            // Check if identity profile is an Email or a Phone Number.
            if (this.identityProfile.type !== 'EMAIL' && this.identityProfile.type !== 'PHONE_NUMBER') {
                return true;
            }
            // Check if form is valid.
            return this.formFields.status === null ||
                this.formFields.gdprLawfulBasis == null ||
                this.formFields.type == null ||
                this.formFields.explanation == null ||
                this.formFields.explanation.trim() === '';
        }
    },
    watch: {
        'formFields.status': {
            handler: function (value) {
                if (value === 'OPTED_IN' || value === null || value === undefined || value === '') {
                    this.displayBaseAndExplanation = true;
                    this.formFields.gdprLawfulBasis = null;
                    this.formFields.explanation = '';
                } else {
                    this.displayBaseAndExplanation = false;
                    this.formFields.gdprLawfulBasis = 'NONE';
                    this.formFields.explanation = 'NONE';
                }
            },
            immediate: true
        }
    },
    beforeMount () {
        // Start loader.
        this.fetchingIdentityProfile = true;
    },
    mounted () {
        // Check provided id.
        if (!this.identityProfileId) {
            this.identityProfileNotFound = true;

            // Stop loader.
            this.fetchingIdentityProfile = false;

            // Abort.
            return;
        }

        // Find the identity profile.
        IdentityProfileService.getIdentityProfile(this.identityProfileId).then(({ data }) => {
            this.identityProfile = data;
            this.identityProfileError = null;
            this.identityProfileNotFound = false;

            // Set the options and map the fields with form fields (if need to).
            this.formFields.dataSubjectIdentityProfileId = this.identityProfile.id;
            if (this.identityProfile.type === 'EMAIL') {
                this.formOptions.types = cloneDeep(this.formOptions.typesForEmail);
            } else if (this.identityProfile.type === 'PHONE_NUMBER') {
                this.formOptions.types = cloneDeep(this.formOptions.typeForPhoneNumber);
            }
        }).catch((reason) => {
            this.identityProfile = null;
            this.identityProfileError = reason;
            this.identityProfileNotFound = true;
        }).finally(() => {
            // Stop loader.
            this.fetchingIdentityProfile = false;
        });
    },
    methods: {
        saveInstance () {
            // Start loader.
            this.communicationSubscriptionLoading = true;

            // Reset.
            this.communicationSubscriptionResult = null;
            this.communicationSubscriptionError = null;

            // Perform the request.
            CommunicationSubscriptionService.createCommunicationSubscription(this.formFields).then(({ data }) => {
                this.communicationSubscriptionResult = data;
                this.communicationSubscriptionError = null;

                this.toastDefault('Communication Subscription submitted');

                // Send signal to parent.
                this.$emit('create-success', data);
            }).catch((reason) => {
                this.communicationSubscriptionResult = null;
                this.communicationSubscriptionError = reason;

                this.toastErrorFromError(reason);

                // Send signal to parent.
                this.$emit('create-error', reason);
            }).finally(() => {
                this.communicationSubscriptionLoading = false;
            });
        },
        // Form Controls //////////
        submitForm () {
            this.communicationSubscriptionLoading = true;
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance();
                } else {
                    this.communicationSubscriptionLoading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newInstanceForm'].resetFields();
        },
        cancelForm () {
            this.resetForm();
            this.$emit('cancel', true);
        },
        cancelFormWithoutReset () {
            this.$emit('cancel', true);
        }
    }
};
</script>
