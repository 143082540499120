/****************************************************************************************************
 * Cloutlayer Simple Storage Service (S3)
 *
 * @future : If 'window' is missing? Use service to access?
 * I suggest not.
 * The purpose of cs3 service is to provide easy (even unconditional and unsafe) access to read-only information.
 *
 * Types:
 * - window storage
 * - local storage  - NOT YET IMPLEMENTED
 * - cookies        - NOT YET IMPLEMENTED
 ****************************************************************************************************/
import cloneDeep from 'lodash/cloneDeep';

const WINDOW_IS_DEFINED = typeof window !== 'undefined';
const WINDOW_STORAGE_NAME = 'cs3w';
const WINDOW_STORAGE_STORE_NAME = 'cs3wStore';

export const CS3WindowStorage = {
    set (key, value, clone = false) {
        // Check and initialize.
        if (!window.hasOwnProperty(WINDOW_STORAGE_STORE_NAME)) {
            window[WINDOW_STORAGE_STORE_NAME] = {};
            return;
        }
        if (window[WINDOW_STORAGE_STORE_NAME] === null) {
            window[WINDOW_STORAGE_STORE_NAME] = {};
        }

        // Validate key.
        if (key === null || key === undefined) {
            return;
        }
        if (typeof key !== 'string') {
            return;
        }
        if (key.trim() === '') {
            return;
        }
        if (clone === true) {
            window[WINDOW_STORAGE_STORE_NAME][key] = cloneDeep(value);
        } else {
            window[WINDOW_STORAGE_STORE_NAME][key] = value;
        }
    },
    get (key, defaultValue = null, clone = false) {
        // Check and initialize.
        if (!window.hasOwnProperty(WINDOW_STORAGE_STORE_NAME)) {
            window[WINDOW_STORAGE_STORE_NAME] = {};
            return;
        }
        if (window[WINDOW_STORAGE_STORE_NAME] === null) {
            window[WINDOW_STORAGE_STORE_NAME] = {};
        }

        let valueToReturn;
        if (window[WINDOW_STORAGE_STORE_NAME].hasOwnProperty(key)) {
            valueToReturn = window[WINDOW_STORAGE_STORE_NAME][key];
        } else {
            valueToReturn = defaultValue; // @future : Mipos edo prepei na einai panta? P.x. and dosei ena person?
        }

        return clone === true ? cloneDeep(valueToReturn) : valueToReturn;
    }
};

const cs3 = {
    initializeCS3WindowStorage () {
        if (WINDOW_IS_DEFINED) {
            window[WINDOW_STORAGE_STORE_NAME] = null;
            window[WINDOW_STORAGE_STORE_NAME] = {};
            window[WINDOW_STORAGE_NAME] = CS3WindowStorage;
        }
    }
};

export default cs3;
