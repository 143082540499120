<template>
    <docked-modal-panel-v1-skeleton :type="typeBasedOnState"
                                    :side="side"
                                    :side-offset="sideOffset"
                                    :x-offset="xOffset"
                                    :y-offset="yOffset"
                                    :lock-body-scroll="lockBodyScroll"
                                    :with-overlay="withOverlay"
                                    :overlay-z-index="overlayZIndex"
                                    :z-index="zIndex">
        <div class="UI-Fullscreen-Column bg-white" v-bind:class="uiFullscreenColumnClass" >
            <div class="UI-Fullscreen-Column__Container">
                <div class="UI-Fullscreen-Column__Header" v-if="displayPanelHeader">
                    <div class="py-3 px-3">
                        <div class="d-flex align-items-center" style="position: relative; height: 100%">
                            <p class="mb-0 weight-6 size-6 text-ellipsis mr-auto">{{ title }}</p>
                            <i class="action-icon mr-2" v-bind:class="icon1Class" @click="stateToggleMinimizeMaximize"></i>
                            <i class="action-icon mr-2" v-bind:class="icon2Class" @click="stateTogglePopOutDock"></i>
                            <i class="action-icon mr-2" v-bind:class="icon3Class" @click="close"></i>
                        </div>
                    </div>
                </div>
                <!--
                @help v-if vs v-show (for this component)
                v-if is for enable/disable section
                v-show is for show/hide section (we use this because we don't want to re-mount the component on 'minimized' change).
                -->
                <div class="UI-Fullscreen-Column__Body" v-if="withPanelBody" v-show="displayPanelBody">
                    <slot name="body"></slot>
                </div>
                <div class="UI-Fullscreen-Column__Footer" v-if="withPanelFooter" v-show="displayPanelFooter">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </docked-modal-panel-v1-skeleton>
</template>

<script>
import DockedModalPanelV1Skeleton from './DockedModalPanelV1Skeleton';

const defaultState = 'docked';
const defaultMinimized = false;
const stateTypeMap = {
    'docked': 'docked',
    'poppedOut': 'modal',
    'minimized': 'docked'
};
const uiFullscreenColumnClassBasedOnState = {
    'docked': ['Brand-Default-Style', 'Docked-Default-Style'],
    'poppedOut': ['Brand-Default-Style', 'UI-Fullscreen-Column--Absolute-100'],
    'minimized': ['Brand-Default-Style', 'Minimized-Default-Style']
};

/**
 * DockedModalPanel (V1).
 */
export default {
    name: 'DockedModalPanelV1',
    components: {
        DockedModalPanelV1Skeleton
    },
    // @help : Check DockedModalPanelV1Skeleton props for more information on how to use them.
    props: {
        initialState: {
            type: String,
            default: defaultState,
            required: false,
            validator: (prop) => [
                'docked',
                'poppedOut',
                'minimized'
            ].includes(prop)
        },
        initialMinimized: {
            type: Boolean,
            default: defaultMinimized,
            required: false
        },
        title: {
            type: String,
            default: '',
            required: false
        },
        // @future : I think Header is required.
        withPanelHeader: {
            type: Boolean,
            default: true,
            required: false
        },
        withPanelBody: {
            type: Boolean,
            default: true,
            required: false
        },
        withPanelFooter: {
            type: Boolean,
            default: true,
            required: false
        },

        // DockedModalPanelV1Skeleton Props //////////
        // Important: These props (& code) must be exactly the same as DockedModalPanelV1Skeleton props.
        side: {
            type: String,
            default: 'right', // right|left
            required: false,
            validator: (prop) => [
                'right',
                'left'
            ].includes(prop)
        },
        sideOffset: {
            type: String,
            default: '24px',
            required: false
        },
        xOffset: {
            type: String,
            default: '10%',
            required: false
        },
        yOffset: {
            type: String,
            default: '8%',
            required: false
        },
        overlayZIndex: {
            type: Number,
            default: 1699,
            required: false
        },
        zIndex: {
            type: Number,
            default: 1700,
            required: false
        }
    },
    data () {
        return {
            // @future : Implement this and add to Skeleton (as option - we don't always want to make Skeleton re-render).
            keyForReactivity: 0,
            minimized: defaultMinimized,
            state: defaultState
        };
    },
    computed: {
        typeBasedOnState () {
            // Minimized required 'modal' type.
            if (this.minimized) {
                return stateTypeMap['minimized'];
            }
            return stateTypeMap[this.state];
        },
        uiFullscreenColumnClass () {
            if (this.minimized) {
                return uiFullscreenColumnClassBasedOnState['minimized'].join(' ');
            }
            return uiFullscreenColumnClassBasedOnState[this.state].join(' ');
        },
        uiFullscreenColumnStyle () {
            return {};
        },

        // Parts/Sections to display based on props and on current state.
        displayPanelHeader () {
            return this.withPanelHeader === true;
        },
        displayPanelBody () {
            return this.withPanelBody === true && this.minimized === false;
        },
        displayPanelFooter () {
            return this.withPanelFooter === true && this.minimized === false;
        },

        // Computed Properties for Skeleton.
        lockBodyScroll () {
            // If minimized, do not lock scroll.
            if (this.minimized === true) {
                return false;
            }
            return this.state === 'poppedOut';
        },
        withOverlay () {
            // If minimized, do not display overlay.
            if (this.minimized === true) {
                return false;
            }
            return this.state === 'poppedOut';
        },

        // Computed Properties for actions and their icons.
        icon1Class () {
            return this.minimized ? 'far fa-window-maximize' : 'far fa-window-minimize';
        },
        icon2Class () {
            // Hide if minimized.
            if (this.minimized) {
                return 'd-none';
            }

            switch (this.state) {
            case 'poppedOut':
                return 'fas fa-compress';
            case 'docked':
                return 'fas fa-expand';
            default:
                return ''; // @future : here?
            }
        },
        icon3Class () {
            return 'fas fa-times';
        }
    },
    beforeMount () {
        this.minimized = this.initialMinimized;
        this.state = this.initialState;
    },
    methods: {
        stateTogglePopOutDock () {
            if (this.state === 'poppedOut') {
                this.state = 'docked';
            } else if (this.state === 'docked') {
                this.state = 'poppedOut';
            }
            // In any other case, do nothing.
        },
        stateToggleMinimizeMaximize () {
            this.minimized = !this.minimized;
        },
        close () {
            this.$emit('close');
        }
    }
};
</script>
