/****************************************************************************************************
 * Operations sub-module for EmailCampaign module
 * - Implements CRUD operations for EmailCampaign model.
 * - After CRUD operations, actions dispatch other actions in order to sync information across sub-modules.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { EmailCampaignService } from '@/common/services/api.service';

const state = {
    emailCampaign: null,
    // State Indicators //////////
    emailCampaignGetting: false,
    emailCampaignDeleting: false,
    emailCampaignUpdating: false,
    // Status //////////
    emailCampaignNotFound: false,
    // Errors //////////
    emailCampaignGetError: null,
    emailCampaignDeleteError: null,
    emailCampaignUpdateError: null
};

const getters = {
    emailCampaign (state) {
        return state.emailCampaign;
    },
    // State Indicators //////////
    emailCampaignGetting (state) {
        return state.emailCampaignGetting;
    },
    emailCampaignDeleting (state) {
        return state.emailCampaignDeleting;
    },
    emailCampaignUpdating (state) {
        return state.emailCampaignUpdating;
    },
    // Status //////////
    emailCampaignNotFound (state) {
        return state.emailCampaignNotFound;
    },
    // Errors //////////
    emailCampaignGetError (state) {
        return state.emailCampaignGetError;
    },
    emailCampaignDeleteError (state) {
        return state.emailCampaignDeleteError;
    },
    emailCampaignUpdateError (state) {
        return state.emailCampaignUpdateError;
    }
};

const actions = {
    async synchronizeDraftEmailCampaign ({ dispatch, commit, state }) {
        // Check if emailCampaign is DRAFT.
        if (state.emailCampaign.status !== 'DRAFT') {
            return;
        }

        // Get extra data (Broadcaster, Workspace, Workspace Count)
        // All actions handle the fails.
        // But they do not change EmailCampaign instance in any case.
        const broadcasterResult = await dispatch('getBroadcaster').then(() => true).catch(() => false);
        const workspaceResult = await dispatch('getWorkspace').then(() => true).catch(() => false);

        // If broadcaster is null, reset broadcasterId from emailCampaign instance.
        if (broadcasterResult === false) {
            commit('setEmailCampaignField', {
                field: 'broadcasterId',
                value: null
            });
        }

        // If workspace is null, reset workspaceId from emailCampaign instance.
        if (workspaceResult === false) {
            commit('setEmailCampaignField', {
                field: 'workspaceId',
                value: null
            });
        }

        // @future : Ensure that .then(() => void 0).catch(() => void 0) exists in all promises (use this only when results handled from the caller!).
        await dispatch('countWorkspace', true).then(() => void 0).catch(() => void 0); // Count total people.
        await dispatch('countWorkspace', false).then(() => void 0).catch(() => void 0); // Count subscribed people.
        await dispatch('getUnitBalanceBalance').then(() => void 0).catch(() => void 0); // Get current Unit Balance.
        await dispatch('validateEmailCampaignLocally');
    },
    getEmailCampaign ({ dispatch, commit, state }, { id }) {
        // Start loader.
        commit('setEmailCampaignGetting', true);

        // Reset state indicators and statuses.
        commit('setEmailCampaignNotFound', false);
        // @future : Proposition for UI/UX improvement: Perform reset in here and not in then-catch? Maybe yes!

        // Get EmailCampaign.
        return EmailCampaignService.getEmailCampaign(id)
            .then(({ data }) => {
                // Set data.
                commit('setEmailCampaign', data);
                commit('setEmailCampaignGetError', null);
                commit('setEmailCampaignNotFound', false);

                // Sync.
                dispatch('synchronizeDraftEmailCampaign');

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                commit('setEmailCampaign', null);
                commit('setEmailCampaignGetError', reason);
                commit('setEmailCampaignNotFound', true);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignGetting', false);
            });
    },
    updateEmailCampaign ({ dispatch, commit, state }) {
        // Start loader.
        commit('setEmailCampaignUpdating', true);

        // Update EmailCampaign.
        return EmailCampaignService.updateEmailCampaign(state.emailCampaign)
            .then(({ data }) => {
                // Set data.
                commit('setEmailCampaign', data);
                commit('setEmailCampaignUpdateError', null);

                // Sync.
                dispatch('synchronizeDraftEmailCampaign');

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                // commit('setEmailCampaign', null);
                commit('setEmailCampaignUpdateError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignUpdating', false);
            });
    },
    deleteEmailCampaign ({ dispatch, commit, state }) {
        // Start loader.
        commit('setEmailCampaignDeleting', true);

        // @future : Validate emailCampaign and id? I think that it is not necessary.

        // Reset data.
        commit('setEmailCampaignDeleteError', null);

        // Delete EmailCampaign.
        return EmailCampaignService.deleteEmailCampaign(state.emailCampaign.id)
            .then(() => {
                // Reset store.
                dispatch('resetModule');
                return Promise.resolve();
            })
            .catch((reason) => {
                commit('setEmailCampaignDeleteError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignDeleting', false);
            });
    },
    resetOperationsSubModule ({ commit }) {
        commit('setEmailCampaign', null);
        // State Indicators
        commit('setEmailCampaignGetting', false);
        commit('setEmailCampaignDeleting', false);
        commit('setEmailCampaignUpdating', false);
        // Status
        commit('setEmailCampaignNotFound', false);
        // Errors
        commit('setEmailCampaignGetError', null);
        commit('setEmailCampaignDeleteError', null);
        commit('setEmailCampaignUpdateError', null);
    }
};

const mutations = {
    setEmailCampaign (state, data) {
        Vue.set(state, 'emailCampaign', data);
    },
    // Mutations for EmailCampaign fields //////////
    setEmailCampaignField (state, { field, value }) {
        Vue.set(state.emailCampaign, field, value);
    },
    setEmailCampaignSettingsField (state, { field, value }) {
        // @future : Check emailCampaign?
        Vue.set(state.emailCampaign.settings, field, value);
    },
    // State Indicators //////////
    setEmailCampaignGetting (state, data) {
        Vue.set(state, 'emailCampaignGetting', data);
    },
    setEmailCampaignDeleting (state, data) {
        Vue.set(state, 'emailCampaignDeleting', data);
    },
    setEmailCampaignUpdating (state, data) {
        Vue.set(state, 'emailCampaignUpdating', data);
    },
    // Status //////////
    setEmailCampaignNotFound (state, data) {
        Vue.set(state, 'emailCampaignNotFound', data);
    },
    // Errors //////////
    setEmailCampaignGetError (state, data) {
        Vue.set(state, 'emailCampaignGetError', data);
    },
    setEmailCampaignDeleteError (state, data) {
        Vue.set(state, 'emailCampaignDeleteError', data);
    },
    setEmailCampaignUpdateError (state, data) {
        Vue.set(state, 'emailCampaignUpdateError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
