/****************************************************************************************************
 * Random Utilities.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 2019
 * @lastModifiedAt 30 July 2020
 ****************************************************************************************************/

const RandomUtils = {};

function getRandomInteger (min = 0, max = 100000) {
    if (typeof min !== 'number') min = 0;
    if (typeof max !== 'number') max = 100000;
    return Math.floor(Math.random() * (max - min)) + min;
}

RandomUtils.getUniqueId = function (prefix = 'id-', min = 0, max = 100000) {
    if (typeof prefix !== 'string') prefix = '';
    return prefix + new Date().getTime() + getRandomInteger(min, max);
};

RandomUtils.getRandomInteger = function (min = 0, max = 100000) {
    return getRandomInteger(min, max);
};

export default RandomUtils;
