/****************************************************************************************************
 * Person create and edit operations store module.
 * Supports the PersonEditView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';

import cloneDeep from 'lodash/cloneDeep';

import {
    PersonService
} from '@/common/services/api.service';
import RandomUtils from '@/common/utils/random.utils';
import DomainTranslations from '@/modules/DomainTranslations';
import schemaDefinitionSpecificProvider from '@/store/shared/df-specific.submodule';

import PdFormDetailsProvider from './pd-form-details-provider';
import person from './person.submodule';
import autoCorrection from './auto-correction.submodule';
import validations from './validations.submodule';

const schemaDefinition = schemaDefinitionSpecificProvider('person');

// Declare PropertyGroup instances that cannot be closed (hidden panel).
const propertyGroupsThatCannotBeClosed = [
    'basic',
    'contact'
];

const state = {
    ...schemaDefinition.state,
    ...person.state,
    ...autoCorrection.state,
    ...validations.state,

    gdprAddOnEnabled: false,

    initializing: false,

    propertyGroups: [],
    propertyGroupsBasics: [], // Basic fields only - for UI, nav, and scroll-spy.
    propertyGroupsUi: {}, // Provides UI information and states for each PropertyGroup name.
    propertyGroupsUiPreviousOpened: {}, // Populated on toggle. Persists propertyGroupsUi per application load.

    /**
     * Schema for each entry:
     * [propertyDefinition.name]: {
     *     baseValue: THE_VALUE,
     *     workingValue: THE_VALUE,
     *     valid: true|false,
     *     changed: true|false
     * }
     */
    properties: {},
    propertiesChangedCount: 0, // total properties changed
    propertiesValidCount: 0, // total properties valid
    propertiesInvalidCount: 0, // total properties invalid
    propertiesChangedValidCount: 0, // VALID changed properties
    propertiesChangedInvalidCount: 0, // INVALID changed properties

    saving: false,
    saveError: null
};

const getters = {
    ...schemaDefinition.getters,
    ...person.getters,
    ...autoCorrection.getters,
    ...validations.getters,

    initializing (state) {
        return state.initializing;
    },

    propertyGroups (state) {
        return state.propertyGroups;
    },
    propertyGroupsBasics (state) {
        return state.propertyGroupsBasics;
    },
    propertyGroupUiCanBeClosed: (state) => (name) => {
        if (typeof name !== 'string') return true;
        if (!state.propertyGroupsUi.hasOwnProperty(name)) return true;
        return state.propertyGroupsUi[name].canBeClosed;
    },
    propertyGroupUiOpened: (state) => (name) => {
        if (typeof name !== 'string') return true;
        if (!state.propertyGroupsUi.hasOwnProperty(name)) return true;
        return state.propertyGroupsUi[name].opened;
    },
    property: (state) => (name) => {
        if (typeof name !== 'string') return null;
        if (!state.properties.hasOwnProperty(name)) return null;
        return state.properties[name];
    },
    propertiesInvalidCount (state) {
        return state.propertiesInvalidCount; // Required for form messages.
    },

    propertyLawfulBasisExistsWorkingValue (state) {
        // noinspection JSUnresolvedVariable
        return state.properties.lawfulBasisExists.workingValue;
    },

    // UI state and flags
    displayGdprActions (state) {
        // noinspection JSUnresolvedVariable
        return state.gdprAddOnEnabled === true && state.properties.lawfulBasisExists.workingValue === false;
    },
    disableSave (state) {
        // noinspection JSUnresolvedVariable
        return state.propertiesChangedCount === 0 ||
            state.propertiesInvalidCount > 0 ||
            (state.gdprAddOnEnabled === true && state.properties.lawfulBasisExists.workingValue === false);
    },
    stateIsChanging (state) {
        return state.initializing || state.saving;
    },
    displayEmptyState (state) {
        return state.initializing === false && (state.schemaDefinition === null || state.person === null);
    },
    displayForm (state) {
        return state.initializing === false && state.schemaDefinition !== null && state.person !== null;
    }
};

const actions = {
    ...schemaDefinition.actions,
    ...person.actions,
    ...autoCorrection.actions,
    ...validations.actions,

    async initializeModule ({ dispatch, commit }) {
        commit('setInitializing', true);

        const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);
        const person = await dispatch('getPersonOrDefault').then((data) => data).catch(() => null);

        // Validate schemaDefinition and person.
        if (schemaDefinition === null || person === null) {
            commit('setInitializing', false);
            // No need to take further actions.
            // Getters will provide view and components the information they need.
            return Promise.reject(new Error('schemaDefinition and person must not be null'));
        }

        // Deep clones are necessary because we process the lists and their objects.
        // All of these objects are stored in module's state and passed in here by reference.
        const dfPropertyGroups = cloneDeep(schemaDefinition.propertyGroups);
        const dfPropertyDefinitions = cloneDeep(schemaDefinition.propertyDefinitions);
        const personProperties = cloneDeep(person.properties);

        // Process PropertyDefinition instances.
        // 1. transform each instance
        //    You can add/remove/modify each PropertyDefinition. E.g. for some PDs require special treatment.
        //    OR include/exclude PDs (custom).
        // 2. filter instances
        //    Remove PDs which are either computed or read-only.
        // 3. group instance by 'group'.
        const propertyDefinitionsCleaned = dfPropertyDefinitions
            .map(function (propertyDefinition) {
                propertyDefinition.label = DomainTranslations.personDfPd(propertyDefinition.name, propertyDefinition.label);
                propertyDefinition.description = null; // 2020-07-13 TEMPORARY DISABLED.
                propertyDefinition.enumerationValuesOptions = [];
                if (propertyDefinition.type === 'ENUMERATION') {
                    propertyDefinition.enumerationValuesOptions = propertyDefinition.enumerationValues
                        .map(function (enumValue) {
                            let enumerationValueLabel = propertyDefinition.enumerationValuesLabels[enumValue];
                            let label;
                            if (propertyDefinition.predefined === true) {
                                label = DomainTranslations.personEnum(enumValue, propertyDefinition.name, enumerationValueLabel);
                            } else {
                                label = enumerationValueLabel;
                            }
                            return {
                                label: label,
                                value: enumValue
                            };
                        });
                }
                propertyDefinition.validations = {
                    ...PdFormDetailsProvider.customValidations(propertyDefinition.name)
                };
                return propertyDefinition;
            })
            .filter(function (propertyDefinition) {
                if (propertyDefinition.computed === true) return false;
                // noinspection RedundantIfStatementJS
                if (propertyDefinition.readOnly === true) return false;
                return true;
            });

        const propertyDefinitionsByName = propertyDefinitionsCleaned
            .reduce(function (accumulator, current) {
                accumulator[current.name] = current;
                return accumulator;
            }, {});
        const propertyDefinitionsByGroup = propertyDefinitionsCleaned
            .reduce(function (accumulator, current) {
                if (accumulator[current.group] === null || accumulator[current.group] === undefined) {
                    accumulator[current.group] = [];
                }
                accumulator[current.group].push(current);
                return accumulator;
            }, {});

        // Process PropertyGroup instances.
        // 1. transform each instance
        //    add ordered list of PropertyDefinition instances that belong to the group
        // 2. filter instances
        //    Remove PropertyGroup instances with NO PropertyDefinition instances
        // 3. sort instances by 'display' order.
        const orderedPropertyGroups = dfPropertyGroups
            .map(function (propertyGroup) {
                propertyGroup.label = DomainTranslations.personDfPg(propertyGroup.name, propertyGroup.label);
                propertyGroup.description = null; // 2020-07-13 TEMPORARY DISABLED.
                propertyGroup['propertyDefinitions'] = [];
                propertyGroup['rId'] = RandomUtils.getUniqueId();

                // Add PropertyDefinition instances as ordered list to PropertyGroup.
                if (propertyDefinitionsByGroup.hasOwnProperty(propertyGroup.name)) {
                    if (propertyDefinitionsByGroup[propertyGroup.name].length > 0) {
                        propertyGroup['propertyDefinitions'] = propertyDefinitionsByGroup[propertyGroup.name]
                            .sort(function (a, b) {
                                return a.displayOrder - b.displayOrder;
                            });
                    }
                }

                return propertyGroup;
            })
            .filter(function (propertyGroup) {
                return propertyGroup.propertyDefinitions.length > 0;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        // Create an object with only the necessary information for the UI.
        // Necessary for nav, accordion, scroll-spy, etc.
        const orderedPropertyGroupsBasics = orderedPropertyGroups
            .map(function (propertyGroup) {
                return {
                    name: propertyGroup.name,
                    label: propertyGroup.label,
                    description: propertyGroup.description,
                    displayOrder: propertyGroup.displayOrder,
                    propertyDefinitions: propertyGroup.propertyDefinitions.map(function (propertyDefinition) {
                        return {
                            name: propertyDefinition.name,
                            label: propertyDefinition.label
                        };
                    })
                };
            });

        // Initialize UI state information for each PropertyGroup.
        const propertyGroupsUi = {};
        for (const propertyGroupBasics of orderedPropertyGroupsBasics) {
            // Overrides for specific PropertyGroup instances.
            const canBeClosed = !propertyGroupsThatCannotBeClosed.some(function (item) {
                return item === propertyGroupBasics.name;
            });

            let opened;
            if (canBeClosed === false) {
                opened = true;
            } else {
                if (typeof state.propertyGroupsUiPreviousOpened[propertyGroupBasics.name] === 'boolean') {
                    opened = state.propertyGroupsUiPreviousOpened[propertyGroupBasics.name];
                } else {
                    opened = false; // Closed by default.
                }
            }

            propertyGroupsUi[propertyGroupBasics.name] = {
                // UI Accordion fields
                canBeClosed: canBeClosed,
                opened: opened
            };
        }

        // Process Person's properties.
        const properties = {};
        for (let [key, value] of Object.entries(personProperties)) {
            let clonedValue = cloneDeep(value);

            // Do not include property if for any reason is not being displayed in form.
            // This options affects back-end. So be careful with the properties that you send.
            if (!propertyDefinitionsByName.hasOwnProperty(key)) continue;

            // Perform necessary conversions/transformations.
            // NOTICE: CURRENTLY, COMPONENTS HAVE TOTAL CONTROL OVER PROPERTIES.
            // THAT MAKES SENSE AS THIS SUB-MODULE EXISTS TO SUPPORT COMPONENTS AND NOT THE OTHER WAY AROUND.
            /*
            if (propertyDefinitionsByName[key].type === 'INSTANT') {
                const dateValue = new Date(clonedValue);
                if (dateValue instanceof Date && !isNaN(dateValue)) clonedValue = dateValue;
                else clonedValue = null;
                // else console.warn('COULD NOT CONVERT TO JS DATE'); // @future DATA LOSS / HANDLE, but how? Notify user?
            }
             */

            properties[key] = {
                baseValue: clonedValue,
                workingValue: clonedValue,
                valid: true, // The success of the whole concept it that back-end always provided valid data...(!)
                changed: false,
                touched: false // If edited at least once (regardless if changed or not)
            };
        }

        commit('setInitializing', false);
        commit('setPropertyGroups', orderedPropertyGroups);
        commit('setPropertyGroupsBasics', orderedPropertyGroupsBasics);
        commit('setPropertyGroupsUi', propertyGroupsUi);
        commit('setProperties', properties);

        return Promise.resolve();
    },

    async resetModule ({ dispatch, commit }) {
        await dispatch('resetSchemaDefinitionSpecificSubModule');
        await dispatch('resetPersonSubModule');
        await dispatch('resetAutoCorrectionSubModule');

        // Reset index state.
        commit('setInitializing', false);
        commit('setPropertyGroups', []);
        commit('setPropertyGroupsBasics', []);
        commit('setPropertyGroupsUi', {});
        commit('setProperties', {});
        commit('resetPropertiesCounts');
        commit('setSaving', false);
        commit('setSaveError', null);
    },

    /**
     * Creates or update the Person.
     *
     * This async operation always resolves.
     * Resolve does not imply that async (remove) operation succeed.
     * Result data should be checked and take decisions based on that information.
     *
     * On reject it means that an unexpected error has occurred.
     * (and usually cannot be handled properly - a basic toast notification with report option is enough)
     *
     * IMPORTANT: Currently this operation after its execution it does not perform any further actions on store.
     * The expected behavior is that the view/component that called this operation will redirect immediately
     * or to re-initialize the store based on the new data.
     */
    async save ({ state, commit }) {
        commit('setSaving', true);
        commit('setSaveError', null);

        // Get only the changed properties.
        const personProperties = {};
        let changedCount = 0;
        let invalidCount = 0;
        for (let [key, value] of Object.entries(state.properties)) {
            if (!value.changed) continue;
            changedCount = changedCount + 1;
            if (value.valid === false) invalidCount = invalidCount + 1;
            personProperties[key] = cloneDeep(value.workingValue);
        }

        // Check if at least one property is changed.
        // NOTICE: We expect that this is unreached as we disable button if there are no changed properties.
        if (changedCount === 0) {
            commit('setSaving', false);
            return Promise.reject(new Error('No property is changed!'));
        }

        // Check if exists at least one invalid changed property.
        // NOTICE: We expect that this is unreached as we disable button if invalid properties exists.
        if (invalidCount > 0) {
            commit('setSaving', false);
            return Promise.reject(new Error('Form contains invalid properties!'));
        }

        // Prepare the DTO.
        const dto = {
            id: state.person.id,
            properties: personProperties
        };

        // Set operation and perform.
        const operation = dto.id === null ? 'createPerson' : 'updatePerson';
        const result = await PersonService[operation](dto)
            .then((data) => {
                commit('setSaving', true); // Leave loader. We expect redirect...

                return {
                    success: true,
                    person: data.data,
                    error: null
                };
            })
            .catch((reason) => {
                commit('setSaving', false);

                return {
                    success: false,
                    person: null,
                    error: reason
                };
            });

        // IMPORTANT: We expect redirect and that's why we leave it true.
        // (to avoid flashing screen on redirect)
        // If it's necessary to set to true, do it after Promise's resolved.
        // commit('setSaving', false);
        commit('setSaveError', result.error); // @future we should process error and store only the necessary info.

        return Promise.resolve(result);
    }
};

const mutations = {
    ...schemaDefinition.mutations,
    ...person.mutations,
    ...autoCorrection.mutations,
    ...validations.mutations,

    setGdprAddOnEnabled (state, data) {
        if (typeof data !== 'boolean') return;
        state.gdprAddOnEnabled = data;
    },

    setInitializing (state, data) {
        Vue.set(state, 'initializing', data);
    },
    setPropertyGroups (state, data) {
        Vue.set(state, 'propertyGroups', data);
    },
    setPropertyGroupsBasics (state, data) {
        Vue.set(state, 'propertyGroupsBasics', data);
    },
    setPropertyGroupsUi (state, data) {
        Vue.set(state, 'propertyGroupsUi', data);
    },
    setProperties (state, data) {
        Vue.set(state, 'properties', data);
    },

    /**
     * Toggles PropertyGroup accordion.
     *
     * Does not affect other PropertyGroup UI states.
     */
    togglePropertyGroupUiOpened (state, name) {
        if (!state.propertyGroupsUi.hasOwnProperty(name)) return;
        const currentState = state.propertyGroupsUi[name].opened;
        const newState = !currentState;
        if (newState === false) {
            // Check if canBeClosed only if new state is false.
            if (!state.propertyGroupsUi[name].canBeClosed) return;
        }
        Vue.set(state.propertyGroupsUi[name], 'opened', !currentState);
        Vue.set(state.propertyGroupsUiPreviousOpened, name, !currentState); // Remember this.
    },
    ensurePropertyGroupUiOpened (state, name) {
        if (!state.propertyGroupsUi.hasOwnProperty(name)) return;
        Vue.set(state.propertyGroupsUi[name], 'opened', true);
        Vue.set(state.propertyGroupsUiPreviousOpened, name, true); // Remember this.
    },

    /**
     * On form field change, this mutation updates the property's value.
     * This mutation relies on components which have the responsibility to provide valid information.
     */
    modifyProperty (state, data) {
        if (data === null) return; // @future Validate data?
        if (!state.properties.hasOwnProperty(data.name)) return;
        // Base value cannot be changed once it's set.
        Vue.set(state.properties[data.name], 'workingValue', data.value);
        Vue.set(state.properties[data.name], 'valid', data.valid);
        Vue.set(state.properties[data.name], 'changed', data.changed);
        Vue.set(state.properties[data.name], 'touched', true);
    },

    modifyPropertyBaseValue (state, { name, value }) {
        if (!state.properties.hasOwnProperty(name)) return;
        Vue.set(state.properties[name], 'baseValue', value);
    },
    modifyPropertyWorkingValue (state, { name, value }) {
        if (!state.properties.hasOwnProperty(name)) return;
        Vue.set(state.properties[name], 'workingValue', value);
    },

    syncPropertiesCounts (state) {
        let propertiesChangedCount = 0;
        let propertiesValidCount = 0;
        let propertiesInvalidCount = 0;
        let propertiesChangedValidCount = 0;
        let propertiesChangedInvalidCount = 0;

        for (let value of Object.values(state.properties)) {
            const valid = value.valid;
            const changed = value.changed;

            if (changed) {
                propertiesChangedCount++;

                if (valid) {
                    propertiesChangedValidCount++;
                } else {
                    propertiesChangedInvalidCount++;
                }
            }

            if (valid) {
                propertiesValidCount++;
            } else {
                propertiesInvalidCount++;
            }
        }

        Vue.set(state, 'propertiesChangedCount', propertiesChangedCount);
        Vue.set(state, 'propertiesValidCount', propertiesValidCount);
        Vue.set(state, 'propertiesInvalidCount', propertiesInvalidCount);
        Vue.set(state, 'propertiesChangedValidCount', propertiesChangedValidCount);
        Vue.set(state, 'propertiesChangedInvalidCount', propertiesChangedInvalidCount);
    },
    resetPropertiesCounts (state) {
        Vue.set(state, 'propertiesChangedCount', 0);
        Vue.set(state, 'propertiesValidCount', 0);
        Vue.set(state, 'propertiesInvalidCount', 0);
        Vue.set(state, 'propertiesChangedValidCount', 0);
        Vue.set(state, 'propertiesChangedInvalidCount', 0);
    },

    /**
     * A mutation that changes the rId of a specific PropertyGroup in order to be re-mounted.
     * That way all PropertyDefinition form handlers will be re-mounted too.
     * This is the solution to make properties reactive.
     * It's not optimal but works.
     * Use it wisely and only on properties that do not change too often.
     */
    updatePropertyGroupReactivityId (state, pgName) {
        if (typeof pgName !== 'string') return;
        let index = null;
        let counter = 0;
        for (const pg of state.propertyGroups) {
            if (pg.name === pgName) {
                index = counter;
                break;
            }
            counter++;
        }
        if (index === null) return; // PropertyGroup not found.
        const pg = cloneDeep(state.propertyGroups[index]);
        pg['rId'] = RandomUtils.getUniqueId();
        Vue.set(state.propertyGroups, index, pg);
    },

    setSaving (state, data) {
        Vue.set(state, 'saving', data);
    },
    setSaveError (state, data) {
        Vue.set(state, 'saveError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
