/****************************************************************************************************
 * Send sub-module for SmsCampaign module
 * - Handles the SmsCampaign send operation.
 * Assumes DRAFT SmsCampaign (!)
 *
 * @author Dimitris Gkoulis
 * @createdAt 16 July 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { SmsCampaignService } from '@/common/services/api.service';

const state = {
    smsCampaignSending: false,
    smsCampaignSendError: null
};

const getters = {
    smsCampaignSending (state) {
        return state.smsCampaignSending;
    },
    smsCampaignSendError (state) {
        return state.smsCampaignSendError;
    }
};

const actions = {
    sendSmsCampaign ({ commit, state }) {
        // Start loader.
        commit('setSmsCampaignSending', true);

        // @future : Validate smsCampaign and return Promise.reject?
        // check other loaders.
        // const isNull = state.smsCampaign === null;
        // const isDraft = state.smsCampaign.status;
        // const isSafeToSend = getters.validationResult('safeToSend');

        // Send smsCampaign.
        // This operation saves and then sends smsCampaign.
        return SmsCampaignService.sendSmsCampaign(state.smsCampaign)
            .then(({ data }) => {
                commit('setSmsCampaignSendError', null);

                // IMPORTANT: PLEASE READ:
                // No need to do anything at all BECAUSE
                // after send the SmsCampaignView will be re-mounted.
                // But keep in mind that if this architecture changes,
                // this block of code has to adapted to that changes
                // in order to provide a: [BE PREPARED]
                // continual, fluid, always moving, clean, stable experience.

                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setSmsCampaignSendError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setSmsCampaignSending', false);
            });
    },
    resetSendSubModule ({ commit }) {
        // State Indicators
        commit('setSmsCampaignSending', false);
        // Errors
        commit('setSmsCampaignSendError', null);
    }
};

const mutations = {
    setSmsCampaignSending (state, data) {
        Vue.set(state, 'smsCampaignSending', data);
    },
    setSmsCampaignSendError (state, data) {
        Vue.set(state, 'smsCampaignSendError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
