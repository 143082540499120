/**
 * GenericAddOn module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 23 March 2020
 */
const routes = [
    {
        path: '/:tenantId/tools/add-on/:addOn(politics|gdpr|i18n)',
        name: 'generic-add-on',
        component: () => import(/* webpackChunkName: "genericaddon" */ '@/modules/GenericAddOn/views/GenericAddOnView')
    }
];

export default routes;
