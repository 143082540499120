<template>
    <span v-if="displayTag" v-bind:class="cssClass">
        <span v-if="count" key="with-count">{{ $t('mobilization_status_literal_with_count', { count: count }) }}</span>
        <span v-else key="without-count">{{ $t('mobilization_status_literal') }}</span>
    </span>
</template>

<script>
export default {
    name: 'PersonPoliticsMobilizationStatusTag',
    props: {
        isMobilizer: {
            type: Boolean,
            default: false,
            required: false
        },
        peopleCount: {
            type: Number,
            default: null,
            required: false
        }
    },
    computed: {
        displayTag () {
            return !!this.isMobilizer;
        },
        cssClass () {
            return this.isMobilizer ? 'gk-tag gk-tag-purple' : 'gk-tag gk-tag-gray';
        },
        count () {
            if (typeof this.peopleCount === 'number' && this.peopleCount > 0) return this.peopleCount;
            return null;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "mobilization_status_literal_with_count": "Mobilizer ({count})",
        "mobilization_status_literal": "Mobilizer"
    },
    "el": {
        "mobilization_status_literal_with_count": "Κινητοποιητής ({count})",
        "mobilization_status_literal": "Κινητοποιητής"
    }
}
</i18n>
