<template>
    <div class="CreatorsDialogView ElementUi-CommonDialog-Wrapper">
        <!-- CREATE DIALOG -->
        <el-dialog :title="$t(safeGetViewCreatorTitle)"
                   :custom-class="'ElementUi-CommonDialog ElementUi-CommonDialog--Narrow'"
                   :top="'5px'"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :show-close="true"
                   :lock-scroll="true"
                   :visible.sync="viewActive"
                   v-on:close="onClose">
            <div v-if="safeGetViewCreator === 'PERSON'" key="GLOBAL_CREATE_PERSON">
                <person-creator v-on:cancel="removeCreatorRouteQuery"
                                v-on:create-success="removeCreatorRouteQuery"></person-creator>
            </div>
            <div v-else-if="safeGetViewCreator === 'EMAIL_CAMPAIGN'" key="GLOBAL_CREATE_EMAIL_CAMPAIGN">
                <email-campaign-creator v-on:cancel="removeCreatorRouteQuery"
                                        v-on:create-success="removeCreatorRouteQuery"></email-campaign-creator>
            </div>
            <div v-else-if="safeGetViewCreator === 'SMS_CAMPAIGN'" key="GLOBAL_CREATE_SMS_CAMPAIGN">
                <sms-campaign-creator v-on:cancel="removeCreatorRouteQuery"
                                      v-on:create-success="removeCreatorRouteQuery"></sms-campaign-creator>
            </div>
            <div v-else-if="safeGetViewCreator === 'EMAIL_TEMPLATE'" key="GLOBAL_CREATE_EMAIL_TEMPLATE">
                <email-template-creator v-on:cancel="removeCreatorRouteQuery"
                                        v-on:create-success="removeCreatorRouteQuery"></email-template-creator>
            </div>
            <div v-else-if="safeGetViewCreator === 'BROADCASTER'" key="GLOBAL_CREATE_BROADCASTER">
                <broadcaster-creator :id="viewEditorIdentifier"
                                     v-on:cancel="removeCreatorRouteQuery"
                                     v-on:create-success="removeCreatorRouteQuery"
                                     v-on:delete-success="removeCreatorRouteQuery"></broadcaster-creator>
            </div>
            <div v-else-if="safeGetViewCreator === 'IDENTITY_PROFILE_COM_SUB'" key="GLOBAL_IDENTITY_PROFILE_COM_SUB">
                <communication-subscription-creator :identity-profile-id="viewEditorIdentifier"
                                                    v-on:cancel="removeCreatorRouteQuery"
                                                    v-on:create-success="removeCreatorRouteQuery"></communication-subscription-creator>
            </div>
            <!--
            <div v-else key="GLOBAL_CREATE_INVALID">
                <button class="btn btn-secondary btn-block" @click="removeCreatorRouteQuery">{{ $t('common_action_cancel') }}</button>
            </div>
            -->
        </el-dialog>
        <!-- END CREATE DIALOG -->
    </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import PersonCreator from '@/modules/Creators/components/PersonCreator';
import EmailCampaignCreator from '@/modules/Creators/components/EmailCampaignCreator';
import SmsCampaignCreator from '@/modules/Creators/components/SmsCampaignCreator';
import EmailTemplateCreator from '@/modules/Creators/components/EmailTemplateCreator';
import BroadcasterCreator from '@/modules/Creators/components/BroadcasterCreator';
import CommunicationSubscriptionCreator from '@/modules/Creators/components/CommunicationSubscriptionCreator';

const DialogDetails = {
    'person': {
        key: 'person',
        tmplKey: 'PERSON',
        word: 'Person',
        appendWord: true
    },
    'emailCampaign': {
        key: 'emailCampaign',
        tmplKey: 'EMAIL_CAMPAIGN',
        word: 'Email Campaign',
        appendWord: true
    },
    'smsCampaign': {
        key: 'smsCampaign',
        tmplKey: 'SMS_CAMPAIGN',
        word: 'SMS Campaign',
        appendWord: true
    },
    'emailTemplate': {
        key: 'emailTemplate',
        tmplKey: 'EMAIL_TEMPLATE',
        word: 'Email Template',
        appendWord: true
    },
    'broadcaster': {
        key: 'broadcaster',
        tmplKey: 'BROADCASTER',
        word: 'Broadcaster',
        appendWord: true
    },
    'ip-cs': {
        key: 'ip-cs',
        tmplKey: 'IDENTITY_PROFILE_COM_SUB',
        word: 'Communication Subscription',
        appendWord: false
    }
};

/**
 * Creators Dialog View.
 * A nested pseudo view which handles router events that are related to Creators.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 14 November 2020 (moved dialog style to scss)
 */
export default {
    name: 'CreatorsDialogView',
    components: {
        CommunicationSubscriptionCreator,
        BroadcasterCreator,
        EmailTemplateCreator,
        SmsCampaignCreator,
        EmailCampaignCreator,
        PersonCreator
    },
    data () {
        return {
            viewActive: false,
            viewCreator: null,
            viewEditorIdentifier: null
        };
    },
    computed: {
        safeGetViewCreator () {
            if (typeof this.viewCreator !== 'string') return 'DEFAULT';
            if (DialogDetails.hasOwnProperty(this.viewCreator)) return DialogDetails[this.viewCreator].tmplKey;
            return 'DEFAULT';
        },
        // The composed word or phrase should be translated in vue-i18n.
        safeGetViewCreatorTitle () {
            if (typeof this.viewCreator !== 'string') return '';
            if (DialogDetails.hasOwnProperty(this.viewCreator)) {
                const word = DialogDetails[this.viewCreator].word;
                if (DialogDetails[this.viewCreator].appendWord) {
                    return this.viewEditorIdentifier ? 'Edit ' + word : 'Create ' + word;
                } else {
                    return word;
                }
            }
            return '';
        }
    },
    watch: {
        '$route.query.gcCreator' () {
            this.explainAndRoute();
        },
        '$route.query.gcEditorIdentifier' () {
            this.explainAndRoute();
        }
    },
    beforeMount () {
        this.explainAndRoute();
    },
    methods: {
        /**
         * Explains route query params and set data.
         * Data change triggers changes on computed properties.
         */
        explainAndRoute () {
            let creator = this.$route.query.gcCreator;
            this.viewEditorIdentifier = this.$route.query.gcEditorIdentifier;
            if (this.viewEditorIdentifier === undefined) this.viewEditorIdentifier = null;
            if (!creator) {
                this.viewActive = false;
                this.viewCreator = null;
            } else {
                this.viewActive = true;
                this.viewCreator = creator;
            }
        },

        /**
         * Removes the query 'creator' from route.
         * IMPORTANT: It should remove ONLY query params related to this view.
         */
        removeCreatorRouteQuery () {
            let query = Object.assign({}, this.$route.query);
            delete query.gcCreator;
            delete query.gcEditorIdentifier;
            if (isEqual(query, this.$route.query)) return;
            this.$router.replace({ query });
        },

        /**
         * Handles 'times' 'X' icon click.
         */
        onClose () {
            this.viewActive = false;
            this.viewCreator = null;
            this.removeCreatorRouteQuery();
        }
    }
};
</script>
