<template>
    <span v-bind:class="kind">
        {{ label }}
        <span class="ml-1 small cursor-pointer" @click="close" v-if="closable"><i class="fas fa-times"></i></span>
    </span>
</template>

<script>
export default {
    name: 'PersonTaxonomyLabelTag',
    props: {
        value: {
            type: Object,
            default: null,
            required: true
        },
        closable: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        kind () {
            return 'gk-tag gk-taxonomy-label';
        },
        label () {
            // @future Add basic check and validation.
            return this.value.name;
        }
    },
    methods: {
        close () {
            this.$emit('close', this.value);
            if (this.value.hasOwnProperty('label')) {
                this.$emit('close-by-name', this.value.label);
            }
        }
    }
};
</script>
