<template>
    <div class="global-select-multiple" v-if="foreignType">

        <!-- LOADER -->
        <element-loading :active="initializing" :size="24" background-color="rgba(255, 255, 255, 0.8)"></element-loading>

        <!-- ERROR -->
        <p class="text-danger" v-if="error">{{ error.messageForUser }}</p>

        <!-- SELECT -->
        <el-select class="g-width-100"
                   :size="elemSize"

                   v-model="selectedItemIdentifierList"
                   :disabled="elemDisabled"
                   :value-key="'foreignIdentifier'"
                   :placeholder="elemPlaceholder"
                   multiple
                   filterable
                   remote
                   :loading="fetching"
                   :loading-text="elemLoadingText"
                   :no-match-text="elemNoMatchText"
                   :no-data-text="elemNoDataText"
                   :remote-method="fetch"
                   v-on:change="onSelectChange">

            <!-- OPTIONS -->
            <el-option v-for="result in results"
                       :key="result.foreignIdentifier"
                       :label="result.foreignName"
                       :value="result.foreignIdentifier">{{ result.foreignName }}</el-option>

        </el-select>
        <!-- END SELECT -->

    </div>
</template>

<script>
import { InternalIdentifierService } from '@/common/services/api.service';
import i18n from '@/common/plugins/i18n';

function initialTranslation (param) {
    if (i18n.te(param)) {
        return i18n.t(param);
    }
    return param;
}

/**
 * Global Select Multiple
 *
 * @author Dimitris Gkoulis
 * @createdAt 2018
 * @lastModifiedAt 14 November 2020
 */
export default {
    name: 'GlobalSelectMultiple',
    props: {
        // UI Element Properties //////////
        elemSize: {
            type: String,
            required: false,
            default: ''
        },
        elemDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        elemClearable: {
            type: Boolean,
            required: false,
            default: true
        },
        elemPlaceholder: {
            type: String,
            required: false,
            default: initialTranslation('Please enter a keyword')
        },
        elemLoadingText: {
            type: String,
            required: false,
            default: (initialTranslation('Loading') + '...')
        },
        elemNoMatchText: {
            type: String,
            required: false,
            default: initialTranslation('No matching data')
        },
        elemNoDataText: {
            type: String,
            required: false,
            default: initialTranslation('No data')
        },
        // Data Properties //////////
        foreignIdentifierList: {
            type: Array,
            default: function () {
                return [];
            },
            required: false
        },
        foreignType: {
            type: String,
            default: null,
            required: true
        }
    },
    data () {
        /**
         * Each item list has the following structure:
         * {
         *     id: null,
         *     foreignType: null,
         *     foreignIdentifier: null,
         *     foreignName: null,
         *     extra: null
         * }
         */
        return {
            // Selected Item //////////
            selectedItemIdentifierList: [],
            // Loaders //////////
            initializing: false,
            fetching: false,
            // Request Results //////////
            results: [],
            // Errors //////////
            error: null // This is a shared error for presentation purposes.
        };
    },
    mounted () {
        this.initialize();
    },
    methods: {
        fetch (query) {
            // Validate query (user input).
            if (query === null) {
                return;
            }
            if (query.trim() === '') {
                return;
            }
            this.fetchRemote(query);
        },
        fetchRemote (query) {
            // Start fetching loader.
            this.fetching = true;
            // Fetch data.
            InternalIdentifierService.searchInternalIdentifier(query, this.foreignType)
                .then(({ data }) => {
                    this.results = data;
                    this.error = null;
                })
                .catch((error) => {
                    this.results = [];
                    this.error = error;
                })
                .finally(() => {
                    this.fetching = false;
                });
        },
        initialize () {
            // Check foreignIdentifierList. If it is null, there is no need to initialize at all.
            if (this.foreignIdentifierList.length === 0) {
                // In this case, pre-fetch some results to improve the UX.
                this.fetchRemote(''); // Fetch some results (by default on mount).
                return;
            }
            // Start initializing loader.
            this.initializing = true;
            InternalIdentifierService.bulkGetInternalIdentifier(this.foreignIdentifierList, this.foreignType)
                .then(({ data }) => {
                    // Set results and reset error.
                    this.results = data;
                    this.selectedItemIdentifierList = data.map(item => {
                        return item.foreignIdentifier;
                    });
                    this.error = null;
                })
                .catch((error) => {
                    this.error = error;
                })
                .finally(() => {
                    this.initializing = false;
                });
        },
        // Event Handlers //////////
        onSelectChange () {
            if (this.selectedItemIdentifierList.length === 0) {
                this.$emit('select', []);
                return;
            }
            this.$emit('select', this.selectedItemIdentifierList);
        }
    }
};
</script>
