/**
 * DfFlatUiCustomization module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 1 August 2020
 */
const routes = [
    {
        path: '/:tenantId/tools/df-flat-ui-customization/:id',
        name: 'df-flat-ui-customization',
        component: () => import(/* webpackChunkName: "dfflatuicustomization" */ '@/modules/DfFlatUiCustomization/views/DfFlatUiCustomizationView.vue')
    }
];

export default routes;
