/****************************************************************************************************
 * Details sub-module for Person module
 * Processes Person properties based on SchemaDefinition and prepares data for components.
 *
 * @author Dimitris Gkoulis
 * @createdAt 25 June 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import LegacyGpUiUserRecordLogic from '@/common/logic/legacy-gp-ui-user-record.logic';
import DomainTranslations from '@/modules/DomainTranslations';
import detailsHelper from './details-helper';
import DetailsHcHelper from './details-hc-helper';

const state = {
    /**
     * Selected details for "about" timeline section.
     */
    details: [],

    /**
     * Details grouped by "group" for "details" sub-view.
     */
    detailsByGroup: [],

    /**
     * Label and name only for "nav" section in "details" sub-view
     */
    detailsByGroupBasics: [],

    /**
     * UI states for 'detailsByGroup'.
     */
    detailsByGroupUi: {},
    detailsByGroupUiPreviousOpened: {} // Populated on toggle. Persists detailsByGroupUi per application load.
};

const getters = {
    details (state) {
        return state.details;
    },
    detailsByGroup (state) {
        return state.detailsByGroup;
    },
    detailsByGroupBasics (state) {
        return state.detailsByGroupBasics;
    },
    detailsByGroupUiOpened: (state) => (name) => {
        if (typeof name !== 'string') return true;
        if (!state.detailsByGroupUi.hasOwnProperty(name)) return false;
        return state.detailsByGroupUi[name].opened;
    }
};

const actions = {
    // @future I think it's a heavy and complex process. But is well structured.
    // Check if all of them are necessary and if you can simplify process
    // or reduce the times that this action is called.
    // E.g. you can perform some operations on application load
    // and do only the necessary in here.
    // This simplification will be useful for all store modules that required SchemaDefinition.
    async initializeDetailsSubModule ({ state, commit }) {
        if (state.schemaDefinition === null || state.person === null) {
            return Promise.reject(new Error('schemaDefinition and person must not be null!'));
        }

        // Get list from back-end or fallback (catch and fallback are extremely rare).
        const detailsForAboutList = await LegacyGpUiUserRecordLogic
            .provideStringListByKey('person_left_sidebar_summary_card').then((list) => list).catch(() => []);
        const detailsForAboutObj = LegacyGpUiUserRecordLogic.transformListToObjectForStore(detailsForAboutList);

        // Deep clones are necessary because we process the lists and their objects.
        // All of these objects are stored in module's state and passed in here by reference.
        const dfPropertyGroups = cloneDeep(state.schemaDefinition.propertyGroups);
        const dfPropertyDefinitions = cloneDeep(state.schemaDefinition.propertyDefinitions);
        const personProperties = cloneDeep(state.person.properties);

        // For each PropertyDefinition find its value from Person properties and add it.
        // If property value does not exist, do not include the PropertyDefinition.
        // - If you need to convert the value, here is the place to do so. BUT It's not really necessary...
        //   There are smart components that will transform the value as required.
        // - If there is any PropertyDefinition that should not be included in any section,
        //   here is the place to remove it.
        // Transform PropertyDefinition to a object that can be processed by components
        // with the necessary information only.
        // And finally order the transformed PropertyDefinition instances.
        const allDetails = dfPropertyDefinitions
            .map(function (propertyDefinition) {
                if (!personProperties.hasOwnProperty(propertyDefinition.name)) return null;
                propertyDefinition.value = personProperties[propertyDefinition.name];
                return propertyDefinition;
            })
            .filter(function (propertyDefinition) {
                return propertyDefinition !== null;
            })
            .map(function (propertyDefinition) {
                // (1) get settings based on SchemaDefinition information.
                // (2) get settings that override the previous settings
                // (usually for specific PDs where special treatment is required).
                let settings = detailsHelper.getDetailSettings(propertyDefinition);

                let value = propertyDefinition.value;
                if (propertyDefinition.type === 'ENUMERATION') {
                    const enumerationValueLabel = propertyDefinition.enumerationValuesLabels[value];
                    if (propertyDefinition.predefined === true) {
                        value = DomainTranslations.personEnum(value, propertyDefinition.name, enumerationValueLabel);
                    } else {
                        value = enumerationValueLabel;
                    }
                }

                return {
                    name: propertyDefinition.name,
                    label: DomainTranslations.personDfPd(propertyDefinition.name, propertyDefinition.label),
                    // description: propertyDefinition.description,
                    description: null, // 2020-07-13 TEMPORARY DISABLED.
                    group: propertyDefinition.group,
                    displayOrder: propertyDefinition.displayOrder,
                    displayAs: settings.displayAs,
                    value: value
                };
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        // Sorted Details for "about" section in "timeline" sub-view.
        // Check if detail should be presented in "about" section.
        // Then clone it (because some of its characteristics will change)
        // and then sort all by the new display order.
        const details = allDetails
            .map(function (detail) {
                if (!detailsForAboutObj.hasOwnProperty(detail.name)) return null;
                const newDisplayOrder = detailsForAboutObj[detail.name];
                const clonedDetail = cloneDeep(detail);
                clonedDetail.displayOrder = newDisplayOrder;
                return clonedDetail;
            })
            .filter(function (detail) {
                return detail !== null;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        // Sorted Details by "group".
        const detailsByGroupObj = allDetails
            .reduce(function (accumulator, current) {
                if (accumulator[current.group] === null || accumulator[current.group] === undefined) {
                    accumulator[current.group] = [];
                }
                accumulator[current.group].push(current);
                return accumulator;
            }, {});

        // Sorted Details by "group" - FINAL with information about groups.
        const detailsByGroupList = dfPropertyGroups
            .map(function (propertyGroup) {
                return {
                    name: propertyGroup.name,
                    label: DomainTranslations.personDfPg(propertyGroup.name, propertyGroup.label),
                    // description: propertyGroup.description,
                    description: null, // 2020-07-13 TEMPORARY DISABLED.
                    displayOrder: propertyGroup.displayOrder,
                    details: detailsByGroupObj.hasOwnProperty(propertyGroup.name) ? detailsByGroupObj[propertyGroup.name] : []
                };
            })
            // Translate hard-coded Details
            .concat(DetailsHcHelper.buildAndGet(state.person).map(function (group) {
                group.label = DomainTranslations.personEntityGroup(group.name, group.label);
                group.details = group.details.map(function (detail) {
                    detail.label = DomainTranslations.personEntityProperty(detail.name, detail.label);
                    return detail;
                });
                return group;
            }))
            .filter(function (propertyGroup) {
                return propertyGroup.details.length > 0;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        // Basic information for each group.
        const detailsByGroupBasics = detailsByGroupList
            .map(function (pg) {
                return {
                    name: pg.name,
                    label: pg.label,
                    displayOrder: pg.displayOrder
                };
            });

        // Initialize UI state information for each PropertyGroup.
        const detailsByGroupUi = detailsByGroupList
            .map(function (pg) {
                let opened;
                if (typeof state.detailsByGroupUiPreviousOpened[pg.name] === 'boolean') {
                    opened = state.detailsByGroupUiPreviousOpened[pg.name];
                } else {
                    opened = pg.name === 'basic';
                }

                return {
                    name: pg.name,
                    // UI Accordion fields
                    opened: opened
                };
            })
            .reduce(function (accumulator, current) {
                accumulator[current.name] = {
                    opened: current.opened
                };
                return accumulator;
            }, {});

        commit('setDetails', details);
        commit('setDetailsByGroup', detailsByGroupList);
        commit('setDetailsByGroupBasics', detailsByGroupBasics);
        commit('setDetailsByGroupUi', detailsByGroupUi);
    },

    resetDetailsSubModule ({ commit }) {
        commit('setDetails', []);
        commit('setDetailsByGroup', []);
        commit('setDetailsByGroupBasics', []);
        commit('setDetailsByGroupUi', {});
    }
};

const mutations = {
    setDetails (state, data) {
        if (!Array.isArray(data)) return;
        Vue.set(state, 'details', data);
    },
    setDetailsByGroup (state, data) {
        if (!Array.isArray(data)) return;
        Vue.set(state, 'detailsByGroup', data);
    },
    setDetailsByGroupBasics (state, data) {
        if (!Array.isArray(data)) return;
        Vue.set(state, 'detailsByGroupBasics', data);
    },
    setDetailsByGroupUi (state, data) {
        Vue.set(state, 'detailsByGroupUi', data);
    },

    /**
     * Toggles details group accordion.
     */
    toggleDetailsByGroupUiOpened (state, name) {
        if (!state.detailsByGroupUi.hasOwnProperty(name)) return;
        const currentState = state.detailsByGroupUi[name].opened;
        Vue.set(state.detailsByGroupUi[name], 'opened', !currentState);
        Vue.set(state.detailsByGroupUiPreviousOpened, name, !currentState); // Remember this.
    },
    ensureDetailsByGroupUiOpened (state, name) {
        if (!state.detailsByGroupUi.hasOwnProperty(name)) return;
        Vue.set(state.detailsByGroupUi[name], 'opened', true);
        Vue.set(state.detailsByGroupUiPreviousOpened, name, true); // Remember this.
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
