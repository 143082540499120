<template>
    <div id="app">
        <div id="app-layout">
            <div id="app-header-wrapper">
                <app-navigation-bar></app-navigation-bar>
            </div>
            <main id="app-main-wrapper">
                <core-loader v-if="applicationUiInitializing" :with-text="false" :with-padding="true"></core-loader>
                <router-view v-if="applicationUiReady"/>
            </main>
        </div>
        <search-view v-if="applicationUiReady"></search-view>
        <creators-dialog-view v-if="applicationUiReady"></creators-dialog-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppNavigationBar from '@/AppNavigationBar';
import CreatorsDialogView from '@/modules/Creators/views/CreatorsDialogView';
import SearchView from '@/modules/Search/views/SearchView';

/**
 * Important: The first request to 'account' resource is necessary for the client to retrieve the CSRF token.
 * If that request never happens and the user tries to login,
 * the server will respond 'access_denied / Could not verify the provided CSRF token because your session was not found.'
 */
export default {
    name: 'App',
    components: {
        SearchView,
        AppNavigationBar,
        CreatorsDialogView
    },
    computed: {
        ...mapGetters('application', {
            // UI /////////
            applicationUiInitializing: 'uiInitializing',
            applicationUiReady: 'uiReady'
        })
    }
};
</script>
