/****************************************************************************************************
 * Application UI store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 ****************************************************************************************************/

const state = {
    uiInitializing: true,
    uiInitialized: false,
    uiReady: false
};

const getters = {
    uiInitializing (state) {
        return state.uiInitializing;
    },
    uiInitialized (state) {
        return state.uiInitialized;
    },
    uiReady (state) {
        return state.uiReady;
    }
};

const actions = {
    uiCheckAndSetReadiness ({ state, commit }) {
        const isReady = state.uiInitializing === false &&
            state.uiInitialized === true &&
            state.user !== null &&
            state.tenantAccount !== null;

        commit('uiReadySet', isReady);
    }
};

const mutations = {
    uiInitializingSet (state, data) {
        // @future : Validate.
        state.uiInitializing = data;
    },
    uiInitializedSet (state, data) {
        // @future : Validate.
        state.uiInitialized = data;
    },
    uiReadySet (state, data) {
        // @future : Validate.
        state.uiReady = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
