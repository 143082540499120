/****************************************************************************************************
 * SmsCampaign store module.
 * Supports the SmsCampaignView.
 *
 * It is consisted of multiple sub-modules.
 * These sub-modules are tight coupled.
 * Purpose of sub-modules:
 * - increased readability
 * - separation of concerns
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 16 July 2020
 ****************************************************************************************************/

import unitBalanceProvider from '@/store/shared/unit-balance.submodule';
import broadcaster from './broadcaster.submodule';
import workspace from './workspace.submodule';
import operations from './operations.submodule';
import send from './send.submodule';
import validationV0 from './validation.v0.submodule';
import ui from './ui.submodule';

const unitBalance = unitBalanceProvider('SMS');

const state = {
    ...broadcaster.state,
    ...workspace.state,
    ...unitBalance.state,
    ...operations.state,
    ...send.state,
    ...validationV0.state,
    ...ui.state
};

const getters = {
    ...broadcaster.getters,
    ...workspace.getters,
    ...unitBalance.getters,
    ...operations.getters,
    ...send.getters,
    ...validationV0.getters,
    ...ui.getters
};

const actions = {
    ...broadcaster.actions,
    ...workspace.actions,
    ...unitBalance.actions,
    ...operations.actions,
    ...send.actions,
    ...validationV0.actions,
    ...ui.actions,

    async initializeModule ({ dispatch }, id) {
        await dispatch('resetModule').then(() => void 0).catch(() => void 0);
        if (typeof id !== 'string') return Promise.reject(new Error('ID cannot be null!'));
        return dispatch('getSmsCampaign', { id: id });
    },
    async resetModule ({ dispatch }) {
        await dispatch('resetBroadcasterSubModule');
        await dispatch('resetWorkspaceSubModule');
        await dispatch('resetUnitBalanceSubModule');
        await dispatch('resetOperationsSubModule');
        await dispatch('resetSendSubModule');
        await dispatch('resetValidationV0SubModule');
        await dispatch('resetUiSubModule');
    }
};

const mutations = {
    ...broadcaster.mutations,
    ...workspace.mutations,
    ...unitBalance.mutations,
    ...operations.mutations,
    ...send.mutations,
    ...validationV0.mutations,
    ...ui.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
