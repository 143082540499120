<template>
    <div class="SearchViewWrapper">
        <collapse-transition>
            <div class="SearchView" v-show="displaySearch">
                <div class="container">
                    <!-- SEARCH RESULTS -->
                    <div class="mt-4" v-if="displaySearchResults">
                        <h4>{{ $t('common_search_results') }}</h4>
                        <div class="mt-4">
                            <div class="mb-2 py-3 px-0 border-bottom" v-for="result in searchResults" :key="result.id">
                                <p class="mb-0 text-microcopy--60 font-weight-bold text-uppercase">{{ $t(result.resourceType) }}</p>
                                <p class="mb-0 size-5 weight-6 cursor-pointer" @click="onSearchResultClick(result)">{{ result.resourceName }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- EMPTY STATE -->
                    <div class="mt-4" v-if="displaySearchEmpty">
                        <h4>{{ $t('common_search_no_results') }}</h4>
                        <p class="mt-4 mb-0">{{ $t('common_search_no_results_subtitle_1') }}</p>
                        <p class="small mb-0">{{ $t('common_search_no_results_tip_1') }}</p>
                    </div>
                </div>
            </div>
        </collapse-transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Global Search overlay.
 *
 * @future implementations: Loader? Progress bar?
 *
 * @author Dimitris Gkoulis
 * @createdAt 18 July 2020
 */
export default {
    name: 'SearchView',
    computed: {
        ...mapGetters('search', {
            searchResults: 'searchResults',
            displaySearch: 'displaySearch',
            displaySearchResults: 'displaySearchResults',
            displaySearchEmpty: 'displaySearchEmpty'
        })
    },
    watch: {
        // Every time this state indicator changes the main body scroll is locked
        // in order to display loading properly.
        'displaySearch' (newValue) {
            if (newValue === true) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.className = document.body.className.replace('overflow-hidden', '');
            }
        },
        // This is a fix in order to close the search
        // when user clicks in a router-link (or in a button) from navigation.
        // It's expected that only clicks in navigation can trigger this
        // because when search is active the main section is hidden.
        '$route': {
            handler: function (to, from) {
                if (!to) return;
                if (!from) return;
                if (to.name === from.name) return;
                this.cancelSearch();
            },
            immediate: true
        }
    },
    methods: {
        cancelSearch () {
            this.$store.dispatch('search/cancelSearch').then(() => void 0).catch(() => void 0);
        },
        onSearchResultClick (result) {
            switch (result.resourceType) {
            case 'PERSON':
                this.cancelSearch();
                this.$router.push({ name: 'person', params: { id: result.resourceIdentifier } });
                break;
            case 'EMAIL_CAMPAIGN':
                this.cancelSearch();
                this.$router.push({ name: 'email-campaign', params: { id: result.resourceIdentifier } });
                break;
            case 'SMS_CAMPAIGN':
                this.cancelSearch();
                this.$router.push({ name: 'sms-campaign', params: { id: result.resourceIdentifier } });
                break;
            case 'WORKSPACE':
                this.cancelSearch();
                this.$router.push({ name: 'people-workspace', params: { id: result.resourceIdentifier } });
                break;
            case 'EMAIL_TEMPLATE':
                this.cancelSearch();
                this.$router.push({ name: 'email-template', params: { id: result.resourceIdentifier } });
                break;
            case 'BROADCASTER':
                this.cancelSearch();
                this.$router.push({ name: 'broadcasters', query: { gcCreator: 'broadcaster', gcEditorIdentifier: result.resourceIdentifier } });
                break;
            case 'SENDER_EMAIL_ADDRESS':
                this.cancelSearch();
                this.$router.push({ name: 'sender-email-addresses', params: { id: result.resourceIdentifier } });
                break;
            default:
                break; // Do nothing.
            }
        }
    }
};
</script>

<style lang="scss">
    .SearchView {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f5f4f2;
        z-index: 1450;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>

<i18n>
{
    "en": {
        "PERSON": "Person",
        "WORKSPACE": "Workspace",
        "EMAIL_CAMPAIGN": "Email Campaign",
        "SMS_CAMPAIGN": "SMS Campaign",
        "EMAIL_TEMPLATE": "Email Template",
        "SENDER_EMAIL_ADDRESS": "Sender Email Address",
        "BROADCASTER": "Broadcaster"
    },
    "el": {
        "PERSON": "Άτομο",
        "WORKSPACE": "Χώρος Εργασία",
        "EMAIL_CAMPAIGN": "Εκστρατεία Email",
        "SMS_CAMPAIGN": "Εκστρατεία SMS",
        "EMAIL_TEMPLATE": "Πρότυπο Email",
        "SENDER_EMAIL_ADDRESS": "Διεύθυνση Email Αποστολέα",
        "BROADCASTER": "Μεταδότης"
    }
}
</i18n>
