/**
 * Person Edit module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
const routes = [
    {
        path: '/:tenantId/people/person-edit/:id?',
        name: 'person-edit',
        component: () => import(/* webpackChunkName: "personedit" */ '@/modules/PersonEdit/views/PersonEditView.vue')
    },
    {
        path: '/:tenantId/people/person-edit-redirect/:id?',
        name: 'person-edit-redirect',
        component: () => import(/* webpackChunkName: "personeditredirect" */ '@/modules/PersonEdit/views/PersonEditRedirectView.vue')
    }
];

export default routes;
