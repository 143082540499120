/****************************************************************************************************
 * Search store module.
 * Handles all aspects of search domain.
 * It is being used by SearchView and application's main navigation.
 * It is responsible for UI state and for the search operation.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 18 July 2020
 ****************************************************************************************************/

import Vue from 'vue';
import debounce from 'lodash/debounce';
import { ResourceSimpleIndexService } from '@/common/services/api.service';

// If you let user select them
// you have to ensure that all are included
// in case that user has not selected any type.
const SearchResourceTypes = [
    'PERSON',
    'EMAIL_CAMPAIGN',
    'SMS_CAMPAIGN',
    'WORKSPACE',
    'EMAIL_TEMPLATE',
    'SENDER_EMAIL_ADDRESS',
    'BROADCASTER'
];

const state = {
    searchForceOpen: false,
    searchResourceTypes: SearchResourceTypes,
    searchTerm: '',
    searchResults: [],
    searching: false,
    searchError: null
};

const getters = {
    searchTerm (state) {
        return state.searchTerm;
    },
    searchResults (state) {
        return state.searchResults;
    },

    // UI state indicators.
    // Independent (do not use v-if v-else)
    // @future Make them computed? E.g. declare them in state
    // and use a mutation on each change to synchronize them.
    // Be careful, it may required to call 'sync' in different places.
    // E.g. on the first search it may be better to call 'sync'
    // in the begging of the process but before search results are fetched.
    displaySearch (state) {
        return state.searchForceOpen === true || state.searchTerm.trim() !== '';
    },
    displaySearchResults (state) {
        return state.searching === false && state.searchResults.length > 0 && state.searchError === null;
    },
    displaySearchEmpty (state) {
        return state.searching === false && state.searchTerm.trim() !== '' && (state.searchResults.length === 0 || state.searchError !== null);
    }
};

const actions = {
    search ({ commit, state, dispatch }, { searchTermParam }) {
        commit('setSearching', true);
        commit('setSearchError', null);

        // Set user input.
        commit('setSearchTerm', searchTermParam);

        // Check if search should be performed.
        if (state.searchTerm.trim() === '') {
            commit('setSearching', false);
            commit('setSearchError', null);
            commit('setSearchResults', []);
            return Promise.resolve([]);
        }

        // The first search sets searchForceOpen to true.
        // WHY? UX of course. We force search to remain open only after the first search.
        // This provides a smooth experience to user especially when tries multiple terms.
        // After the first search user should click on 'times' icon in order to close search.
        // Or click outside wrapper in order to trigger blur event.
        commit('setSearchForceOpen', true);

        return ResourceSimpleIndexService.searchResources(state.searchTerm, state.searchResourceTypes, 0, 30)
            .then(({ data }) => {
                commit('setSearchResults', data);
                commit('setSearchError', null);
            })
            .catch((reason) => {
                commit('setSearchResults', []);
                commit('setSearchError', reason);
            })
            .finally(() => {
                commit('setSearching', false);
            });
    },

    typingSearchEvent: debounce(function ({ dispatch }, params) {
        return dispatch('search', params);
    }, 500),

    cancelSearch ({ commit }) {
        commit('setSearchForceOpen', false);
        commit('setSearchTerm', '');
        commit('setSearching', false);
        commit('setSearchError', null);
        commit('setSearchResults', []);
    },

    blurSearchEvent ({ state, dispatch }) {
        if (state.searchTerm.trim() !== '') return Promise.resolve();
        return dispatch('cancelSearch');
    }
};

const mutations = {
    setSearchForceOpen (state, data) {
        if (typeof data !== 'boolean') Vue.set(state, 'searchForceOpen', false);
        else Vue.set(state, 'searchForceOpen', data);
    },
    setSearchTerm (state, data) {
        let newSearchTerm;
        if (typeof data !== 'string') newSearchTerm = '';
        if (data.trim() === '') newSearchTerm = '';
        newSearchTerm = data; // data.trim(); DO NOT TRIM- REMEMBER opera bookmarks :) :P
        Vue.set(state, 'searchTerm', newSearchTerm);
    },
    setSearchResults (state, data) {
        if (!Array.isArray(data)) Vue.set(state, 'searchResults', []);
        else Vue.set(state, 'searchResults', data);
    },
    setSearching (state, data) {
        if (typeof data !== 'boolean') Vue.set(state, 'searching', false);
        else Vue.set(state, 'searching', data);
    },
    setSearchError (state, data) {
        if (typeof data === 'undefined') data = null;
        Vue.set(state, 'searchError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
