<template>
    <div class="SimpleStateError" :class="{ 'SimpleStateError--Default': defaultStyle }">
        <p class="mb-1 size-4">
            <span class="text-danger mr-1"><i class="fas fa-exclamation-circle"></i></span>
            <span>{{ $t('sserror_title_1') }}</span>
        </p>
        <p class="mb-4">{{ $t('sserror_subtitle_1') }}</p>
        <p class="mb-0" v-if="error">
            <span class="text-microcopy--70 cursor-pointer" @click="displayDetails = !displayDetails">{{ $t('sserror_details') }}</span>
            <!--
            <span class="text-microcopy--70 dot-separator">{{ $t('sserror_report_problem') }}</span>
            -->
        </p>
        <div v-if="error && displayDetails">
            <p class="mb-0 text-danger small" v-if="error.message">M: {{ error.message }}</p>
            <p class="mb-0 text-danger small" v-if="error.dataMessage">DM: {{ error.dataMessage }}</p>
            <p class="mb-0 text-danger small" v-if="error.dataDetail">DD: {{ error.dataDetail }}</p>
            <p class="mb-0 text-danger small" v-if="error.httpStatus">HS: {{ error.httpStatus }}</p>
            <p class="mb-0 text-danger small" v-if="error.dataTimestamp">P: {{ error.dataPath }}</p>
            <p class="mb-0 text-danger small" v-if="error.dataTimestamp">T: {{ error.dataTimestamp }}</p>
        </div>
    </div>
</template>

<script>
/**
 * Simple error state.
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 July 2020
 */
export default {
    name: 'SimpleStateError',
    props: {
        error: {
            required: false
        },
        defaultStyle: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data () {
        return {
            displayDetails: false
        };
    }
};
</script>

<style lang="scss">
    .SimpleStateError--Default {
        position: relative;
        margin-top: 1.5rem;
    }
</style>

<i18n>
{
    "en": {
        "sserror_title_1": "Something went wrong",
        "sserror_subtitle_1": "Sorry for the interruption but it seems that something didn't work as expected.",
        "sserror_details": "Technical Details",
        "sserror_report_problem": "Report"
    },
    "el": {
        "sserror_title_1": "Κάτι πήγε στραβά",
        "sserror_subtitle_1": "Μας συγχωρείτε για τη διακοπή αλλά από ότι φαίνεται κάτι δε δούλεψε σωστά.",
        "sserror_details": "Τεχνικές Λεπτομέρειες",
        "sserror_report_problem": "Αναφορά Προβλήματος"
    }
}
</i18n>
