/****************************************************************************************************
 * DfFlatUiCustomization store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 1 August 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import i18n from '@/common/plugins/i18n';
import DomainTranslations from '@/modules/DomainTranslations';
import schemaDefinitionDynamicProvider from '@/store/shared/df-dynamic.submodule';
import RandomUtils from '@/common/utils/random.utils';
import LegacyGpUiUserRecordLogic from '@/common/logic/legacy-gp-ui-user-record.logic';
import DomainModel from '@/common/logic/domain-models';
import { LegacyGpUiUserRecordService } from '@/common/services/api.service';

const schemaDefinition = schemaDefinitionDynamicProvider();

const state = {
    ...schemaDefinition.state,

    initializing: false,

    legacyGpUiUserRecordKey: null,

    // UI data to support checkboxes and UI functionality
    pgOptions: [],
    // DnD - this list contains the actual data that are persisted in back-end.
    pdActives: []
    // pgOptions and pdActives must be synchronized.
};

const getters = {
    ...schemaDefinition.getters,

    pageTitle (state) {
        if (state.schemaDefinition === null) return null;
        const fallbackTitle = DomainTranslations
            .dfTitleRtU(state.schemaDefinition.name, state.schemaDefinition.label) +
            ' - ' +
            i18n.t('common_action_customize');

        if (typeof state.legacyGpUiUserRecordKey !== 'string') return fallbackTitle;

        // Check if there is a registered title for that user customization type.
        // key example: 'legacyGpUiUserRecord_person:person_left_sidebar_summary_card'
        const translKey = 'legacyGpUiUserRecord_' + state.schemaDefinitionName + ':' + state.legacyGpUiUserRecordKey;
        if (i18n.te(translKey)) return i18n.t(translKey);

        return fallbackTitle;
    },

    pgOptions (state) {
        return state.pgOptions;
    },
    pdActives (state) {
        return state.pdActives;
    },

    pdActivesLengthOne (state) {
        return state.pdActives.length === 1;
    },

    stateIsChanging (state) {
        return state.initializing || state.propertyGroupDeleting;
    },
    displayEmptyState (state) {
        return state.initializing === false && state.schemaDefinition === null;
    },
    displayMain (state) {
        return state.initializing === false && state.schemaDefinition !== null;
    }
};

const actions = {
    ...schemaDefinition.actions,

    async initializeModule ({ dispatch, commit }) {
        commit('setInitializing', true);

        if (typeof state.legacyGpUiUserRecordKey !== 'string') {
            commit('setInitializing', false);
            return Promise.reject(new Error('legacyGpUiUserRecordKey is missing!'));
        }

        const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);

        // Validate schemaDefinition.
        if (schemaDefinition === null) {
            commit('setInitializing', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }

        // List with names of PDs. See LegacyGpUiUserRecordLogic docs for more info.
        const stringList = await LegacyGpUiUserRecordLogic.provideStringListByKey(state.legacyGpUiUserRecordKey, false)
            .then((data) => data).catch(() => []);
        const stringListObjByName = LegacyGpUiUserRecordLogic.transformListToObjectForStore(stringList);

        const domain = schemaDefinition.name; // for quick access.

        // Deep clones are necessary because we process the lists and their objects.
        // All of these objects are stored in module's state and passed in here by reference.
        const dfPropertyGroups = cloneDeep(schemaDefinition.propertyGroups);
        const dfPropertyDefinitions = cloneDeep(schemaDefinition.propertyDefinitions);

        const propertyDefinitionsOptions = dfPropertyDefinitions
            .map(function (propertyDefinition) {
                let active = false;
                let initialOrder = 0;

                if (stringListObjByName.hasOwnProperty(propertyDefinition.name)) {
                    active = true;
                    initialOrder = stringListObjByName[propertyDefinition.name];
                }

                return {
                    rId: RandomUtils.getUniqueId(),
                    name: propertyDefinition.name,
                    label: DomainTranslations.dfPd(domain, propertyDefinition.name, propertyDefinition.label),
                    displayOrder: propertyDefinition.displayOrder,
                    group: propertyDefinition.group,
                    // data will be retrieved by LegacyGpUiUserRecord instance.
                    active: active,
                    initialOrder: initialOrder
                };
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        const propertyDefinitionsOptionsByGroup = propertyDefinitionsOptions
            .reduce(function (accumulator, current) {
                if (accumulator[current.group] === null || accumulator[current.group] === undefined) {
                    accumulator[current.group] = [];
                }
                accumulator[current.group].push(current);
                return accumulator;
            }, {});

        const pgOptions = dfPropertyGroups
            .map(function (propertyGroup) {
                let propertyDefinitionList = [];
                if (propertyDefinitionsOptionsByGroup.hasOwnProperty(propertyGroup.name)) {
                    propertyDefinitionList = propertyDefinitionsOptionsByGroup[propertyGroup.name];
                }

                return {
                    rId: RandomUtils.getUniqueId(),
                    name: propertyGroup.name,
                    label: DomainTranslations.dfPg(domain, propertyGroup.name, propertyGroup.label),
                    displayOrder: propertyGroup.displayOrder,
                    pdOptions: propertyDefinitionList
                };
            })
            .filter(function (propertyGroup) {
                return propertyGroup.pdOptions.length > 0;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        const pdActives = propertyDefinitionsOptions
            .filter(function (pdOption) {
                return pdOption.active === true;
            })
            .map(function (pdOption) {
                return cloneDeep(pdOption);
            })
            .sort(function (a, b) {
                return a.initialOrder - b.initialOrder;
            });

        commit('setPgOptions', pgOptions);
        commit('setPdActives', pdActives);

        // Ensure that settings are stored.
        // @future enable?
        // await dispatch('transformOptionsAndStoreUserChanges').then(() => void 0).catch(() => void 0);

        commit('setInitializing', false);

        return Promise.resolve();
    },
    async resetModule ({ dispatch, commit }) {
        dispatch('resetSchemaDefinitionDynamicSubModule');

        // Reset index state.
        commit('setInitializing', false);
        commit('setLegacyGpUiUserRecordKey', null);
        commit('setPgOptions', []);
        commit('setPdActives', []);
    },

    transformOptionsAndStoreUserChangesWithDebounce: debounce(function ({ dispatch }) {
        return dispatch('transformOptionsAndStoreUserChanges');
    }, 500),
    transformOptionsAndStoreUserChanges ({ state }) {
        // Transform pdActives to DTO stringList.
        const stringList = state.pdActives
            .map(function (pdActive) {
                return pdActive.name;
            });

        // Build DTO.
        const dto = DomainModel.getLegacyGpUiUserRecordDTO();
        dto.key = state.legacyGpUiUserRecordKey;
        dto.stringList = stringList;

        return LegacyGpUiUserRecordService.saveLegacyGpUiUserRecord(dto)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch((reason) => {
                return Promise.reject(reason);
            });
    },
    handlePdOptionActiveChange ({ dispatch, commit }, { newActive, pgOptionIndex, pdOptionIndex }) {
        commit('setPgOptionsPdOptionActiveAndSync', {
            newActive: newActive,
            pgOptionIndex: pgOptionIndex,
            pdOptionIndex: pdOptionIndex
        });
        return dispatch('transformOptionsAndStoreUserChangesWithDebounce');
    },
    handlePdActivesOrderChange ({ dispatch, commit }, data) {
        commit('setPdActives', data);
        return dispatch('transformOptionsAndStoreUserChangesWithDebounce');
    }
};

const mutations = {
    ...schemaDefinition.mutations,

    setInitializing (state, data) {
        Vue.set(state, 'initializing', data);
    },

    setLegacyGpUiUserRecordKey (state, data) {
        Vue.set(state, 'legacyGpUiUserRecordKey', data);
    },

    setDomainAndKeyById (state, data) {
        if (typeof data !== 'string') {
            Vue.set(state, 'schemaDefinitionName', null);
            Vue.set(state, 'legacyGpUiUserRecordKey', null);
            return;
        }

        const parts = data.split(':');
        if (parts.length === 0) {
            // reset.
            Vue.set(state, 'schemaDefinitionName', null);
            Vue.set(state, 'legacyGpUiUserRecordKey', null);
        } else if (parts.length === 1) {
            Vue.set(state, 'schemaDefinitionName', parts[0]); // from df-dynamic (the state)
            Vue.set(state, 'legacyGpUiUserRecordKey', null); // reset
        } else if (parts.length === 2 || parts.length > 2) { // TEMPORARY: If parts more than 2, ignore the rest.
            Vue.set(state, 'schemaDefinitionName', parts[0]); // from df-dynamic (the state)
            Vue.set(state, 'legacyGpUiUserRecordKey', parts[1]);
        } else {}
    },

    setPgOptions (state, data) {
        Vue.set(state, 'pgOptions', data);
    },
    setPdActives (state, data) {
        Vue.set(state, 'pdActives', cloneDeep(data));
    },

    setPgOptionsPdOptionActiveAndSync (state, { newActive, pgOptionIndex, pdOptionIndex }) {
        if (typeof newActive !== 'boolean') return;
        if (typeof pgOptionIndex !== 'number') return;
        if (typeof pdOptionIndex !== 'number') return;
        // Check if indexes are in range.
        if (typeof state.pgOptions[pgOptionIndex] === 'undefined') return;
        if (typeof state.pgOptions[pgOptionIndex]['pdOptions'][pdOptionIndex] === 'undefined') return;

        Vue.set(state.pgOptions[pgOptionIndex]['pdOptions'][pdOptionIndex], 'active', newActive);

        // Sync
        const pdOption = cloneDeep(state.pgOptions[pgOptionIndex]['pdOptions'][pdOptionIndex]);
        const pdOptionName = pdOption.name;
        pdOption['rId'] = RandomUtils.getUniqueId();

        // First, remove if already exists.
        let indexToRemove = -1;
        for (let i = 0; i < state.pdActives.length; i++) {
            if (state.pdActives[i].name === pdOptionName) {
                indexToRemove = i;
                break;
            }
        }

        if (indexToRemove >= 0) {
            state.pdActives.splice(indexToRemove, 1);
        }

        // If newActive is true, add the value in the end.
        if (newActive === true) {
            state.pdActives.push(pdOption);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
