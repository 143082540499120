<template>
    <div :id="elementId"></div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { Spinner } from 'spin.js';
import RandomUtils from '@/common/utils/random.utils';

const DEFAULT_SPINNER_OPTS = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 17, // The line thickness
    radius: 45, // The radius of the inner circle
    scale: 0.15, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#69717d', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute' // Element positioning
};

/**
 * spin.js spinner
 *
 * @see http://spin.js.org/
 *
 * @author Dimitris Gkoulis
 * @createdAt 8 November 2020
 */
export default {
    name: 'SpinSpinner',
    props: {
        scale: {
            type: Number,
            default: 0.15
        },
        color: {
            type: String,
            default: '#69717d'
        }
    },
    data () {
        return {
            spinner: null,
            elementId: null
        };
    },
    beforeMount () {
        this.elementId = RandomUtils.getUniqueId('spin-spinner-');
    },
    mounted () {
        const target = document.getElementById(this.elementId);
        const opts = cloneDeep(DEFAULT_SPINNER_OPTS);
        opts['scale'] = this.scale;
        opts['color'] = this.color;
        this.spinner = new Spinner(opts).spin(target);
    },
    beforeDestroy () {
        if (this.spinner !== null) {
            this.spinner.stop();
        }
    }
};
</script>

<style lang="css">
    @keyframes spinner-line-fade-more {
        0%, 100% {
            opacity: 0; /* minimum opacity */
        }
        1% {
            opacity: 1;
        }
    }

    @keyframes spinner-line-fade-quick {
        0%, 39%, 100% {
            opacity: 0.25; /* minimum opacity */
        }
        40% {
            opacity: 1;
        }
    }

    @keyframes spinner-line-fade-default {
        0%, 100% {
            opacity: 0.22; /* minimum opacity */
        }
        1% {
            opacity: 1;
        }
    }

    @keyframes spinner-line-shrink {
        0%, 25%, 100% {
            /* minimum scale and opacity */
            transform: scale(0.5);
            opacity: 0.25;
        }
        26% {
            transform: scale(1);
            opacity: 1;
        }
    }
</style>
