/****************************************************************************************************
 * Application access store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 * @lastModifiedAt 8 September 2020
 ****************************************************************************************************/

import { AccountLogic } from '@/common/logic/account.logic';

const state = {
    accessHasTenantUserAccessLevel: false,
    accessHasTenantAdminAccessLevel: false
};

const getters = {
    accessHasTenantUserAccessLevel (state) {
        return state.accessHasTenantUserAccessLevel;
    },
    accessHasTenantAdminAccessLevel (state) {
        return state.accessHasTenantAdminAccessLevel;
    }
};

const actions = {
    /**
     * Builds access level list.
     *
     * Important: This actions, checks if user's combined authorities imply access to current tenant.
     * The tenantId is retrieved from URL.
     *
     * We expect that before app initialization a remote operation will ensure that user has access to current tenant.
     */
    accessBuild ({ state, commit }) {
        // Check if user or contextTenantId is null.
        if (state.user === null || state.contextTenantId === null) {
            commit('accessHasTenantUserAccessLevelSet', false);
            commit('accessHasTenantAdminAccessLevelSet', false);
            return;
        }

        const accessLevelList = AccountLogic.getRtuAccessLevelList(state.user, state.contextTenantId);

        commit('accessHasTenantUserAccessLevelSet', accessLevelList.hasTenantUserAccessLevel);
        commit('accessHasTenantAdminAccessLevelSet', accessLevelList.hasTenantAdminAccessLevel);
    }
};

const mutations = {
    accessHasTenantUserAccessLevelSet (state, data) {
        // Ensure validity and set default 'false'.
        if (data === null) {
            data = false;
        }
        if (typeof data !== 'boolean') {
            data = false;
        }
        state.accessHasTenantUserAccessLevel = data;
    },
    accessHasTenantAdminAccessLevelSet (state, data) {
        // Ensure validity and set default 'false'.
        if (data === null) {
            data = false;
        }
        if (typeof data !== 'boolean') {
            data = false;
        }
        state.accessHasTenantAdminAccessLevel = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
