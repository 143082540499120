import Vue from 'vue';

import './styles/styles.scss';

import AppDependencies from './common/init/Dependencies.init';
import AppComponents from './common/init/Components.init';
import AppFilters from './common/init/Filters.init';
import AppMixins from './common/init/Mixins.init';
import AppApiService from './common/init/ApiService.init';
import AppRouter from './common/init/Router.init';
import AppServices from './common/init/Services.init';
import AppVueConfig from './common/init/VueConfig.init';

import App from './App.vue';
import store from './store/index';
import router from './router/index';
// import './registerServiceWorker'; // TEMPORARY DISABLED!
import i18n from './common/plugins/i18n';

AppDependencies.init();
AppComponents.init();
AppFilters.init();
AppMixins.init();
AppApiService.init();
AppRouter.init();
AppServices.init();
AppVueConfig.init();

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
