/****************************************************************************************************
 * People sub-module of people store module.
 * Responsible for searching operations (states, error handling, remote operations, etc).
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { PersonService } from '@/common/services/api.service';

// State.
const state = {
    peopleFetching: false,
    peopleFetchError: null,
    people: [],
    // Total People count in QUERY!
    peopleCount: 0,
    // Total People count in DB Collection!
    peopleCollectionCount: 0
};

// Getters.
const getters = {
    peopleFetching (state) {
        return state.peopleFetching;
    },
    peopleFetchError (state) {
        return state.peopleFetchError;
    },
    people (state) {
        return state.people;
    },
    // People in store (page entries)
    peopleLength (state) {
        return state.people.length;
    },
    // Total People count in QUERY!
    peopleCount (state) {
        return state.peopleCount;
    },

    // State indicators for UI components
    displayPeopleListing (state) {
        return state.peopleFetching === false && state.people.length > 0 && state.peopleFetchError === null;
    },
    displayPeopleEmptyState (state) {
        return state.peopleFetching === false && state.people.length === 0 && state.peopleFetchError === null;
    },
    displayPeopleFirstUseEmptyState (state) {
        return state.peopleCollectionCount === 0;
    },
    displayPeopleError (state) {
        return state.peopleFetching === false && state.peopleFetchError !== null;
    }
};

// Actions.
const actions = {
    searchPeople ({ commit, state }) {
        // Start Loader.
        commit('setPeopleFetching', true);

        // Reset error.
        commit('setPeopleFetchError', null);

        // Get searchDetails.
        const searchDetails = get(state, 'workspaceWorking.searchDetails', null);

        // Check searchDetails.
        if (searchDetails === null) {
            return Promise.reject(new Error('Search Details object is null'));
        }

        return PersonService.searchPeopleAdvanced(searchDetails, false)
            .then((data) => {
                commit('setPeopleCount', parseInt(data.headers['x-total-count'], 10));
                commit('setPeopleCollectionCount', parseInt(data.headers['x-collection-count'], 10));
                commit('setPeople', data.data);
                return Promise.resolve(data.data);
            })
            .catch((error) => {
                commit('setPeopleFetchError', error);
                commit('setPeopleCount', 0);
                commit('setPeopleCollectionCount', 0);
                commit('setPeople', []);
                // It's expected that an error state will be displayed
                // Not empty state or first use empty state.
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setPeopleFetching', false);
            });
    },
    searchPeopleWithDebounce: debounce(function ({ dispatch }) {
        return dispatch('searchPeople');
    }, 0),
    resetPeopleSubModule ({ commit }) {
        commit('setPeopleFetching', false);
        commit('setPeopleFetchError', null);
        commit('setPeople', []);
        commit('setPeopleCount', 0);
    }
};

// Mutations.
const mutations = {
    setPeopleFetching (state, value) {
        state.peopleFetching = value;
    },
    setPeopleFetchError (state, value) {
        state.peopleFetchError = value;
    },
    setPeople (state, value) {
        state.people = cloneDeep(value);
    },
    setPeopleCount (state, value) {
        if (typeof value !== 'number') return;
        state.peopleCount = value;
    },
    setPeopleCollectionCount (state, value) {
        if (typeof value !== 'number') return;
        state.peopleCollectionCount = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
