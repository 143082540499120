import fechaObj from 'fecha';

import i18n from '@/common/plugins/i18n';

import elementElLocale from 'element-ui/lib/locale/lang/el';
import elementEnLocale from 'element-ui/lib/locale/lang/en';

import fechaLocale from '@/locale/third-party/fecha.locale';

import DomainTranslations from '@/modules/DomainTranslations';

const LANG_KEY_FALLBACK = 'en';
const SUPPORTED_LANG_KEYS = ['en', 'el'];

function validateLangKeyOrFallback (langKey) {
    if (langKey === null || langKey === undefined) return LANG_KEY_FALLBACK;
    if (typeof langKey !== 'string') return LANG_KEY_FALLBACK;
    // Check if langKey is supported by application.
    if (SUPPORTED_LANG_KEYS.some(item => item === langKey)) return langKey;
    return LANG_KEY_FALLBACK;
}

function setLangKeyToDomElements (langKey) {
    document.querySelector('html').setAttribute('lang', langKey);
}

const ApplicationI18n = {
    async init (langKey) {
        // Validate or get safe value.
        langKey = validateLangKeyOrFallback(langKey);

        // Set the active locale.
        i18n.locale = langKey;

        // Set langKey to DOM elements.
        setLangKeyToDomElements(langKey);

        // Load application messages for that langKey
        // @future : Do imports fail?
        let messages;
        if (langKey === 'el') {
            messages = await import(/* webpackChunkName: "lang-el" */ '@/locale/el')
                .then(messages => messages.default).catch(ignoredError => {});
        } else {
            messages = await import(/* webpackChunkName: "lang-en" */ '@/locale/en')
                .then(messages => messages.default).catch(ignoredError => {});
        }

        // Load element-ui messages for that langKey.
        let elementMessages;
        if (langKey === 'el') elementMessages = elementElLocale;
        else elementMessages = elementEnLocale;

        // Set messages.
        i18n.setLocaleMessage(langKey, {
            ...elementMessages,
            ...messages
        });

        // Set fecha locale (only for el).
        if (langKey === 'el') {
            // fechaObj.i18n = fechaLocale.el; // NOT WORKING
            // fechaObj.defaultI18n = fechaLocale.el; // NOT WORKING
            fechaObj.setGlobalDateI18n(fechaLocale.el);
        }

        // Initialize DomainTranslations.
        await DomainTranslations.initialize(langKey).then(() => void 0).catch(() => void 0);

        return Promise.resolve(langKey);
    }
};

export default ApplicationI18n;
