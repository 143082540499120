/****************************************************************************************************
 * Person Activity store module.
 *
 * This module uses (extends and customizes) the functionality of genericListing store module,
 * but performs the remote operations by itself (remote operations related to business logic).
 *
 * @future as you can see 'resetPersonActivityModule' is mutation and not action.
 * Also, this mutation implements both reset and initialization.
 * Pros to paron asto etsi giati mas exipiretei. ALLA SAN LOGIKI DEN EINAI 100% SOSTI.
 *
 * @author Dimitris Gkoulis
 * @createdAt 26 June 2020
 ****************************************************************************************************/
import Vue from 'vue';
import map from 'lodash/map';
import filter from 'lodash/filter';
import genericListing, {
    RESET_DATA,
    RESET_DATA_COUNT,
    RESET_ERROR,
    SET_DATA,
    SET_DATA_COUNT,
    SET_ERROR,
    START_FETCHING,
    STOP_FETCHING
} from '@/store/generics/genericListing.module';
import { ActivityService } from '@/common/services/api.service';
import ActivityTypeLogic from '@/common/logic/activity-type.logic';

// These defaults are for this module (personActivity)!
const defaultListingPage = 0;
const defaultListingSize = 30; // @help : You must set to PersonActivityAccessMixin.vue too.
const defaultListingSortField = 'timestamp';
const defaultListingSortType = 'desc';

const module = genericListing({
    createOnePath: null,
    updateOnePath: null,
    getAllPath: null,
    getOnePath: null,
    deleteOnePath: null
}, {
    listingPage: defaultListingPage,
    listingSize: defaultListingSize,
    listingSortField: defaultListingSortField,
    listingSortType: defaultListingSortType
});

const state = {
    personId: null,
    activityTypes: [],
    ...module.state
};

const getters = {
    personId (state) {
        return state.personId;
    },
    ...module.getters
};

const actions = {
    getActivities ({ commit, state }) {
        commit(START_FETCHING);

        let list = map(filter(state.activityTypes, function (item) {
            return item.checked;
        }), function (item) {
            return item.key;
        });

        // If list is empty, add all activity types.
        // We do this because back-end will return all activity types
        // (including types that user-tenant must not see due to add-on enabled/disabled).
        // For example, if tenant used to have politics add-on and then sometime later disabled it,
        // if the list is empty, it will return data from politics.
        // We don't want this. BUT that back-end behavior is right.
        // So, front-end, adds all activity types for this tenant. :)
        if (list.length === 0) {
            list = map(state.activityTypes, function (item) {
                return item.key;
            });
        }

        // Check personId.
        if (state.personId === null) {
            commit(STOP_FETCHING);
            return Promise.reject(new Error('personId must not be null!'));
        }

        // Retrieve activities.
        return ActivityService.retrievePersonActivityFeed(state.personId, list, state.listingPage, state.listingSize, state.listingSortField, state.listingSortType)
            .then((data) => {
                // Set new data and reset error.
                commit(SET_DATA_COUNT, parseInt(data.headers['x-total-count'], 10));
                commit(SET_DATA, data.data);
                commit(RESET_ERROR);
                return Promise.resolve(data.data);
            })
            .catch((error) => {
                // Reset data and set error.
                commit(RESET_DATA_COUNT);
                commit(RESET_DATA);
                commit(SET_ERROR, error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit(STOP_FETCHING);
            });
    },
    ...module.actions
};

const mutations = {
    setPersonId (state, data) {
        Vue.set(state, 'personId', data);
    },
    resetPersonActivityModule (state, resetChecked = true) {
        // Check if activityTypes list should be initialized.
        // This check ensures that 'activityTypes' list is initialized once per application load.
        // ActivityTypeLogic returns a list with initialized details for each ActivityType.
        // Labels are also translated.
        if (!Array.isArray(state.activityTypes) || state.activityTypes.length === 0) {
            state.activityTypes = ActivityTypeLogic.activityTypesForPersonActivity();
        }

        // Reset 'checked' field if resetChecked is true.
        // That is reset of user options.
        if (resetChecked) {
            for (let activityTypeItem of state.activityTypes) {
                activityTypeItem.checked = true;
            }
        }

        state.fetching = false;
        state.data = [];
        state.dataCount = 0;
        state.listingPage = defaultListingPage;
        state.listingSize = defaultListingSize;
        state.listingSortField = defaultListingSortField;
        state.listingSortType = defaultListingSortType;
        state.error = null;
    },

    replaceActivity (state, { index, activity }) {
        activity.vueKey = 'vueKey' + new Date().getTime(); // To ensure that component is re-rendered.
        Vue.set(state.data, index, activity);
    },
    removeActivity (state, { index }) {
        state.data.splice(index, 1);
    },
    ...module.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
