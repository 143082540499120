<template>
    <div class="clv-interaction-locker" v-if="active"></div>
</template>

<script>
/**
 * Locks any user-web app interaction
 * by displaying an invisible fixed overlay.
 *
 * ATTENTION: I may blocked by ad-blockers.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 */
export default {
    name: 'ClvInteractionLocker',
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false
        }
    }
};
</script>

<style lang="scss">
    .clv-interaction-locker {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 10000 !important;
    }
</style>
