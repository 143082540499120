/****************************************************************************************************
 * Domain Model provider.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 July 2020
 * @lastModifiedAt 15 November 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const EmailCampaign = {
    id: null,
    name: '',
    description: '',
    status: 'DRAFT',
    outcome: 'NONE',
    subject: '',
    previewText: '',
    editor: 'BUILDER', // In back-end the default is HTML.
    builderDataSerialized: null,
    bodyPlain: '',
    bodyHtml: '',
    broadcasterId: null,
    workspaceId: null,
    broadcaster: null,
    workspace: null,
    // SendableCampaign //////////
    recipientsCount: 0,
    validRecipientsCount: 0,
    completionTimestamp: null,
    sentBy: null,
    sentTimestamp: null,
    // EmailCampaignSettings ///////////
    settings: {
        languageKey: 'EN',
        openTracking: true,
        clickTracking: true,
        plainTextClickTracking: false,
        productBranding: true,
        transparencyAndCompliance: true
    },
    // AbstractAuditingEntity //////////
    meta: {
        createdBy: '',
        createdDate: null,
        lastModifiedBy: '',
        lastModifiedDate: null
    }
};

const SmsCampaign = {
    id: null,
    name: '',
    description: '',
    status: 'DRAFT',
    outcome: 'NONE',
    body: '',
    flash: false,
    providerId: null,
    broadcasterId: null,
    workspaceId: null,
    broadcaster: null,
    workspace: null,
    // SendableCampaign //////////
    recipientsCount: 0,
    validRecipientsCount: 0,
    completionTimestamp: null,
    sentBy: null,
    sentTimestamp: null,
    // AbstractAuditingEntity //////////
    meta: {
        createdBy: '',
        createdDate: null,
        lastModifiedBy: '',
        lastModifiedDate: null
    }
};

const EmailTemplate = {
    id: null,
    name: '',
    description: '',
    editor: 'HTML',
    mjml: '',
    mjmlStructured: '',
    html: '',
    // AbstractAuditingEntity //////////
    meta: {
        createdBy: '',
        createdDate: null,
        lastModifiedBy: '',
        lastModifiedDate: null
    }
};

const PersonLifeEvent = {
    id: null,
    personId: null,
    title: '',
    description: '',
    category: 'CUSTOM',
    icon: '',
    date: {
        fullDate: null,
        clientFullDate: null,
        year: null, // It cannot be null.
        month: null,
        day: null
    },
    specific: {
        location: '',
        educationalOrganization: '',
        workplace: '',
        personId: null
    },
    extra: {}
};

const PropertyGroup = {
    name: '',
    label: '',
    description: '',
    displayOrder: 100,
    sourceSpecifics: {
        specifics: {}
    }
};

const PropertyDefinition = {
    name: '',
    label: '',
    description: '',
    group: 'general',
    displayOrder: 100,
    type: 'STRING',
    list: false,
    nullable: false,
    computed: false,
    predefined: false,
    readOnlyDefinition: false,
    readOnlyValue: false,
    defaultValue: '',
    enumerationValues: [],
    enumerationValuesLabels: {},
    // REMOVE FLAGS!
    flags: {
        indexOnProvision: false,
        personalization: false,
        queryable: false,
        bigString: false
    },
    sourceSpecifics: {
        specifics: {
            indexOnProvision: false,
            personalization: false,
            queryable: false,
            bigString: false
        }
    }
};

const LegacyGpUiUserRecord = {
    id: null,
    userLogin: '',
    key: '',
    map: {},
    list: [],
    stringList: [],
    timestamp: null
};

const LegacyGpUiUserRecordDTO = {
    key: '',
    map: {},
    list: [],
    stringList: []
};

const DomainModel = {};

DomainModel.getEmailCampaign = function () {
    return cloneDeep(EmailCampaign);
};

DomainModel.getSmsCampaign = function () {
    return cloneDeep(SmsCampaign);
};

DomainModel.getEmailTemplate = function () {
    return cloneDeep(EmailTemplate);
};

DomainModel.getPersonLifeEvent = function () {
    return cloneDeep(PersonLifeEvent);
};

DomainModel.getPropertyGroup = function () {
    return cloneDeep(PropertyGroup);
};

DomainModel.getPropertyDefinition = function () {
    return cloneDeep(PropertyDefinition);
};

DomainModel.getLegacyGpUiUserRecord = function () {
    return cloneDeep(LegacyGpUiUserRecord);
};

DomainModel.getLegacyGpUiUserRecordDTO = function () {
    return cloneDeep(LegacyGpUiUserRecordDTO);
};

export default DomainModel;
