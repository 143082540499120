/****************************************************************************************************
 * UI sub-module of people store module.
 * Responsible for managing UI states
 * (at least some of them especially those which require store data modification and synchronization across sub-modules).
 ****************************************************************************************************/

// State.
const state = {
    uiSettingsOpened: false,
    // Be careful if you add more than one off canvas sidebars.
    // You must control which can be open at the same time.
    // The safest solution is to close all sidebars except the last opened.
    uiSidebarRightOpened: false
};

// Getters.
const getters = {
    uiSettingsOpened (state) {
        return state.uiSettingsOpened;
    },
    uiSidebarRightOpened (state) {
        return state.uiSidebarRightOpened;
    }
};

// Actions.
const actions = {};

// Mutations.
const mutations = {
    uiSettingsOpenedToggle (state) {
        state.uiSettingsOpened = !state.uiSettingsOpened;
    },
    uiSidebarRightOpenedSet (state, data) {
        if (typeof data !== 'boolean') return;
        state.uiSidebarRightOpened = data;
    },
    uiSidebarRightOpenedToggle (state) {
        state.uiSidebarRightOpened = !state.uiSidebarRightOpened;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
