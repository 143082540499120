/****************************************************************************************************
 * Property specific SchemaDefinition reusable store sub-module.
 * Used by modules which manage another domain and SchemaDefinition it's part of business logic.
 *
 * @author Dimitris Gkoulis
 * @createdAt 9 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import { ModelDefinitionService } from '@/common/services/api.service';

export default function (id = null) {
    if (typeof id !== 'string') throw new Error('id is missing!');
    if (id.trim() === '') throw new Error('id is missing!');

    return {
        namespaced: true,
        state: {
            schemaDefinition: null,
            schemaDefinitionError: null,
            schemaDefinitionLoading: false
        },
        getters: {
            // @help add the rest if necessary.
        },
        actions: {
            getSchemaDefinition ({ commit }) {
                commit('setSchemaDefinitionLoading', true);

                // Get SchemaDefinition.
                return ModelDefinitionService.getModelDefinition(id)
                    .then(({ data }) => {
                        // Set new data and reset error.
                        commit('setSchemaDefinition', data.schemaDefinition);
                        commit('setSchemaDefinitionError', null);
                        return Promise.resolve(data.schemaDefinition);
                    })
                    .catch((error) => {
                        commit('setSchemaDefinition', null);
                        commit('setSchemaDefinitionError', error);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        commit('setSchemaDefinitionLoading', false);
                    });
            },
            resetSchemaDefinitionSpecificSubModule ({ commit }) {
                commit('setSchemaDefinition', null);
                commit('setSchemaDefinitionError', null);
                commit('setSchemaDefinitionLoading', false);
            }
        },
        mutations: {
            setSchemaDefinition (state, data) {
                Vue.set(state, 'schemaDefinition', data);
            },
            setSchemaDefinitionError (state, data) {
                Vue.set(state, 'schemaDefinitionError', data);
            },
            setSchemaDefinitionLoading (state, data) {
                Vue.set(state, 'schemaDefinitionLoading', data);
            }
        }
    };
};
