/****************************************************************************************************
 * Operations store sub-module for SchemaDefinitionEdit
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 30 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import { ModelDefinitionService } from '@/common/services/api.service';

const state = {
    propertyGroupDeleting: false,
    propertyGroupDeleteError: null
};

const getters = {};

const actions = {
    deletePropertyGroupAndSynchronizeIndex ({ state, commit, dispatch }, pgName) {
        commit('setPropertyGroupDeleting', true);
        commit('setPropertyGroupDeleteError', null);

        // Checks.
        if (typeof state.schemaDefinitionName !== 'string') {
            commit('setPropertyGroupDeleting', false);
            return Promise.reject(new Error('schemaDefinitionName is missing!'));
        }
        if (typeof pgName !== 'string') {
            commit('setPropertyGroupDeleting', false);
            return Promise.reject(new Error('pgName is missing!'));
        }
        // @future Check if pgName (PropertyGroup) exists.

        const domain = state.schemaDefinitionName;
        // noinspection UnnecessaryLocalVariableJS
        const name = pgName;

        return ModelDefinitionService.deletePropertyGroup(domain, name)
            .then(({ data }) => {
                commit('removePropertyGroupFromIndexComputedStates', pgName);
                dispatch('application/signalToInvalidateSchemaDefinitionData', null, { root: true });
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setPropertyGroupDeleteError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setPropertyGroupDeleting', false);
            });
    },

    resetOperationsSubModule ({ commit }) {
        commit('setPropertyGroupDeleting', false);
        commit('setPropertyGroupDeleteError', null);
    }
};

const mutations = {
    setPropertyGroupDeleting (state, data) {
        Vue.set(state, 'propertyGroupDeleting', data);
    },
    setPropertyGroupDeleteError (state, data) {
        Vue.set(state, 'propertyGroupDeleteError', data);
    },

    removePropertyGroupFromIndexComputedStates (state, name) {
        if (typeof name !== 'string') return;
        Vue.delete(state.propertyGroupsNames, name);
        Vue.delete(state.propertyGroupsByName, name);

        let indexToDelete = -1;
        for (let i = 0; i < state.propertyGroupsDnD.length; i++) {
            if (state.propertyGroupsDnD[i].name === name) {
                indexToDelete = i;
                break;
            }
        }
        if (indexToDelete >= 0) {
            Vue.delete(state.propertyGroupsDnD, indexToDelete);
        }

        Vue.delete(state.propertyDefinitionsPerGroupDnD, name);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
