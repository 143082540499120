import Vue from 'vue';

import textEllipsis from '../filters/text-ellipsis.filter';
import dateLiteral from '../filters/date-literal.filter';
import translate from '../filters/translate.filter';

const AppFilters = {
    init () {
        // Register Filters
        Vue.filter('ellipsis', textEllipsis);
        Vue.filter('dateLiteral', dateLiteral);
        Vue.filter('translate', translate);
    }
};

export default AppFilters;
