/****************************************************************************************************
 * An application sub-module which handles internal communication between various store modules.
 *
 * Provides an interface that does not change.
 * Diladi an ena dispatch kalei dispatches apo polla store modules, an kati allaxei prepei na psaxnoume
 * kai na kanoume refactor. Edo kaloume ena dispatch pou kalei alla. Opote einai ena eniaio simeio
 * gia esoteriki epikoinonia.
 *
 * - All actions should start with 'signalTo'.
 * - All actions should handle possible errors from actions the call.
 * - Prefer standardized or partially standardized actions and mutations.
 *   E.g. an action that resets modules and has the same name across all store modules.
 * - No action in this sub-module should ever fail.
 *
 * @author Dimitris Gkoulis
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

const state = {};

const getters = {};

const actions = {
    signalToInvalidateSchemaDefinitionData ({ commit }) {
        // PersonEdit store module retrieves and processes SchemaDefinition on each change.
        // Person store module retrieves and processes SchemaDefinition on each change.
        commit('people/setBgcDetailsInitialized', false, { root: true }); // This will trigger
    }
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
