/****************************************************************************************************
 * Custom PropertyDefinition data.
 *
 * Override the existing PropertyDefinition data
 * or enrich PropertyDefinition data.
 *
 * @author Dimitris Gkoulis
 * @createdAt 1 August 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const CustomValidations = {
    'priority': {
        minValue: 0,
        maxValue: 5
    }
};

const PdFormDetailsProvider = {};

PdFormDetailsProvider.customValidations = function (name, defaultValue = {}) {
    if (typeof name !== 'string') return defaultValue;
    if (!CustomValidations.hasOwnProperty(name)) return defaultValue;
    return cloneDeep(CustomValidations[name]);
};

export default PdFormDetailsProvider;
