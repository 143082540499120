/****************************************************************************************************
 * A helper store module to support value auto-corrections.
 * Currently only PdInstant uses this functionality.
 *
 * @createdAt 11 July 2020
 ****************************************************************************************************/

import Vue from 'vue';

const state = {
    // A map that holds the properties that was auto-corrected.
    // A property auto-correction should take place only once per Person load.
    autoCorrectionStatusV0: {}
};

const getters = {
    hasBeenAutoCorrectedV0: (state) => (name) => {
        // Return true to disable auto-correction.
        // False means that it should be auto-corrected.
        if (typeof name !== 'string') return true;
        if (!state.autoCorrectionStatusV0.hasOwnProperty(name)) return false; // It should be auto-corrected.
        return state.autoCorrectionStatusV0[name];
    }
};

const actions = {
    resetAutoCorrectionSubModule ({ commit }) {
        commit('resetAutoCorrectionStatusV0');
    }
};

const mutations = {
    resetAutoCorrectionStatusV0 (state) {
        Vue.set(state, 'autoCorrectionStatusV0', {});
    },
    setAutoCorrectionStatusToTrueV0ForProperty (state, name) {
        if (typeof name !== 'string') return;
        Vue.set(state, 'autoCorrectionStatusV0', true);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
