<template>
    <span v-if="displayTag" v-bind:class="cssClass">{{ $t(status) }}</span>
</template>

<script>
export default {
    name: 'PersonPoliticsSupportStatusTag',
    props: {
        status: {
            type: String,
            default: 'UNKNOWN',
            required: false
        },
        hideNoStatus: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    computed: {
        displayTag () {
            return this.status === 'SUPPORTER' || this.status === 'PROSPECT';
        },
        cssClass () {
            let value = { 'gk-tag gk-tag-gray': true };
            if (this.status === 'SUPPORTER') value = { 'gk-tag gk-tag-purple': true };
            if (this.status === 'PROSPECT') value = { 'gk-tag gk-tag-purple-lighter': true };
            return value;
        }
    }
};
</script>
