const detailsHelper = {};

/**
 * Custom settings for specific PropertyDefinition instances.
 * Currently are only declared in here (front-end).
 */
const customDetailsSettings = {
    'labels': {
        displayAs: 'personTaxonomyLabels'
    }
};

/**
 * Given a PropertyDefinition, returns default settings for presentation components.
 */
detailsHelper.getDetailDefaultSettings = function (propertyDefinition) {
    // Settings.
    let displayAs = 'default';

    // PropertyDefinition characteristics.
    const type = propertyDefinition.type;
    const list = propertyDefinition.list;

    if (type === 'INSTANT') displayAs = 'dateLiteral';
    else if (type === 'STRING' && list === true) displayAs = 'listLiteral';
    else if (type === 'BOOLEAN') displayAs = 'boolToYesNo';

    return {
        displayAs: displayAs
    };
};

/**
 * Given a PropertyDefinition, checks if has custom settings and returns them
 * or builds and returns the defaults based on its characteristics.
 */
detailsHelper.getDetailSettings = function (propertyDefinition) {
    if (propertyDefinition === null) return null;
    if (customDetailsSettings.hasOwnProperty(propertyDefinition.name)) {
        return customDetailsSettings[propertyDefinition.name];
    }
    return detailsHelper.getDetailDefaultSettings(propertyDefinition);
};

export default detailsHelper;
