import get from 'lodash/get';
import store from '@/store';
import i18n from '@/common/plugins/i18n';
import ApplicationI18n from '@/common/init/ApplicationI18n.init';
import { AccountService, TenantAccountUiService } from '@/common/services/api.service';
import {
    CLOUTLAYER_WEBAPP_BASE_URL,
    CLOUTLAYER_WEBAPP_CORE_LOGIN_URL
} from '@/common/config/config';
import UserAgentUtils from '@/common/utils/user-agent.utils';

const Application = {

    log (message) {
        if (process.env.NODE_ENV === 'development') {
            console.warn(message);
        }
    },

    /**
     * Init.
     *
     * Initialization process:
     * - Check if already initialized. If true exit. If not continue.
     * - Get and check tenantId from URL params.
     * - Get user instances (and check if is authenticated).
     * - Check async if tenantAccount with id (aka Alias) exists.
     * - Check if user has access to tenantAccount locally.
     * - Check if user has access to tenantAccount remotely.
     * - Get tenantAccount instance and prepare the env (some flags, store and local storage).
     * @see the code for more information!
     *
     * @param tenantId
     * @returns {Promise<{valid: boolean, state: string}>}
     */
    async initialize (tenantId) {
        // Check if application is initialized.
        const initialized = store.getters['application/uiInitialized'];

        // If initialized, not need to initialize again. Return success.
        // Success does not imply access to application.
        // It means that the initialization is completed.
        // App.vue, router or any other core component will take care of the flow.
        if (initialized) {
            // Return success.
            return Promise.resolve({
                valid: true,
                state: 'INITIALIZED'
            });
        }

        // ///////////////////////////////////////////////
        // Set (or reset) application store.
        // @future : Do this in store?
        // ///////////////////////////////////////////////

        // Context sub-module.
        store.commit('application/contextTenantIdSet', tenantId); // Set tenantId from URL params.

        // User sub-module.
        store.commit('application/userFetchingSet', false);
        store.commit('application/userSet', null);
        store.commit('application/userErrorGetSet', null);

        // Access sub-module.
        store.commit('application/accessHasTenantUserAccessLevelSet', false);
        store.commit('application/accessHasTenantAdminAccessLevelSet', false);

        // TenantAccount sub-module.
        store.commit('application/tenantAccountFetchingSet', false);
        store.commit('application/tenantAccountSet', null);
        store.commit('application/tenantAccountErrorGetSet', null);

        // UI sub-module.
        store.commit('application/uiInitializingSet', true);
        store.commit('application/uiInitializedSet', false);
        // store.commit('application/uiReadySet', false);

        // Notice: The 'uiInitializing' will be set only is this process succeed.
        // We do this so that the application does not appear until the user is redirected.

        // Notice: The 'uiInitialized' only serves this process.
        // It is a flag that tell Application.initialize if must run or not.

        // ///////////////////////////////////////////////
        // Start process.
        // Validations, async-operations and access checks.
        // ///////////////////////////////////////////////

        // Check tenant id.
        if (!tenantId || typeof tenantId !== 'string' || tenantId.trim() === '') {
            // App initialized. But it is not accessible to user.
            // Reason: tenantId from URL params is not valid.
            store.commit('application/uiInitializedSet', true);

            Application.log('tenantId is not valid');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'INVALID_TENANT_ID'
            });
        }

        // Fetch user.
        // This also checks if user is authenticated.
        // If no user is authenticated the request fails.
        const userResult = await store.dispatch('application/userFetch').then((user) => {
            return user;
        }).catch((reason) => {
            return null;
        });

        // Check if user is null.
        if (!userResult) {
            // App initialized. But it is not accessible to user.
            // Reason: user is null (that means that user is not authenticated).
            store.commit('application/uiInitializedSet', true);

            Application.log('User account is null');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'USER_NULL'
            });
        }

        // Build access level list.
        // Notice : This is a quick access check.
        // It checks if user's authorities imply access to tenantId.
        // IMPORTANT: This is a helper to avoid multiple requests to backend.
        // Later, we have to ask the backend is user has access to tenant.
        await store.dispatch('application/accessBuild');

        // Check if tenantAccount exists (returns a simple true or false).
        const tenantAccountExistsRemote = await TenantAccountUiService.checkTenantAccountExistence(tenantId).then(({ data }) => {
            return data;
        }).catch((reason) => {
            return false;
        });

        // Check result.
        if (!tenantAccountExistsRemote) {
            // App initialized. But it is not accessible to user.
            // Reason: tenantAccount does not exist (checked async-remote).
            store.commit('application/uiInitializedSet', true);

            Application.log('TenantAccount does not exist (async check)');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'TENANT_ACCOUNT_NOT_EXISTS'
            });
        }

        // Change the app language based on user langKey.
        // @future : Validate langKey.
        i18n.locale = userResult.langKey;

        // Initialize i18n messages (not the plugin that has already been initialized).
        const i18nInitResult = await ApplicationI18n.init(userResult.langKey).then(result => result).catch(reason => null);

        // Check result.
        // @future : By doing this we will force to app to redirect in core web-app.
        // Find another solution or show a warning message (a beautiful designed SVG).
        if (i18nInitResult === null) {
            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'I81N_MESSAGES_INIT_FAILED'
            });
        }

        // Get user access-to-tenant flag (userAccessLevel).
        const hasAccess = store.getters['application/accessHasTenantUserAccessLevel'];

        // Check access-to-tenant flag. (userAccessLevel)
        if (!hasAccess) {
            // App initialized. But it is not accessible to user.
            // Reason: User authorities do not imply access to tenant.
            store.commit('application/uiInitializedSet', true);

            Application.log('Access to tenant denied (Authorities check)');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'ACCESS_TO_TENANT_ID_DENIED'
            });
        }

        // Check if user has access to tenant.
        // Ask server.
        const hasAccessRemote = await TenantAccountUiService.checkTenantAccountUserAccess(tenantId).then(({ data }) => {
            return data;
        }).catch((reason) => {
            return false;
        });

        // Check result.
        if (!hasAccessRemote) {
            // App initialized. But it is not accessible to user.
            // Reason: User does not have access to tenant.
            store.commit('application/uiInitializedSet', true);

            Application.log('Access to tenant denied (Remote check)');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'ACCESS_TO_TENANT_ID_DENIED'
            });
        }

        // Fetch tenantAccount.
        const tenantAccountResult = await store.dispatch('application/tenantAccountFetch').then((tenantAccount) => {
            return tenantAccount;
        }).catch((reason) => {
            return null;
        });

        // Check tenantAccount
        if (!tenantAccountResult) {
            // App initialized. But it is not accessible to user.
            // Reason: Failed to fetch tenantAccount.
            // We expect that this condition is never met
            // because we have checked before if tenantAccount exists and if user has access to it.
            store.commit('application/uiInitializedSet', true);

            Application.log('Tenant Account is null');

            // Return fail.
            return Promise.resolve({
                valid: false,
                state: 'TENANT_ACCOUNT_NULL'
            });
        }

        // ///////////////////////////////////////////////
        // Services and user information
        // Quotas, limits, limitations, unit balance, etc
        // ///////////////////////////////////////////////

        // Get Quotas for all registered entities and unit balance for all registered unit types.
        await store.dispatch('application/quotasGetAllEntityQlu').then(() => void 0).catch(() => void 0);
        await store.dispatch('application/unitBalanceGetAllUnitBalances').then(() => void 0).catch(() => void 0);

        // ///////////////////////////////////////////////
        // Initialize, set, etc app components.
        // ///////////////////////////////////////////////

        // Global Static Storage for this window.
        // @future : Add more? (tenantAccountId, userLogin).
        window.cs3w.set('defaultLanguageKey', get(tenantAccountResult, 'defaultLanguageKey', 'EN'));
        window.cs3w.set('gdprAddOnEnabled', get(tenantAccountResult, 'details.addOns.gdprAddOn', false));
        window.cs3w.set('politicsAddOnEnabled', get(tenantAccountResult, 'details.addOns.politicsAddOn', false));
        window.cs3w.set('i18nAddOnEnabled', get(tenantAccountResult, 'details.addOns.i18nAddOn', false));

        // ///////////////////////////////////////////////
        // Finally, set application modules flags and check application readiness.
        // ///////////////////////////////////////////////

        store.commit('application/uiInitializingSet', false);
        store.commit('application/uiInitializedSet', true);

        await store.dispatch('application/uiCheckAndSetReadiness');

        // Compute and set computed context properties.
        store.commit('application/contextDeviceIsMobileSet', UserAgentUtils.isPossiblyMobileDeviceV1());
        store.commit('application/contextDeviceHasSmallScreenSet', get(window, 'screen.width', 0) < 768);

        // Return success.
        return Promise.resolve({
            valid: true,
            state: 'OK'
        });
    },

    async beforeRouteCheck (theRoute) {
        // Check route.
        if (!theRoute) {
            return Promise.resolve({
                valid: false,
                state: 'ROUTE_NULL'
            });
        }

        // Get tenantId from params.
        let tenantId = theRoute.params.tenantId;

        // Validate tenantId.
        if (!tenantId || typeof tenantId !== 'string' || tenantId.trim() === '') {
            return Promise.resolve({
                valid: false,
                state: 'INVALID_TENANT_ID'
            });
        }

        // Authenticate.
        // @future : Reduce requests by adding a counter?
        const loginResult = await AccountService.whoAmI().then(({ data }) => {
            if (typeof data === 'string') {
                return data === 'anonymous' ? null : data;
            } else {
                return null;
            }
        }).catch((reason) => {
            // @help : IMPORTANT : This redirect must never be reached.
            // We expect that the zeus's endpoint always replies.
            return null;
        });

        // Check 'isAuthenticated' result.
        if (!loginResult) {
            return Promise.resolve({
                valid: false,
                state: 'NOT_AUTHENTICATED'
            });
        }

        // Check if login (username - from getIsAuthenticated) is equal to login (username) from account in store.
        if (loginResult !== store.getters['application/userLogin']) {
            return Promise.resolve({
                valid: false,
                state: 'LOGIN_MISMATCH'
            });
        }

        // Return success.
        return Promise.resolve({
            valid: true,
            state: 'OK'
        });
    },

    getRedirectBasedOnState (route, result) {
        // Prepare the base redirect url.
        let redirect = CLOUTLAYER_WEBAPP_CORE_LOGIN_URL;

        // If requested URL exists (is a valid route), add it as 'redirect' after login.
        // IMPORTANT: The first param of the url is the tenantId.
        // So, this mechanism will add the tenantId even if tenant with ID does not exist.
        // In this scenario:
        // base redirect user to core with invalid tenantId.
        // core redirect user to base with invalid tenantId.
        // base redirect user to core with NO tenantId (it will find that the tenantId does not exist, because user is authenticated)
        // core redirect user to core profile view. From here, user can select his account.
        // @future : Is there any solution to avoid the multiple redirects? Do we have too?
        // I 've tested it and it's okay!
        if (route.matched.length > 0) {
            redirect = redirect + '?redirect=' + CLOUTLAYER_WEBAPP_BASE_URL + route.path;
        }

        return redirect;
    },

    /**
     * Checks app's data integrity. NOT YET IMPLEMENTED...
     */
    checkIntegrity () {
        // Some of them must run in 'init' and some others to 'beforeRouteCheck'
        // - Check for nulls, empty strings or missing information.
        // - Check if tenantId is equal to currentTenantId in store.
        // - Check if user has access to tenant (beforeRouteCheck).
        // ---- Idea : implement only for 'beforeRouteCheck' an async method
        //      that checks authentication and authorization and access to tenant.

        // UX
        // - Check if user visits the app for the very first time.

        // IMPORTANT : Check if tenant account has financial outbursts. (where to check this and what to do? PopUp?)

        // QUESTION : Can this method make changes in order to ensure integrity.
        // I prefer not because these changes are based on assumptions and we don't like assumptions.
    }
};

export default Application;
