/****************************************************************************************************
 * Application store sub-module responsible for main navigation behavior.
 *
 * @author Dimitris Gkoulis
 * @createdAt 21 July 2020
 * @lastModifiedAt 5 November 2020
 ****************************************************************************************************/

import Vue from 'vue';

const state = {
    navigationContainerClassName: 'container'
};

const getters = {
    navigationContainerClassName (state) {
        return state.navigationContainerClassName;
    }
};

const actions = {};

const mutations = {
    navigationContainerSwitchToDefault (state) {
        Vue.set(state, 'navigationContainerClassName', 'container');
    },
    navigationContainerSwitchToFluid (state) {
        Vue.set(state, 'navigationContainerClassName', 'container-fluid');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
