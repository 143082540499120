/****************************************************************************************************
 * Translations provider for Cloutlayer's domains.
 * The difference between this and vue-i18n is that this component provides translations for dynamic information.
 * E.g. labels, properties, enum values, etc (data provided by back-end).
 *
 * This component provides an independent way to translate dynamic data.
 * Many components have data with declared label but it will be used as fallback value.
 * Pros: Unified and convenient way to translate dynamic data.
 * Cons: Translations declared in here may differ from English provided by back-end
 *
 * There is no need to check if it's predefined PropertyDefinition.
 * In the worst case scenario it will return the fallback value.
 * BUT in the @future it will retrieve custom - user created - translations.
 *
 * @future implementations:
 * - On start-up fetch translations for custom (user created)
 *   PropertyDefinition and PropertyGroup and merge them (labels, enums, etc.).
 * - POWERFUL fallback mechanism
 *
 * SIMANTIKO 29 July 2020: Genika auto to module an kai exairetika xrisimo einai ligo adomito.
 * Opote xreiazomai mpaino kai allazo metafraseis kai mixanismous. Auto einai kalo kai kako.
 * Kalo giati kano ti douleia mou, kako giati megalonei xoris domi.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import get from 'lodash/get';

const Translations = {
    en: {},
    el: {}
};

const DEFAULT_FALLBACK_VALUE = 'Unknown';

let ACTIVE_LOCALE = 'en';

const DomainTranslations = {};

DomainTranslations.initialize = async function (langKey) {
    if (typeof langKey !== 'string') langKey = 'en';
    ACTIVE_LOCALE = langKey;

    let messages;
    if (langKey === 'el') {
        messages = await import(/* webpackChunkName: "domain-translations-lang-el" */ '@/modules/DomainTranslations/locale/el')
            .then(messages => messages.default).catch(ignoredError => {});
    } else {
        messages = await import(/* webpackChunkName: "domain-translations-lang-en" */ '@/modules/DomainTranslations/locale/en')
            .then(messages => messages.default).catch(ignoredError => {});
    }

    Translations[langKey] = {
        ...messages
    };
};

DomainTranslations.t = function (key, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof key !== 'string') return fallback;
    return get(Translations, ACTIVE_LOCALE + '.' + key, fallback);
};

/**
 * Ultra safe and ready-to-use SchemaDefinition view title.
 *
 * @param domain the SchemaDefinition domain
 * @param label the SchemaDefinition label (used as callback)
 *
 * @createdAt 25 July 2020
 */
DomainTranslations.dfTitleRtU = function (domain, label) {
    let defaultValue = DomainTranslations.t('dfTitle', 'Properties');
    if (typeof label === 'string' && label.trim() !== '') {
        // e.g. Properties / Post (where 'Post' is the SchemaDefinition label).
        defaultValue = defaultValue + ' ' + DomainTranslations.t('dfTitleSeparator', '/') + ' ' + label;
    }
    if (typeof domain !== 'string') return defaultValue;
    return DomainTranslations.t('dfTitles.' + domain, defaultValue);
};

/**
 * Human readable PropertyType.
 *
 * @createdAt 25 July 2020
 */
DomainTranslations.propertyTypeLabel = function (list, type, bigString) {
    let bigStringProvided = false;
    if (typeof list !== 'boolean') list = false;
    if (typeof type !== 'string') type = 'UNKNOWN';
    if (typeof bigString === 'boolean') bigStringProvided = true;

    let key = 'pdTypeLabels.' + list.toString();
    if (type === 'STRING') key = key + '_' + type + (bigStringProvided ? ('_' + bigString.toString()) : '');
    else key = key + '_' + type;

    return DomainTranslations.t(key);
};

/**
 * Human readable PropertyType by key.
 *
 * @createdAt 29 July 2020
 */
DomainTranslations.propertyTypeLabelByKey = function (keyParam, fallback = DEFAULT_FALLBACK_VALUE) {
    return DomainTranslations.t('pdTypeLabels.' + (typeof keyParam === 'string' ? keyParam : ''), fallback);
};

/**
 * Translated label for the "name" PropertyGroup of the "domain" SchemaDefinition.
 *
 * @createdAt 25 July 2020
 */
DomainTranslations.dfPg = function (domain, name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof domain !== 'string') return fallback;
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t(domain + '.df.pg.' + name, fallback);
};

/**
 * Translated label for the "name" PropertyDefinition of the "domain" SchemaDefinition.
 *
 * @createdAt 25 July 2020
 */
DomainTranslations.dfPd = function (domain, name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof domain !== 'string') return fallback;
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t(domain + '.df.pd.' + name, fallback);
};

/**
 * Translated label for the "name" "enumeration" of "domain".
 *
 * @param domain the "domain" (either the SchemaDefinition domain or the name of a registered entity)
 * @param name the value of the enumeration field (e.g. MALE, FEMALE, OTHER, etc)
 * @param enumeration the enumeration property name (e.g. sex, country, maritalStatus, etc)
 * @param fallback the fallback value (usually the enumeration value itself)
 *
 * @createdAt 27 July 2020
 */
DomainTranslations.enumeration = function (domain, name, enumeration = null, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof domain !== 'string') return fallback;
    if (typeof name !== 'string') return fallback;
    if (typeof enumeration !== 'string') return fallback;
    return DomainTranslations.t(domain + '.enum.' + enumeration + '.' + name, fallback);
};

// /////////////////////////////////////////////////////////////////////////////////////////////////
// Domain Specifics for convenient use /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////////////////////////
// @future DEPRECATED - WE MUST USE EITHER GENERIC METHODS OR MOVE THIS INTO A HELPER FILE. - FIX n REFACTOR
// /////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Translated label for the "name" PropertyGroup of "person" SchemaDefinition.
 */
DomainTranslations.personDfPg = function (name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t('person.df.pg.' + name, fallback);
};

/**
 * Translated label for the "name" PropertyDefinition of "person" SchemaDefinition.
 */
DomainTranslations.personDfPd = function (name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t('person.df.pd.' + name, fallback);
};

/**
 * Translated label for the "name" Group of "person" Entity.
 * Used for custom groups or hardcoded groups that do not exist in SchemaDefinition (as PropertyGroup).
 */
DomainTranslations.personEntityGroup = function (name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t('person.entity.group.' + name, fallback);
};

/**
 * Translated label for the "name" Property of "person" Entity.
 * Used for custom fields or hardcoded fields that do not exist in SchemaDefinition (as PropertyDefinition).
 */
DomainTranslations.personEntityProperty = function (name, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof name !== 'string') return fallback;
    return DomainTranslations.t('person.entity.property.' + name, fallback);
};

/**
 * Translated label for the "name" "enumeration" of "person" domain.
 *
 * @future implementation [VERY HELPFUL!]
 * If "enumeration" is null it will search across all "person" enums and it will return the first match.
 */
DomainTranslations.personEnum = function (name, enumeration = null, fallback = DEFAULT_FALLBACK_VALUE) {
    if (typeof name !== 'string') return fallback;
    if (typeof enumeration !== 'string') return fallback;
    return DomainTranslations.t('person.enum.' + enumeration + '.' + name, fallback);
};

export default DomainTranslations;
