/****************************************************************************************************
 * BuilderGenericCriteria sub-module for people store module.
 * Extends property sub-module. To be more precise, it uses it.
 *
 * @author Dimitris Gkoulis
 * @createdAt 8 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import GcLogic from '@/modules/People/gc-builder/gc.logic';

const state = {
    bgcSynchronizing: false,
    bgcDetailsInitialized: false,
    bgc: null,
    bgcList: [],
    bgcForCascader: []
};

const getters = {
    bgcSynchronizing (state) {
        return state.bgcSynchronizing;
    },
    bgc (state) {
        return state.bgc;
    },
    bgcForCascader (state) {
        return state.bgcForCascader;
    }
};

const actions = {
    // Requires Workspace instance.
    // SchemaDefinition, bgcList and bgcForCascader are set once per application
    // (or until they are invalidated by another module) to avoid extra load.
    async synchronizeBgcSubModule ({ state, commit, dispatch }) {
        // Ensure state indicators.
        commit('setBgcSynchronizing', true);

        let bgcList;
        let bgcForCascader;

        if (state.bgcDetailsInitialized) {
            bgcList = state.bgcList;
            bgcForCascader = state.bgcForCascader;
        } else {
            const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);

            if (schemaDefinition === null) {
                // Set default - save values.
                bgcList = [];
                bgcForCascader = [];
            } else {
                bgcList = GcLogic.dfToBgcList(schemaDefinition, true);
                bgcForCascader = GcLogic.bgcToCascaderOptions(bgcList);
            }

            commit('setBgcList', bgcList);
            commit('setBgcForCascader', bgcForCascader);
            commit('setBgcDetailsInitialized', true);
        }

        if (state.workspaceWorking !== null) {
            commit('setBgc', GcLogic.gcToBgc(state.workspaceWorking.searchDetails.genericCriteria, bgcList));
        } else {
            commit('setBgc', null);
        }

        commit('setBgcSynchronizing', false);
    }
};

const mutations = {
    setBgcSynchronizing (state, data) {
        Vue.set(state, 'bgcSynchronizing', data);
    },
    setBgcDetailsInitialized (state, data) {
        Vue.set(state, 'bgcDetailsInitialized', data);
    },
    setBgc (state, data) {
        Vue.set(state, 'bgc', data);
    },
    setBgcList (state, data) {
        if (!Array.isArray(data)) return;
        Vue.set(state, 'bgcList', data);
    },
    setBgcForCascader (state, data) {
        if (!Array.isArray(data)) return;
        Vue.set(state, 'bgcForCascader', data);
    },

    setBgcAndGc (state, data) {
        Vue.set(state, 'bgc', data);
        Vue.set(state.workspaceWorking.searchDetails, 'genericCriteria', GcLogic.bgcToGc(data));
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
