/****************************************************************************************************
 * Operations sub-module for EmailTemplate store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { EmailTemplateService } from '@/common/services/api.service';

const state = {
    emailTemplateBase: null,
    emailTemplate: null,
    // State Indicators //////////
    emailTemplateGetting: false,
    emailTemplateUpdating: false,
    emailTemplateDeleting: false,
    // Status //////////
    emailTemplateNotFound: false,
    // Errors //////////
    emailTemplateGetError: null,
    emailTemplateUpdateError: null,
    emailTemplateDeleteError: null
};

const getters = {
    emailTemplate (state) {
        return state.emailTemplate;
    },
    // State Indicators //////////
    emailTemplateGetting (state) {
        return state.emailTemplateGetting;
    },
    emailTemplateUpdating (state) {
        return state.emailTemplateUpdating;
    },
    emailTemplateDeleting (state) {
        return state.emailTemplateDeleting;
    },
    // Status //////////
    emailTemplateNotFound (state) {
        return state.emailTemplateNotFound;
    },
    // Errors //////////
    emailTemplateGetError (state) {
        return state.emailTemplateGetError;
    },
    emailTemplateUpdateError (state) {
        return state.emailTemplateUpdateError;
    },
    emailTemplateDeleteError (state) {
        return state.emailTemplateDeleteError;
    }
};

const actions = {
    /**
     * Get the "id" EmailTemplate.
     */
    getEmailTemplate ({ commit, state }, { id }) {
        commit('setEmailTemplateGetting', true);

        // Reset state indicators and statuses.
        commit('setEmailTemplateNotFound', false);

        return EmailTemplateService.getEmailTemplate(id)
            .then(({ data }) => {
                commit('setEmailTemplateBase', cloneDeep(data)); // Clone is required!
                commit('setEmailTemplate', data);
                commit('setEmailTemplateGetError', null);
                commit('setEmailTemplateNotFound', false);

                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setEmailTemplateBase', null);
                commit('setEmailTemplate', null);
                commit('setEmailTemplateGetError', reason);
                commit('setEmailTemplateNotFound', true);

                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setEmailTemplateGetting', false);
            });
    },

    /**
     * Update the current EmailTemplate.
     *
     * This operation will set the updated instance as base instance.
     */
    updateEmailTemplate ({ commit, state }) {
        commit('setEmailTemplateUpdating', true);

        return EmailTemplateService.updateEmailTemplate(state.emailTemplate)
            .then(({ data }) => {
                commit('setEmailTemplateBase', cloneDeep(data)); // Clone is required!
                commit('setEmailTemplate', data);
                commit('setEmailTemplateUpdateError', null);

                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setEmailTemplateUpdateError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setEmailTemplateUpdating', false);
            });
    },

    /**
     * Delete the current EmailTemplate.
     */
    deleteEmailTemplate ({ dispatch, commit, state }) {
        commit('setEmailTemplateDeleting', true);

        commit('setEmailTemplateDeleteError', null);

        const id = state.emailTemplate.id;
        return EmailTemplateService.deleteEmailTemplate(id)
            .then(() => {
                dispatch('resetModule');
                return Promise.resolve();
            })
            .catch((reason) => {
                commit('setEmailTemplateDeleteError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                commit('setEmailTemplateDeleting', false);
            });
    },

    /**
     * Discards changes related to content (only).
     *
     * There is no state indicator for this (high-level) action.
     */
    discardEmailTemplateChanges ({ commit, state }) {
        commit('setEmailTemplateField', { field: 'editor', value: state.emailTemplateBase.editor });
        commit('setEmailTemplateField', { field: 'html', value: state.emailTemplateBase.html });
        commit('setEmailTemplateField', { field: 'builderDataSerialized', value: state.emailTemplateBase.builderDataSerialized });
    },

    resetOperationsSubModule ({ commit }) {
        commit('setEmailTemplateBase', null);
        commit('setEmailTemplate', null);
        // State Indicators
        commit('setEmailTemplateGetting', false);
        commit('setEmailTemplateUpdating', false);
        commit('setEmailTemplateDeleting', false);
        // Status
        commit('setEmailTemplateNotFound', false);
        // Errors
        commit('setEmailTemplateGetError', null);
        commit('setEmailTemplateUpdateError', null);
        commit('setEmailTemplateDeleteError', null);
    }
};

const mutations = {
    setEmailTemplateBase (state, data) {
        Vue.set(state, 'emailTemplateBase', data);
    },
    setEmailTemplate (state, data) {
        Vue.set(state, 'emailTemplate', data);
    },
    // Mutations for EmailTemplate fields //////////
    setEmailTemplateField (state, { field, value }) {
        Vue.set(state.emailTemplate, field, value);
    },
    // State Indicators //////////
    setEmailTemplateGetting (state, data) {
        Vue.set(state, 'emailTemplateGetting', data);
    },
    setEmailTemplateUpdating (state, data) {
        Vue.set(state, 'emailTemplateUpdating', data);
    },
    setEmailTemplateDeleting (state, data) {
        Vue.set(state, 'emailTemplateDeleting', data);
    },
    // Status //////////
    setEmailTemplateNotFound (state, data) {
        Vue.set(state, 'emailTemplateNotFound', data);
    },
    // Errors //////////
    setEmailTemplateGetError (state, data) {
        Vue.set(state, 'emailTemplateGetError', data);
    },
    setEmailTemplateUpdateError (state, data) {
        Vue.set(state, 'emailTemplateUpdateError', data);
    },
    setEmailTemplateDeleteError (state, data) {
        Vue.set(state, 'emailTemplateDeleteError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
