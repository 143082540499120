/****************************************************************************************************
 * GenericCriteria logic.
 *
 * Models:
 * - SchemaDefinition (aka df)
 * - PropertyGroup (aka pg)
 * - PropertyDefinition (aka pd)
 * - GenericCriteria (aka gc) (all types)
 * - BuilderGenericCriteria (aka bgc)
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import DomainTranslations from '@/modules/DomainTranslations';
import GcModel, { DEFAULT_DISPLAY_ORDER } from './gc.model';
import GcCustomsProvider from './gc-customs-provider';

/**
 * Custom 'specific' objects for some of the bgc.
 *
 * VERY IMPORTANT NOTICE: OVERRIDE ONLY INFORMATION RELATED TO THE UI.
 * E.g. do not set 'name'!
 *
 * @future We should provide a single service for all modules (check PersonEdit custom validations)
 */
const customGenericCriteriaSpecifics = {
    'priority': {
        minValue: 0,
        maxValue: 5
    }
};

/**
 * Transforms PropertyDefinition to BuilderGenericCriteria.
 * If PropertyDefinition is not valid, it returns null.
 * For types that support range filter, returns 2 instances
 * (the default and the range filter).
 *
 * IMPORTANT: THIS STAGE MUST ALWAYS BE BEFORE THE CONCATENATION WITH CUSTOM (HARDCODED) PROPERTIES!
 * WHY? Because it handles only PropertyDefinition instances.
 * The BGC model has fields that may represent different things based on each situation.
 * E.g. in this operation 'name' field represents PropertyDefinition 'name' field.
 * But custom BGC 'name' field represents the Person's entity fields that are not defined as PropertyDefinition.
 *
 * IT IS PART OF 'dfToBgcList' PROCESS!
 */
function flatMapPropertyDefinitionToBuilderGenericCriteria (propertyDefinition) {
    if (propertyDefinition === null) return null;

    let bgc;
    let bgcRange;

    // Get the required information from propertyDefinition.
    const isList = propertyDefinition.list;
    const type = propertyDefinition.type;
    const name = propertyDefinition.name;
    const field = 'properties.' + propertyDefinition.name;
    const label = propertyDefinition.label;
    const group = propertyDefinition.group;
    const displayOrder = propertyDefinition.displayOrder;

    if (isList) {
        switch (type) {
        case 'STRING':
            bgc = GcModel.getBgcCommonArrayFilter_String();
            bgcRange = null;
            break;
        case 'LONG':
        case 'DOUBLE':
        case 'BOOLEAN':
        case 'INSTANT':
        case 'ENUMERATION':
        default:
            // Theoretically unreachable.
            // Lists currently support only STRING.
            bgc = null;
            bgcRange = null;
            break;
        }
    } else {
        switch (type) {
        case 'STRING':
            bgc = GcModel.getBgcCommonFilter_String();
            bgcRange = null;
            break;
        case 'LONG':
        case 'DOUBLE': // @future For querying purposes I think it's just fine!
            bgc = GcModel.getBgcCommonFilter_Integer();
            bgcRange = GcModel.getBgcCommonRangeFilter_Integer();
            break;
        /*
        case 'DOUBLE':
            bgc = GcModel.getBgcCommonFilter_Decimal();
            bgcRange = GcModel.getBgcCommonRangeFilter_Decimal();
            break;
        */
        case 'BOOLEAN':
            bgc = GcModel.getBgcCommonFilter_Boolean();
            bgcRange = null;
            break;
        case 'INSTANT':
            bgc = GcModel.getBgcCommonFilter_DateOrDateTime('date');
            bgcRange = GcModel.getBgcCommonRangeFilter_DateOrDateTime('date');
            break;
        case 'ENUMERATION':
            bgc = GcModel.getBgcCommonFilter_StringAndSelect();
            bgc.value = propertyDefinition.defaultValue; // The default enum value. It can be null.
            bgc.specific.options = propertyDefinition.enumerationValues
                .map(function (enumValue) {
                    let enumerationValueLabel = propertyDefinition.enumerationValuesLabels[enumValue];
                    let label;
                    if (propertyDefinition.predefined === true) {
                        label = DomainTranslations.personEnum(enumValue, propertyDefinition.name, enumerationValueLabel);
                    } else {
                        label = enumerationValueLabel;
                    }
                    return {
                        label: label,
                        value: enumValue
                    };
                });
            bgcRange = null;
            break;
        default:
            // Theoretically unreachable.
            bgc = null;
            bgcRange = null;
            break;
        }
    }

    // Add commons for any type.
    if (bgc !== null) {
        bgc.field = field;
        bgc.specific.name = name;
        bgc.specific.label = label;
        bgc.specific.displayOrder = displayOrder;
        bgc.specific.group = group;
        bgc.specific.rId = GcModel.getUniqueRId();
    }

    // Add commons for any type.
    if (bgcRange !== null) {
        bgcRange.field = field;
        bgcRange.specific.name = name;
        bgcRange.specific.label = label;
        bgcRange.specific.displayOrder = displayOrder;
        bgcRange.specific.group = group;
        bgcRange.specific.rId = GcModel.getUniqueRId();
    }

    return [bgc, bgcRange]; // They can be null.
}

function bgcToGcRecursive (bgc) {
    if (bgc === null) return null;
    delete bgc.specific;
    for (const child of bgc.children) bgcToGcRecursive(child);
    return bgc;
}

const GcLogic = {};

/**
 * Transforms schemaDefinition to builderGenericCriteria (as list).
 *
 * THE RESULT OF THIS OPERATION IS THE INPUT OF ALL OTHER OPERATIONS!
 *
 *
 * @param schemaDefinition the SchemaDefinition
 * @param includeCustoms if true the custom builderGenericCriteria will be included in list
 * @return the unsorted list with the BuilderGenericCriteria instances
 */
GcLogic.dfToBgcList = function (schemaDefinition, includeCustoms = true) {
    if (schemaDefinition === null) return [];
    if (!Array.isArray(schemaDefinition.propertyGroups)) return [];
    if (!Array.isArray(schemaDefinition.propertyDefinitions)) return [];

    // Get PropertyGroup and PropertyDefinition instances.
    const dfPropertyDefinitions = cloneDeep(schemaDefinition.propertyDefinitions);
    const dfPropertyGroups = cloneDeep(schemaDefinition.propertyGroups);

    // Convert list to map.
    const propertyGroupsByName = dfPropertyGroups
        .reduce(function (accumulator, current) {
            accumulator[current.name] = current;
            return accumulator;
        }, {});

    let bgcListFromPdList = dfPropertyDefinitions
        .filter(function (propertyDefinition) {
            return propertyDefinition.sourceSpecifics.specifics.queryable === true;
        })
        .flatMap(flatMapPropertyDefinitionToBuilderGenericCriteria)
        // Cleans nulls
        .filter(function (bgc) {
            return bgc !== null;
        })
        .map(function (bgc) {
            // PropertyDefinition and PropertyGroup translations.
            // REMINDER: bgc.specific.label and propertyGroupsByName[bgc.specific.group].label declared labels
            // are used as fallback values!
            bgc.specific.label = DomainTranslations.personDfPd(bgc.specific.name, bgc.specific.label);
            bgc.specific.groupLabel = DomainTranslations.personDfPg(bgc.specific.group, propertyGroupsByName[bgc.specific.group].label);

            // Find and set the required PropertyGroup information.
            if (propertyGroupsByName.hasOwnProperty(bgc.specific.group)) {
                // bgc.specific.groupLabel = propertyGroupsByName[bgc.specific.group].label; // We 've already got this.
                bgc.specific.groupDisplayOrder = propertyGroupsByName[bgc.specific.group].displayOrder;
            } else {
                // Theoretically unreachable.
                // bgc.specific.groupLabel = 'Unknown'; // We 've already got this.
                bgc.specific.groupDisplayOrder = DEFAULT_DISPLAY_ORDER;
            }

            // Check if some of the 'specific' fields should be overridden.
            // NOTICE: null name means that it's not PropertyDefinition.
            if (bgc.specific.name !== null && customGenericCriteriaSpecifics.hasOwnProperty(bgc.specific.name)) {
                bgc.specific = Object.assign(bgc.specific, cloneDeep(customGenericCriteriaSpecifics[bgc.specific.name]));
            }

            return bgc;
        });

    // if (includeCustoms) return bgcListFromPdList.concat(GcCustomsProvider.getAll());
    if (includeCustoms) {
        bgcListFromPdList = bgcListFromPdList.concat(GcCustomsProvider.getAll().map(function (customBgc) {
            customBgc.specific.label = DomainTranslations.personEntityProperty(customBgc.specific.name, customBgc.specific.label);
            customBgc.specific.groupLabel = DomainTranslations.personEntityGroup(customBgc.specific.group, customBgc.specific.groupLabel);
            return customBgc;
        }));
    }
    return bgcListFromPdList;
};

/**
 * Transforms (persistent) genericCriteria (from Workspace) to builderGenericCriteria (as tree).
 *
 * Assumes COMMON_GROUP with children COMMON_GROUP GenericCriteria.
 * Nested COMMON_GROUP in level 2 is not yet supported (I think forever).
 *
 * This operation has some auto-correction characteristics.
 * It returns a safe value in order to ensure smooth operation of bgc-builder.
 *
 * There is a big gap on how we should handle groups after level 1
 * and how we handle valid gc which are not being supported by bgc-builder.
 * @future Check this after a month... Try to find the solution...
 *
 * @param genericCriteriaParam the GenericCriteria from Workspace SearchDetails
 * @param bgcListParam the list of bgc definitions
 */
GcLogic.gcToBgc = function (genericCriteriaParam, bgcListParam) {
    // Ugly but necessary(!)
    const genericCriteria = cloneDeep(genericCriteriaParam);
    const bgcList = cloneDeep(bgcListParam);

    const bgcByFieldAndType = bgcList.reduce(function (accumulator, current) {
        // This is necessary because some fields have more than one definitions.
        const key = current.field + '_' + current.type;
        // @future If key already exists?
        accumulator[key] = current;
        return accumulator;
    }, {});

    const bgcRoot = GcModel.getBgcCommonGroup();
    if (genericCriteria === null) return bgcRoot;
    bgcRoot.operator = genericCriteria.operator; // active operator.
    bgcRoot.specific.rId = GcModel.getUniqueRId();
    if (genericCriteria.children.length === 0) return bgcRoot;

    for (const gcGroup of genericCriteria.children) {
        if (gcGroup === null) continue;
        if (gcGroup.type !== 'COMMON_GROUP') continue;
        const bgcGroup = GcModel.getBgcCommonGroup();
        bgcGroup.operator = gcGroup.operator; // active operator.
        bgcGroup.specific.rId = GcModel.getUniqueRId();

        for (const gc of gcGroup.children) {
            if (gc === null) continue;
            let bgc = null;

            switch (gc.type) {
            case 'COMMON_FILTER':
            case 'COMMON_RANGE_FILTER':
            case 'COMMON_ARRAY_FILTER':
            case 'COMMUNICATION_SUBSCRIPTION_FILTER':
                const key = gc.field + '_' + gc.type;
                if (bgcByFieldAndType.hasOwnProperty(key)) {
                    bgc = cloneDeep(gc);
                    bgc.specific = cloneDeep(bgcByFieldAndType[key].specific);
                    bgc.specific.rId = GcModel.getUniqueRId();
                }
                // Otherwise, leave it null in order to not be included.
                break;
            case 'COMMON_GROUP':
            default:
                continue;
            }

            if (bgc !== null) bgcGroup.children.push(bgc);
        }

        // If bgcGroup has not children, do not include it in bgcRoot.
        if (bgcGroup.children.length > 0) bgcRoot.children.push(bgcGroup);
    }

    return bgcRoot;
};

/**
 * Transforms BuilderGenericCriteria to GenericCriteria (recursively).
 *
 * @param bgcParam the BuilderGenericCriteria
 */
GcLogic.bgcToGc = function (bgcParam) {
    if (bgcParam === null) return null;
    const bgc = cloneDeep(bgcParam);
    return bgcToGcRecursive(bgc);
};

/**
 * Transforms BuilderGenericCriteria list to Element UI Cascader options.
 *
 * @param bgcList the BuilderGenericCriteria list
 */
GcLogic.bgcToCascaderOptions = function (bgcList) {
    if (!Array.isArray(bgcList)) return [];

    const bgcByGroupName = bgcList.reduce(function (accumulator, current) {
        const groupName = current.specific.group;
        // Create cascader group if does not exist.
        if (accumulator[groupName] === null || accumulator[groupName] === undefined) {
            accumulator[groupName] = {
                label: current.specific.groupLabel,
                value: current.specific.group,
                displayOrder: current.specific.groupDisplayOrder,
                children: []
            };
        }
        // Add current bgc and continue.
        accumulator[groupName].children.push(current);
        return accumulator;
    }, {});

    return Object.values(bgcByGroupName)
        .map(function (group) {
            group.children = group.children
                .sort(function (a, b) {
                    return a.specific.displayOrder - b.specific.displayOrder;
                })
                .map(function (bgc) {
                    return {
                        label: bgc.specific.label,
                        value: bgc
                    };
                });
            return group;
        })
        .filter(function (group) {
            return group.children.length > 0;
        })
        .sort(function (a, b) {
            return a.displayOrder - b.displayOrder;
        });
};

export default GcLogic;
