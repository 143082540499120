/****************************************************************************************************
 * Router.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2018
 * @lastModifiedAt 10 November 2020
 ****************************************************************************************************/

import Vue from 'vue';
import Router from 'vue-router';

import ModulesRoutes from './modules-routes';
import ViewsRoutes from './views-routes';

// 18 July 2020 in order to resolve:
// @see https://github.com/vuejs/vue-router/issues/2963
const originalPush = Router.prototype.push;
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => {
        if (err && err.name !== 'NavigationDuplicated') throw err;
    });
};

Vue.use(Router);

// All routes are multi-tenancy enabled!
const routes = [
    {
        path: '/:tenantId',
        name: 'tenantRoot',
        redirect: to => {
            return to.path + '/people';
        }
    },
    ...ModulesRoutes,
    ...ViewsRoutes,
    {
        path: '/:tenantId/*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "notfound" */ '@/views/NotFoundView.vue')
    }
];

// noinspection JSUnusedGlobalSymbols
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
});
