/****************************************************************************************************
 * SchemaDefinitionEdit store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { ModelDefinitionService } from '@/common/services/api.service';
import DomainTranslations from '@/modules/DomainTranslations';
import schemaDefinitionDynamicProvider from '@/store/shared/df-dynamic.submodule';
import operations from './operations.submodule';

const schemaDefinition = schemaDefinitionDynamicProvider();

const state = {
    ...schemaDefinition.state,
    ...operations.state,

    initializing: false,
    pageTitle: null,

    // key-value pairs 'name' - 'name'
    // value is not used. But we need maps to provide quick access.
    // Used for checks and validations (view changes, etc)
    propertyGroupsNames: {},
    propertyDefinitionsNames: {},

    // for presentation (processed).
    propertyGroupsByName: {},
    propertyDefinitionsByName: {},

    // for listings and DnD (processed).
    propertyGroupsDnD: [],
    propertyDefinitionsPerGroupDnD: {}
};

const getters = {
    ...schemaDefinition.getters,
    ...operations.getters,

    pageTitle (state) {
        return state.pageTitle;
    },

    propertyGroupExists: (state) => (name) => {
        if (typeof name !== 'string') return false;
        return state.propertyGroupsNames.hasOwnProperty(name);
    },
    /*
    propertyDefinitionExists: (state) => (name) => {
        if (typeof name !== 'string') return false;
        return state.propertyDefinitionsNames.hasOwnProperty(name);
    },
    */

    propertyGroupsByName (state) {
        return state.propertyGroupsByName;
    },
    propertyDefinitionsByName (state) {
        return state.propertyDefinitionsByName;
    },

    propertyGroupsDnD (state) {
        return state.propertyGroupsDnD;
    },
    propertyDefinitionsPerGroupDnD (state) {
        return state.propertyDefinitionsPerGroupDnD;
    },

    stateIsChanging (state) {
        return state.initializing || state.propertyGroupDeleting;
    },
    displayEmptyState (state) {
        return state.initializing === false && state.schemaDefinition === null;
    },
    displayMain (state) {
        return state.initializing === false && state.schemaDefinition !== null;
    }
};

const actions = {
    ...schemaDefinition.actions,
    ...operations.actions,

    async initializeModule ({ dispatch, commit }) {
        commit('setInitializing', true);

        const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);

        // Validate schemaDefinition.
        if (schemaDefinition === null) {
            commit('setInitializing', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }

        commit('setPageTitle', DomainTranslations.dfTitleRtU(schemaDefinition.name, schemaDefinition.label));
        const domain = schemaDefinition.name; // for quick access.
        // DOMAIN is wrong! Given the opportunity rename to something else.

        // Deep clones are necessary because we process the lists and their objects.
        // All of these objects are stored in module's state and passed in here by reference.
        const dfPropertyGroups = cloneDeep(schemaDefinition.propertyGroups);
        const dfPropertyDefinitions = cloneDeep(schemaDefinition.propertyDefinitions);

        // Data for checks and validations //////////
        let reduceOnlyName = function (accumulator, current) {
            accumulator[current.name] = current.name;
            return accumulator;
        };
        const propertyGroupsNames = dfPropertyGroups.reduce(reduceOnlyName, {});
        const propertyDefinitionsNames = dfPropertyDefinitions.reduce(reduceOnlyName, {});

        // Drag-n-drop data //////////
        const propertyDefinitionsByGroup = dfPropertyDefinitions
            .map(function (propertyDefinition) {
                // noinspection JSUnresolvedVariable
                return {
                    name: propertyDefinition.name,
                    label: DomainTranslations.dfPd(domain, propertyDefinition.name, propertyDefinition.label),
                    typeLabel: DomainTranslations.propertyTypeLabel(propertyDefinition.list, propertyDefinition.type, propertyDefinition.sourceSpecifics.specifics.bigString),
                    group: propertyDefinition.group,
                    displayOrder: propertyDefinition.displayOrder
                };
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            })
            .reduce(function (accumulator, current) {
                if (accumulator[current.group] === null || accumulator[current.group] === undefined) {
                    accumulator[current.group] = [];
                }
                accumulator[current.group].push(current);
                return accumulator;
            }, {});

        const propertyDefinitionsPerGroupDnD = dfPropertyGroups
            .map(function (propertyGroup) {
                let pdList = [];
                if (propertyDefinitionsByGroup.hasOwnProperty(propertyGroup.name)) {
                    pdList = propertyDefinitionsByGroup[propertyGroup.name];
                }
                return {
                    name: propertyGroup.name,
                    pdList: pdList
                };
            })
            .reduce(function (accumulator, current) {
                accumulator[current.name] = current.pdList;
                return accumulator;
            }, {});

        const propertyGroupsDnD = dfPropertyGroups
            .map(function (propertyGroup) {
                // Count PDs for this PG.
                let pdCount = 0;
                if (propertyDefinitionsPerGroupDnD.hasOwnProperty(propertyGroup.name)) {
                    pdCount = propertyDefinitionsPerGroupDnD[propertyGroup.name].length;
                }

                // Hide 'general' PropertyGroup if has no PropertyDefinition instances.
                if (propertyGroup.name === 'general' && pdCount === 0) return null;

                return {
                    name: propertyGroup.name,
                    label: DomainTranslations.dfPg(domain, propertyGroup.name, propertyGroup.label),
                    displayOrder: propertyGroup.displayOrder,
                    pdCount: pdCount
                };
            })
            .filter(function (propertyGroup) {
                return propertyGroup !== null;
            })
            .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
            });

        // Presentation data //////////
        const propertyDefinitionsByName = dfPropertyDefinitions
            .map(function (propertyDefinition) {
                propertyDefinition.label = DomainTranslations.dfPd(domain, propertyDefinition.name, propertyDefinition.label);
                // noinspection JSUnresolvedVariable
                propertyDefinition.typeLabel = DomainTranslations
                    .propertyTypeLabel(propertyDefinition.list, propertyDefinition.type, propertyDefinition.sourceSpecifics.specifics.bigString);
                return propertyDefinition;
            })
            .reduce(function (accumulator, current) {
                accumulator[current.name] = current;
                return accumulator;
            }, {});

        const propertyGroupsByName = dfPropertyGroups
            .map(function (propertyGroup) {
                // Count PDs for this PG.
                let pdCount = 0;
                if (propertyDefinitionsPerGroupDnD.hasOwnProperty(propertyGroup.name)) {
                    pdCount = propertyDefinitionsPerGroupDnD[propertyGroup.name].length;
                }

                propertyGroup.label = DomainTranslations.dfPg(domain, propertyGroup.name, propertyGroup.label);
                propertyGroup.pdCount = pdCount;
                return propertyGroup;
            })
            .reduce(function (accumulator, current) {
                accumulator[current.name] = current;
                return accumulator;
            }, {});

        commit('setPropertyGroupsNames', propertyGroupsNames);
        commit('setPropertyDefinitionsNames', propertyDefinitionsNames);
        commit('setPropertyGroupsByName', propertyGroupsByName);
        commit('setPropertyDefinitionsByName', propertyDefinitionsByName);
        commit('setPropertyGroupsDnD', propertyGroupsDnD);
        commit('setPropertyDefinitionsPerGroupDnD', propertyDefinitionsPerGroupDnD);

        commit('setInitializing', false);

        return Promise.resolve();
    },
    async resetModule ({ dispatch, commit }) {
        dispatch('resetSchemaDefinitionDynamicSubModule');
        dispatch('resetOperationsSubModule');

        // Reset index state.
        commit('setInitializing', false);
        commit('setPageTitle', null);
        commit('setPropertyGroupsNames', {});
        commit('setPropertyDefinitionsNames', {});
        commit('setPropertyGroupsByName', {});
        commit('setPropertyDefinitionsByName', {});
        commit('setPropertyGroupsDnD', []);
        commit('setPropertyDefinitionsPerGroupDnD', {});
    },

    updateSchemaDefinitionDisplayOrders ({ state, dispatch }) {
        // @future do we need debounce?
        // @future should we disable drag-n-drop while processing?
        // @future Validations. e.g. do nothing if nothing's changed.

        // Process data and prepare DTO.
        const id = state.schemaDefinition.name;
        const dto = {
            propertyGroupDisplayOrders: {},
            propertyDefinitionDisplayOrders: {}
        };

        for (let i = 0; i < state.propertyGroupsDnD.length; i++) {
            const name = state.propertyGroupsDnD[i].name;
            dto.propertyGroupDisplayOrders[name] = i;
        }
        // names are unique across all groups.
        for (const propertyDefinitions of Object.values(state.propertyDefinitionsPerGroupDnD)) {
            for (let i = 0; i < propertyDefinitions.length; i++) {
                const name = propertyDefinitions[i].name;
                dto.propertyDefinitionDisplayOrders[name] = i;
            }
        }

        // Perform the async-operation.
        // result does not affect business logic only presentation layer.
        return ModelDefinitionService.updateDisplayOrders(id, dto)
            .then(({ data }) => {
                dispatch('application/signalToInvalidateSchemaDefinitionData', null, { root: true });
                return Promise.resolve(data);
            })
            .catch((reason) => {
                return Promise.reject(reason);
            });
    },

    changePropertyGroups ({ commit, dispatch }, { data }) {
        commit('setPropertyGroupsDnD', data);
        return dispatch('updateSchemaDefinitionDisplayOrders');
    },
    changePropertyDefinitions ({ commit, dispatch }, { group, data }) {
        commit('setPropertyDefinitionsPerGroupDnDByGroup', {
            group: group,
            data: data
        });
        return dispatch('updateSchemaDefinitionDisplayOrders');
    }
};

const mutations = {
    ...schemaDefinition.mutations,
    ...operations.mutations,

    setInitializing (state, data) {
        Vue.set(state, 'initializing', data);
    },
    setPageTitle (state, data) {
        Vue.set(state, 'pageTitle', data);
    },

    setPropertyGroupsNames (state, data) {
        Vue.set(state, 'propertyGroupsNames', data);
    },
    setPropertyDefinitionsNames (state, data) {
        Vue.set(state, 'propertyDefinitionsNames', data);
    },

    setPropertyGroupsByName (state, data) {
        Vue.set(state, 'propertyGroupsByName', data);
    },
    setPropertyDefinitionsByName (state, data) {
        Vue.set(state, 'propertyDefinitionsByName', data);
    },

    setPropertyGroupsDnD (state, data) {
        Vue.set(state, 'propertyGroupsDnD', data);
    },
    setPropertyDefinitionsPerGroupDnD (state, data) {
        Vue.set(state, 'propertyDefinitionsPerGroupDnD', data);
    },
    setPropertyDefinitionsPerGroupDnDByGroup (state, { group, data }) {
        if (typeof group !== 'string') return;
        if (!state.propertyDefinitionsPerGroupDnD.hasOwnProperty(group)) return;
        Vue.set(state.propertyDefinitionsPerGroupDnD, group, data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
