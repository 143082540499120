<template>
    <div class="clv-iframe-sync">
        <!--suppress HtmlUnknownTarget -->
        <iframe :id="iframeElementId" :src="null"></iframe>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import InlineFrameUtils from '../../utilities/iframe.utils';

const DEFAULT_DEBOUNCE_WAIT = 100;

/**
 * Returns safe value for debounce wait parameter.
 */
function getSafeDebounceWait (num) {
    if (typeof num !== 'number') {
        return DEFAULT_DEBOUNCE_WAIT;
    }
    if (num <= 10) {
        return DEFAULT_DEBOUNCE_WAIT;
    }
    return num;
}

/**
 * Returns a unique element id for the iframe element.
 */
function getUniqueElementId (prefix = 'iframe-', min = 0, max = 100000) {
    if (typeof prefix !== 'string') prefix = '';
    if (typeof min !== 'number') min = 0;
    if (typeof max !== 'number') max = 100000;
    return prefix + new Date().getTime() + (Math.floor(Math.random() * (max - min)) + min);
}

/**
 * iframe Synchronization.
 *
 * NOTICE: The use of this component should be avoided
 * when full control over inline frame (iframe) is required.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2 November 2020
 * @lastModifiedAt 5 November 2020
 */
export default {
    name: 'ClvIframeSync',
    props: {
        html: {
            type: String,
            default: '',
            required: false
        },
        debounceWait: {
            type: Number,
            default: 100,
            required: false
        },
        hiddenUntilHeightIsSet: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data () {
        return {
            iframeElementId: null
        };
    },
    beforeMount () {
        this.iframeElementId = getUniqueElementId();
    },
    mounted () {
        const lodashDebounceWait = getSafeDebounceWait(this.debounceWait);

        this.$watch('html', {
            handler: debounce(function (value) {
                InlineFrameUtils.refreshSafelyFrameHtmlAndHeightWithDelay(value, this.iframeElementId, this.hiddenUntilHeightIsSet);
            }, lodashDebounceWait),
            immediate: true
        });
    }
};
</script>

<style lang="scss">
    .clv-iframe-sync {
        position: relative;
        width: 100%;
        height: auto;

        &>iframe {
            width: 100%;
            border: none;
        }
    }
</style>
