/****************************************************************************************************
 * PropertyDefinitionEdit store module.
 *
 * THIS STORE MODULE IS NOT RESPONSIBLE FOR THE BUSINESS LOGIC.
 * IT PROVIDES THE DATA TO THE CORRESPONDING COMPONENTS.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/common/plugins/i18n';
import DomainTranslations from '@/modules/DomainTranslations';
import schemaDefinitionDynamicProvider from '@/store/shared/df-dynamic.submodule';
import DomainModel from '@/common/logic/domain-models';

import operations from './operations.submodule';

const schemaDefinition = schemaDefinitionDynamicProvider();

const state = {
    ...schemaDefinition.state,
    ...operations.state,

    initializing: false,

    propertyDefinitionName: null, // URL param
    propertyDefinition: null // The PropertyDefinition to create or edit.
};

const getters = {
    ...schemaDefinition.getters,
    ...operations.getters,

    propertyDefinitionName (state) {
        return state.propertyDefinitionName;
    },
    propertyDefinition (state) {
        return state.propertyDefinition;
    },

    /*
    domainLabel (state) {
        if (state.schemaDefinition === null) return null;
        return DomainTranslations.dfTitleRtU(state.schemaDefinition.name, state.schemaDefinition.label);
    },
    */
    pageTitle (state) {
        if (state.schemaDefinition === null) return null;
        if (state.propertyDefinition === null) return null;
        const df = DomainTranslations.dfTitleRtU(state.schemaDefinition.name, state.schemaDefinition.label);
        const pd = typeof state.propertyDefinitionName === 'string'
            ? DomainTranslations.dfPd(state.schemaDefinition.name, state.propertyDefinition.name, state.propertyDefinition.label)
            : i18n.t('New Property');
        return df + ' - ' + pd;
    },
    isCreate () {
        return typeof state.propertyDefinitionName !== 'string';
    },

    stateIsChanging (state) {
        return state.initializing ||
            state.propertyGroupCreating ||
            state.propertyDefinitionCreating ||
            state.propertyDefinitionUpdating ||
            state.propertyDefinitionDeleting;
    },
    displayEmptyState (state) {
        return state.initializing === false && state.schemaDefinition === null;
    },
    displayMain (state) {
        return state.initializing === false && state.schemaDefinition !== null;
    }
};

const actions = {
    ...schemaDefinition.actions,
    ...operations.actions,

    async initializeModule ({ dispatch, commit }) {
        commit('setInitializing', true);

        const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);

        // Validate schemaDefinition.
        if (schemaDefinition === null) {
            commit('setInitializing', false);
            return Promise.reject(new Error('schemaDefinition must not be null!'));
        }

        let propertyDefinition = null;
        if (typeof state.propertyDefinitionName === 'string') {
            // EDIT.
            const propertyDefinitionsByName = cloneDeep(schemaDefinition.propertyDefinitions)
                .reduce(function (accumulator, current) {
                    accumulator[current.name] = current;
                    return accumulator;
                }, {});

            if (propertyDefinitionsByName.hasOwnProperty(state.propertyDefinitionName)) {
                propertyDefinition = cloneDeep(propertyDefinitionsByName[state.propertyDefinitionName]);
            } else {
                propertyDefinition = null; // NOT FOUND.
            }
        } else {
            // CREATE.
            propertyDefinition = DomainModel.getPropertyDefinition();
        }

        // Check if propertyDefinition exists.
        if (propertyDefinition === null) {
            commit('setInitializing', false);
            return Promise.reject(new Error('propertyDefinition must not be null!'));
        }

        commit('setPropertyDefinition', propertyDefinition);
        commit('setInitializing', false);

        return Promise.resolve();
    },
    async resetModule ({ dispatch, commit }) {
        dispatch('resetSchemaDefinitionDynamicSubModule');
        dispatch('resetOperationsSubModule');

        // Reset index state.
        commit('setInitializing', false);
        commit('setPropertyDefinitionName', null);
        commit('setPropertyDefinition', null);
    }
};

const mutations = {
    ...schemaDefinition.mutations,
    ...operations.mutations,

    setInitializing (state, data) {
        Vue.set(state, 'initializing', data);
    },

    setDomainAndNameById (state, data) {
        if (typeof data !== 'string') {
            Vue.set(state, 'schemaDefinitionName', null);
            Vue.set(state, 'propertyDefinitionName', null);
            return;
        }

        const parts = data.split(':');
        if (parts.length === 0) {
            // reset.
            Vue.set(state, 'schemaDefinitionName', null);
            Vue.set(state, 'propertyDefinitionName', null);
        } else if (parts.length === 1) {
            Vue.set(state, 'schemaDefinitionName', parts[0]); // from df-dynamic (the state)
            Vue.set(state, 'propertyDefinitionName', null); // reset
        } else if (parts.length === 2 || parts.length > 2) { // TEMPORARY: If parts more than 2, ignore the rest.
            Vue.set(state, 'schemaDefinitionName', parts[0]); // from df-dynamic (the state)
            Vue.set(state, 'propertyDefinitionName', parts[1]);
        } else {}
    },

    setPropertyDefinitionName (state, data) {
        Vue.set(state, 'propertyDefinitionName', data);
    },
    setPropertyDefinition (state, data) {
        Vue.set(state, 'propertyDefinition', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
