/****************************************************************************************************
 * Send sub-module for EmailCampaign module
 * - Handles the EmailCampaign send test operation.
 * Assumes DRAFT EmailCampaign (!)
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import { EmailCampaignService } from '@/common/services/api.service';

const state = {
    emailCampaignSendingTest: false,
    emailCampaignSendTestError: null
};

const getters = {
    emailCampaignSendingTest (state) {
        return state.emailCampaignSendingTest;
    },
    emailCampaignSendTestError (state) {
        return state.emailCampaignSendTestError;
    }
};

const actions = {
    sendTestEmailCampaign ({ commit, state }, { emailList }) {
        // Start loader.
        commit('setEmailCampaignSendingTest', true);

        const payload = {
            emailCampaign: state.emailCampaign,
            emailList: emailList
        };

        return EmailCampaignService.sendTestEmailCampaign(payload)
            .then(({ data }) => {
                commit('setEmailCampaignSendTestError', null);
                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setEmailCampaignSendTestError', reason);
                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignSendingTest', false);
            });
    },
    resetSendTestSubModule ({ commit }) {
        // State Indicators
        commit('setEmailCampaignSendingTest', false);
        // Errors
        commit('setEmailCampaignSendTestError', null);
    }
};

const mutations = {
    setEmailCampaignSendingTest (state, data) {
        Vue.set(state, 'emailCampaignSendingTest', data);
    },
    setEmailCampaignSendTestError (state, data) {
        Vue.set(state, 'emailCampaignSendTestError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
