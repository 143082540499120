<template>
    <div class="OffCanvasPanel-Outer-Wrapper">
        <fade-transition>
            <div class="OffCanvasPanel-Overlay" v-bind:style="overlayStyle" v-if="withOverlay && displayPanel"></div>
        </fade-transition>
        <div class="OffCanvasPanel">
            <transition name="slide">
                <div class="OffCanvasPanel__Layer" v-bind:style="layerStyle" v-if="displayPanel">
                    <div class="OffCanvasPanel__Layer__Main" v-bind:style="mainStyle">
                        <slot></slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
const defaultWidth = '20vw';
const boxShadowLeft = '-3px 0 8px 0 rgba(66,91,118,.21)'; // Alt: '-2px 0 8px 0 rgba(0, 0, 0, 0.20)';
// const boxShadowRight = '';

/**
 * Off Canvas Panel (skeleton).
 * Check scss for more information about the functionality.
 *
 * The provided from props z-index overrides the default z-index that is declared in SCSS.
 *
 * @future : We need a unique id for each panel we create. (for optimized tree rendering and DOM manipulating)
 */
export default {
    name: 'OffCanvasPanelSkeleton',
    props: {
        displayPanel: {
            type: Boolean,
            default: false,
            required: false
        },
        panelWidth: {
            type: String,
            default: defaultWidth,
            required: false
        },
        withBoxShadowLeft: {
            type: Boolean,
            default: false,
            required: false
        },
        withOverlay: {
            type: Boolean,
            default: false,
            required: false
        },
        // @future : Add ready-to-use overlays (blur, opacity, etc)
        // @future : Add custom overlay class (which overrides (disables) ready-to-use overlay)
        overlayZIndex: {
            type: Number,
            default: 1599,
            required: false
        },
        zIndex: {
            type: Number,
            default: 1600,
            required: false
        }
    },
    computed: {
        overlayStyle () {
            return {
                'z-index': this.overlayZIndex
            };
        },
        layerStyle () {
            return {
                'z-index': this.zIndex
            };
        },
        mainStyle () {
            let obj = {};
            // Add box shadow left (if true).
            if (this.withBoxShadowLeft) {
                obj['boxShadow'] = boxShadowLeft;
            }
            // @future : Implement boxShadowRight and direction of offcanvas panel.
            return {
                width: this.panelWidth,
                ...obj
            };
        }
    }
};
</script>
