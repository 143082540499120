/**
 * SchemaDefinitionEdit module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
const routes = [
    {
        path: '/:tenantId/tools/schema-definition-edit/:id',
        name: 'schema-definition-edit',
        component: () => import(/* webpackChunkName: "schemadefinitionedit" */ '@/modules/SchemaDefinitionEdit/views/SchemaDefinitionEditView.vue')
    }
];

export default routes;
