/****************************************************************************************************
 * Unit Balance reusable store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 16 July 2020 (since 17 March 2020 - EmailCampaign store)
 ****************************************************************************************************/

import Vue from 'vue';
import { UnitBalanceService } from '@/common/services/api.service';

export default function (unitType = null) {
    if (typeof unitType !== 'string') throw new Error('unitType is missing!');
    if (unitType !== 'EMAIL' && unitType !== 'SMS') throw new Error('unitType is invalid!');

    return {
        namespaced: true,
        state: {
            unitBalanceBalance: 0
        },
        getters: {
            unitBalanceBalance (state) {
                return state.unitBalanceBalance;
            }
        },
        actions: {
            getUnitBalanceBalance ({ commit, rootGetters }) {
                const tenantAccountId = rootGetters['application/tenantAccountId'];

                // Get UnitBalance Balance (only the number).
                return UnitBalanceService.getUnitBalance(tenantAccountId, unitType)
                    .then(({ data }) => {
                        commit('setUnitBalanceBalance', data.balance);
                        return Promise.resolve(data);
                    })
                    .catch((error) => {
                        commit('setUnitBalanceBalance', 0);
                        return Promise.reject(error);
                    });
            },
            resetUnitBalanceSubModule ({ commit }) {
                commit('setUnitBalanceBalance', 0);
            }
        },
        mutations: {
            setUnitBalanceBalance (state, data) {
                Vue.set(state, 'unitBalanceBalance', data);
            }
        }
    };
};
