/****************************************************************************************************
 * UI sub-module for EmailCampaign module
 * - It handles the editor
 * - Opens / closes a specific editor
 * - Disables the open of other editors when an editor is open.
 * Assumes DRAFT EmailCampaign (!)
 *
 * The word "editor" refers to the panels that hold the EmailCampaign's information.
 * It does not refer to EmailCampaign content editor (BUILDER/HTML)!
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';

const state = {
    currentEditor: null
};

const getters = {
    editorOpen: (state) => (editor) => {
        if (editor === null) {
            return false;
        }
        return state.currentEditor === editor;
    },
    editorOpenDisabled: (state) => () => {
        return state.currentEditor !== null;
    },
    editorOverlayActive: (state) => (editor) => {
        if (editor === null) {
            return false;
        }
        if (state.currentEditor === null) {
            return false;
        }
        return state.currentEditor !== editor;
    }
};

const actions = {
    resetUiSubModule ({ commit }) {
        commit('setCurrentEditor', null);
    }
};

const mutations = {
    setCurrentEditor (state, editor) {
        Vue.set(state, 'currentEditor', editor);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
