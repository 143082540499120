/****************************************************************************************************
 * Modules Routes.
 *
 * @author Dimitris Gkoulis
 * @createdAt 10 November 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import dfFlatUiCustomizationRoutes from '@/modules/DfFlatUiCustomization/router';
import schemaDefinitionEditRoutes from '@/modules/SchemaDefinitionEdit/router';
import emailCampaignRoutes from '@/modules/EmailCampaign/router';
import emailCampaignContentRoutes from '@/modules/EmailCampaignContent/router';
import emailTemplateRoutes from '@/modules/EmailTemplate/router';
import genericAddOnRoutes from '@/modules/GenericAddOn/router';
import peopleRouter from '@/modules/People/router';
import personRoutes from '@/modules/Person/router';
import personEditRoutes from '@/modules/PersonEdit/router';
import propertyDefinitionEditRoutes from '@/modules/PropertyDefinitionEdit/router';
import smsCampaignRoutes from '@/modules/SmsCampaign/router';

const ModulesRoutes = [
    ...dfFlatUiCustomizationRoutes,
    ...schemaDefinitionEditRoutes,
    ...emailCampaignRoutes,
    ...emailCampaignContentRoutes,
    ...emailTemplateRoutes,
    ...genericAddOnRoutes,
    ...peopleRouter,
    ...personRoutes,
    ...personEditRoutes,
    ...propertyDefinitionEditRoutes,
    ...smsCampaignRoutes
];

export default ModulesRoutes;
