/****************************************************************************************************
 * Send sub-module for EmailCampaign module
 * - Handles the EmailCampaign send operation.
 * Assumes DRAFT EmailCampaign (!)
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import { EmailCampaignService } from '@/common/services/api.service';

const state = {
    emailCampaignSending: false,
    emailCampaignSendError: null
};

const getters = {
    emailCampaignSending (state) {
        return state.emailCampaignSending;
    },
    emailCampaignSendError (state) {
        return state.emailCampaignSendError;
    }
};

const actions = {
    sendEmailCampaign ({ commit, state }) {
        // Start loader.
        commit('setEmailCampaignSending', true);

        // @future : Validate emailCampaign and return Promise.reject?
        // check other loaders.
        // const isNull = state.emailCampaign === null;
        // const isDraft = state.emailCampaign.status;
        // const isSafeToSend = getters.validationResult('safeToSend');

        // Send emailCampaign.
        // This operation saves and then sends emailCampaign.
        return EmailCampaignService.sendEmailCampaign(state.emailCampaign)
            .then(({ data }) => {
                commit('setEmailCampaignSendError', null);

                // IMPORTANT: PLEASE READ:
                // No need to do anything at all BECAUSE
                // after send the EmailCampaignView will be re-mounted.
                // But keep in mind that if this architecture changes,
                // this block of code has to adapted to that changes
                // in order to provide a: [BE PREPARED]
                // continual, fluid, always moving, clean, stable experience.

                return Promise.resolve(data);
            })
            .catch((reason) => {
                commit('setEmailCampaignSendError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignSending', false);
            });
    },
    resetSendSubModule ({ commit }) {
        // State Indicators
        commit('setEmailCampaignSending', false);
        // Errors
        commit('setEmailCampaignSendError', null);
    }
};

const mutations = {
    setEmailCampaignSending (state, data) {
        Vue.set(state, 'emailCampaignSending', data);
    },
    setEmailCampaignSendError (state, data) {
        Vue.set(state, 'emailCampaignSendError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
