<template>
    <div class="css-spinner" v-bind:style="cssSpinnerStyle">
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
        <div class="css-spinner-blade"></div>
    </div>
</template>

<script>
/**
 * CSS native spinner like iOS & OS X.
 *
 * @see https://www.cssscript.com/ios-os-x-style-pure-css-loading-spinner/
 *
 * @author Dimitris Gkoulis
 * @createdAt 8 November 2020
 */
export default {
    name: 'CssSpinner',
    props: {
        color: {
            type: String,
            default: 'blue'
        },
        size: {
            type: String,
            default: '40px'
        }
    },
    computed: {
        cssSpinnerStyle () {
            return {
                'font-size': this.size
            };
        }
    }
};
</script>

<style lang="sass">
    $spinner-color: #69717d !default
    $spinner-size: 40px !default

    .css-spinner
        font-size: $spinner-size
        position: relative
        display: inline-block
        width: 1em
        height: 1em
        &.center
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            margin: auto
        .css-spinner-blade
            position: absolute
            left: .4629em
            bottom: 0
            width: .074em
            height: .2777em
            border-radius: .0555em
            background-color: transparent
            transform-origin: center -.2222em
            animation: css-spinner-fade 1s infinite linear

            $animation-delay: 0s // Default is 0s
            $blade-rotation: 0deg

            @for $i from 1 through 12
                &:nth-child(#{$i})
                    animation-delay: $animation-delay
                    transform: rotate($blade-rotation)
                    $blade-rotation: $blade-rotation + 30
                    $animation-delay: $animation-delay + .083

    @keyframes css-spinner-fade
        0%
            background-color: $spinner-color
        100%
            background-color: transparent
</style>
