<template>
    <div v-bind:id="wrapperId" class="DockedModalPanelV1-Outer-Wrapper">
        <div v-bind:id="overlayId" class="DockedModalPanelV1-Overlay" v-bind:style="overlayStyle" v-if="displayOverlay"></div>
        <div v-bind:id="panelId" v-bind:class="panelClass" v-bind:style="panelStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';

const bodyLockScrollClassName = 'locked-overflow';

const classesPerType = {
    'docked': ['DockedModalPanelV1', 'DockedModalPanelV1--Docked'],
    'modal': ['DockedModalPanelV1', 'DockedModalPanelV1--Modal']
};

/**
 * DockedModalPanel (V1) Skeleton.
 *
 * Provided the basic functionality for docked modals (panel).
 * The provided from props z-index overrides the default z-index that is declared in SCSS.
 */
export default {
    name: 'DockedModalPanelV1Skeleton',
    props: {
        type: {
            type: String,
            default: 'docked', // docked|modal
            required: false,
            validator: (prop) => [
                'docked',
                'modal'
            ].includes(prop)
        },

        // Docked Props
        side: {
            type: String,
            default: 'right', // right|left
            required: false,
            validator: (prop) => [
                'right',
                'left'
            ].includes(prop)
        },
        sideOffset: {
            type: String,
            default: '24px',
            required: false
        },

        // Modal Props
        xOffset: {
            type: String,
            default: '10%',
            required: false
        },
        yOffset: {
            type: String,
            default: '8%',
            required: false
        },

        // Common Props
        withOverlay: {
            type: Boolean,
            default: false,
            required: false
        },
        lockBodyScroll: {
            type: Boolean,
            default: false,
            required: false
        },
        // @future : Add ready-to-use overlays (blur, opacity, etc)
        // @future : Add custom overlay class (which overrides (disables) ready-to-use overlay)
        overlayZIndex: {
            type: Number,
            default: 1699,
            required: false
        },
        zIndex: {
            type: Number,
            default: 1700,
            required: false
        }
    },
    data () {
        return {
            wrapperId: null,
            overlayId: null,
            panelId: null
        };
    },
    computed: {
        panelClass () {
            if (classesPerType.hasOwnProperty(this.type)) {
                return classesPerType[this.type].join(' ');
            }
            return [].join(' ');
        },
        panelStyle () {
            let styleObj = {};
            if (this.type === 'docked') {
                // Set right/left offset.
                styleObj[this.side === 'right' || this.side === 'left' ? this.side : 'right'] = this.sideOffset;
            } else if (this.type === 'modal') {
                styleObj['top'] = this.yOffset;
                styleObj['right'] = this.xOffset;
                styleObj['bottom'] = this.yOffset;
                styleObj['left'] = this.xOffset;
            }
            return {
                ...styleObj,
                'z-index': this.zIndex
            };
        },
        overlayStyle () {
            return {
                'z-index': this.overlayZIndex
            };
        },
        displayOverlay () {
            return this.withOverlay === true;
        }
    },
    beforeMount () {
        // Create unique HTML ids for basic elements (wrapper, overlay, panel).
        let inc = new Date().getTime();
        // add ++inc to create different ids.
        this.wrapperId = `DockedModalPanelV1-Outer-Wrapper-${inc}`;
        this.overlayId = `DockedModalPanelV1-Overlay-${inc}`;
        this.panelId = `DockedModalPanelV1-${inc}`;

        // Sync scroll lock (body scroll lock).
        this.syncScrollLock();
    },
    mounted () {
        // Listen for lockBodyScroll changes and set or unset body class.
        this.$watch('lockBodyScroll', {
            handler: debounce(function (ignoredValue) {
                // Sync scroll lock (body scroll lock).
                this.syncScrollLock();
            }, 250)
        });
    },
    beforeDestroy () {
        // Remove lock-scroll class from body.
        document.body.className = document.body.className.replace(bodyLockScrollClassName, '');
    },
    methods: {
        syncScrollLock () {
            // Check if body scroll must be locked.
            if (this.lockBodyScroll === true) {
                document.body.classList.add(bodyLockScrollClassName);
            } else {
                // Remove lock-scroll class from body.
                document.body.className = document.body.className.replace(bodyLockScrollClassName, '');
            }
        }
    }
};
</script>

<style lang="scss">
    // Default zIndex(es).
    // These values are overridden by custom styles (check props and computed properties).
    $DockedModalPanelV1--zIndex: 1111;
    $DockedModalPanelV1-Overlay--zIndex: 1110; // DockedModalPanelV1--zIndex - 1

    .locked-overflow {
        overflow: hidden;
    }

    .DockedModalPanelV1-Outer-Wrapper {}

    .DockedModalPanelV1-Overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: $DockedModalPanelV1-Overlay--zIndex;
    }

    .DockedModalPanelV1 {
        position: fixed;
        background: transparent;

        // Do not use by default. The create problem to modal.
        // @future : Add them to 'X--Docked' class?
        // max-height: 100vh;
        // max-width: 100vh;

        z-index: $DockedModalPanelV1--zIndex; // Default. Override if need to.

        overflow: hidden; // Engineer should add all components in overflow enabled inner wrapper.

        // Default Styling.
        // box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 24px 0 rgba(0,0,0,.08);
    }
    .DockedModalPanelV1--Docked {
        top: auto;
        bottom: 0;

        // @future : Ensure that these are necessary.
        max-height: 100vh;
        max-width: 100vh;
    }
    .DockedModalPanelV1--Modal {
        width: auto;
        height: auto;

        // These are the defaults.
        top: 6%;
        left: 6%;
        bottom: 6%;
        right: 6%;
    }
</style>
