/****************************************************************************************************
 * Provides GenericCriteria and BuilderGenericCriteria objects that are not declared as PropertyDefinition.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 ****************************************************************************************************/

import GcModel from './gc.model';
import GcOperatorModel from './gc-operators.model';

const ORIGIN_DISPLAY_ORDER = 10000;
const METADATA_DISPLAY_ORDER = ORIGIN_DISPLAY_ORDER + 2;

const CustomBuilderGenericCriteria = {};

CustomBuilderGenericCriteria.getId = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'id';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.specific.name = 'id';
    bgc.specific.label = 'ID';
    bgc.specific.displayOrder = 1;
    bgc.specific.group = 'metadata';
    bgc.specific.groupLabel = 'Metadata';
    bgc.specific.groupDisplayOrder = METADATA_DISPLAY_ORDER;
    bgc.specific.operators = []; // In order to hide operators.
    return bgc;
};

CustomBuilderGenericCriteria.getMetadataEmailIdentityProfileId = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'metadata.emailIdentityProfileId';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.specific.name = 'emailIdentityProfileId';
    bgc.specific.label = 'Email Identity Profile ID';
    bgc.specific.displayOrder = 2;
    bgc.specific.group = 'metadata';
    bgc.specific.groupLabel = 'Metadata';
    bgc.specific.groupDisplayOrder = METADATA_DISPLAY_ORDER;
    bgc.specific.operators = []; // In order to hide operators.
    return bgc;
};

CustomBuilderGenericCriteria.getMetadataPhoneNumberIdentityProfileId = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'metadata.phoneNumberIdentityProfileId';
    bgc.operator = GcOperatorModel.getEqualOperatorValue();
    bgc.specific.name = 'phoneNumberIdentityProfileId';
    bgc.specific.label = 'Phone Number Identity Profile ID';
    bgc.specific.displayOrder = 3;
    bgc.specific.group = 'metadata';
    bgc.specific.groupLabel = 'Metadata';
    bgc.specific.groupDisplayOrder = METADATA_DISPLAY_ORDER;
    bgc.specific.operators = []; // In order to hide operators.
    return bgc;
};

CustomBuilderGenericCriteria.getSourceType = function () {
    const bgc = GcModel.getBgcCommonFilter_StringAndSelect();
    bgc.field = 'metadata.sourceType';
    bgc.value = 'UNKNOWN'; // Default is required... (actually it's not required but it's better that way)
    bgc.specific.name = 'sourceType';
    bgc.specific.label = 'Source Type';
    bgc.specific.displayOrder = 1;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    bgc.specific.options = [
        {
            label: 'Unknown',
            value: 'UNKNOWN'
        },
        {
            label: 'Portal',
            value: 'PORTAL'
        },
        {
            label: 'File Import',
            value: 'FILE_IMPORT'
        },
        {
            label: 'Online Sources',
            value: 'ONLINE_SOURCES'
        },
        {
            label: 'Offline Sources',
            value: 'OFFLINE_SOURCES'
        }
    ];
    return bgc;
};

CustomBuilderGenericCriteria.getSourceDescription = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'metadata.sourceDescription';
    bgc.specific.name = 'sourceDescription';
    bgc.specific.label = 'Source Description';
    bgc.specific.displayOrder = 2;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getCreatedBy = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'createdBy';
    bgc.specific.name = 'createdBy';
    bgc.specific.label = 'Created By';
    bgc.specific.displayOrder = 3;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getCreatedDate = function () {
    const bgc = GcModel.getBgcCommonFilter_DateOrDateTime('DATETIME');
    bgc.field = 'createdDate';
    bgc.specific.name = 'createdDate';
    bgc.specific.label = 'Created Date';
    bgc.specific.displayOrder = 4;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getCreatedDateRange = function () {
    const bgc = GcModel.getBgcCommonRangeFilter_DateOrDateTime('DATETIME');
    bgc.field = 'createdDate';
    bgc.specific.name = 'createdDate';
    bgc.specific.label = 'Created Date Range';
    bgc.specific.displayOrder = 5;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getLastModifiedBy = function () {
    const bgc = GcModel.getBgcCommonFilter_String();
    bgc.field = 'lastModifiedBy';
    bgc.specific.name = 'lastModifiedBy';
    bgc.specific.label = 'Last Modified By';
    bgc.specific.displayOrder = 6;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getLastModifiedDate = function () {
    const bgc = GcModel.getBgcCommonFilter_DateOrDateTime('DATETIME');
    bgc.field = 'lastModifiedDate';
    bgc.specific.name = 'lastModifiedDate';
    bgc.specific.label = 'Last Modified Date';
    bgc.specific.displayOrder = 7;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getLastModifiedDateRange = function () {
    const bgc = GcModel.getBgcCommonRangeFilter_DateOrDateTime('DATETIME');
    bgc.field = 'lastModifiedDate';
    bgc.specific.name = 'lastModifiedDate';
    bgc.specific.label = 'Last Modified Date Range';
    bgc.specific.displayOrder = 8;
    bgc.specific.group = 'origin';
    bgc.specific.groupLabel = 'Origin';
    bgc.specific.groupDisplayOrder = ORIGIN_DISPLAY_ORDER;
    return bgc;
};

CustomBuilderGenericCriteria.getCommunicationSubscription = function () {
    const bgc = GcModel.getBgcCommunicationSubscriptionFilter();
    bgc.specific.name = 'communicationSubscription';
    bgc.specific.label = 'Communication Subscription';
    bgc.specific.displayOrder = 0;
    bgc.specific.group = 'communication';
    bgc.specific.groupLabel = 'Communication';
    bgc.specific.groupDisplayOrder = -1; // Communication group already exists. The value -1 does not affect the process.
    return bgc;
};

const GcCustomsProvider = {};

GcCustomsProvider.getAll = function () {
    return [
        // Metadata //////////
        CustomBuilderGenericCriteria.getId(),
        CustomBuilderGenericCriteria.getMetadataEmailIdentityProfileId(),
        CustomBuilderGenericCriteria.getMetadataPhoneNumberIdentityProfileId(),
        // Origin and AbstractAuditingEntity //////////
        CustomBuilderGenericCriteria.getSourceType(),
        CustomBuilderGenericCriteria.getSourceDescription(),
        CustomBuilderGenericCriteria.getCreatedBy(),
        CustomBuilderGenericCriteria.getCreatedDate(),
        CustomBuilderGenericCriteria.getCreatedDateRange(),
        CustomBuilderGenericCriteria.getLastModifiedBy(),
        CustomBuilderGenericCriteria.getLastModifiedDate(),
        CustomBuilderGenericCriteria.getLastModifiedDateRange(),
        // Communication Subscriptions (Custom GC) //////////
        CustomBuilderGenericCriteria.getCommunicationSubscription()
    ];
};

export default GcCustomsProvider;
