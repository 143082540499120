import Vue from 'vue';

import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';

import { SILENT_TRANSLATION_WARNINGS, SILENT_FALLBACK_WARNINGS } from '@/common/config/config';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {}, // set initial locale messages
    silentTranslationWarn: SILENT_TRANSLATION_WARNINGS,
    silentFallbackWarn: SILENT_FALLBACK_WARNINGS
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
