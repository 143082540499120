/****************************************************************************************************
 * EmailTemplate store module.
 * Supports the EmailTemplate module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 ****************************************************************************************************/

import operations from './operations.submodule';
import ui from './ui.submodule';

const state = {
    ...operations.state,
    ...ui.state
};

const getters = {
    ...operations.getters,
    ...ui.getters
};

const actions = {
    ...operations.actions,
    ...ui.actions,

    async initializeModule ({ dispatch }, id) {
        await dispatch('resetModule').then(() => void 0).catch(() => void 0);
        if (typeof id !== 'string') return Promise.reject(new Error('ID cannot be null!'));
        return dispatch('getEmailTemplate', { id: id });
    },
    async resetModule ({ dispatch }) {
        await dispatch('resetOperationsSubModule');
        await dispatch('resetUiSubModule');
    }
};

const mutations = {
    ...operations.mutations,
    ...ui.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
