/****************************************************************************************************
 * Validation sub-module for EmailCampaign module [V0 - WORKING ON PROOF OF CONCEPT]
 *
 * Front-end validation only
 * - For example checks if the subject is valid
 * - For example checks the quality of subject and warns user
 * - For example does not check if the senderEmailAddress is verified
 * - For example does not check if the Broadcaster information is valid (but we expect that is valid)
 *
 * Validation is "flat" (with no pre-conditions / post-conditions / etc - plain and simple!)
 *
 * Validation operation is triggered manually (is not reactive).
 *
 * Validation operation will try to validate the current state of store.
 * It's store's responsibility to load and sync all necessary information.
 *
 * Lifecycle:
 * - Load email campaign
 * - Load email campaign necessary information (broadcaster, workspace, workspace count, etc)
 * - Perform validations and store them in facts.
 * - Then combine facts and produce results.
 *
 * IMPORTANT:
 * A fact that is false, does not imply invalidity.
 * A result that is false, implies invalidity.
 * A result that is true, implies validity.
 *
 * Assumes DRAFT EmailCampaign (!)
 *
 * @future Implementations:
 * - Replace validator lib with is.js lib (https://github.com/arasatasaygin/is.js)?
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'validator/es/lib/isEmpty';
import isLength from 'validator/es/lib/isLength';

const SUBJECT_MIN_LENGTH = 1;
const SUBJECT_MAX_LENGTH = 150;
const PREVIEW_TEXT_MIN_LENGTH = 1;
const PREVIEW_TEXT_MAX_LENGTH = 150;

/**
 * Given an input, it check if string and trims it. If it is not string, it returns an empty string.
 * IMPORTANT: Use only in 'validationHelper'.
 */
function cleanStringForValidation (str) {
    let isString = typeof str === 'string' || str instanceof String;
    if (!isString) {
        return '';
    }
    return str.trim();
}

/**
 * Validates an emailCampaign.
 *
 * @param emailCampaign the emailCampaign to validate - it cannot be null
 * @param broadcaster the broadcaster that fetched by emailCampaign.broadcasterId - if null, we assume that it does not exist.
 * @param workspace the workspace that fetched by emailCampaign.broadcasterId - if null, we assume that it does not exist.
 * @param workspaceCountSubscribed the subscribed People in the "workspaceId" workspace.
 * @param unitBalanceBalance the tenant's current unit balance for this type of campaign.
 * @returns {{validationResults: {}, validationFacts: {}}}
 */
const validationHelper = (emailCampaign, broadcaster, workspace, workspaceCountSubscribed, unitBalanceBalance) => {
    let facts = {};
    let results = {};

    // EmailCampaign must not be null.
    if (emailCampaign === null) {
        throw new Error('emailCampaign must not be null!');
    }

    // Get all fields.
    let broadcasterId = get(emailCampaign, 'broadcasterId', '');
    broadcasterId = cleanStringForValidation(broadcasterId);
    let workspaceId = get(emailCampaign, 'workspaceId', '');
    workspaceId = cleanStringForValidation(workspaceId);
    let subject = get(emailCampaign, 'subject', '');
    subject = cleanStringForValidation(subject);
    let previewText = get(emailCampaign, 'previewText', '');
    previewText = cleanStringForValidation(previewText);
    let bodyHtml = get(emailCampaign, 'bodyHtml', '');
    bodyHtml = cleanStringForValidation(bodyHtml);
    let bodyPlain = get(emailCampaign, 'bodyPlain', '');
    bodyPlain = cleanStringForValidation(bodyPlain);

    // Broadcaster //////////
    facts['broadcasterId_isEmpty'] = isEmpty(broadcasterId, {
        ignore_whitespace: true
    });
    facts['broadcaster_isNull'] = broadcaster === null;

    // Workspace //////////
    facts['workspaceId_isEmpty'] = isEmpty(workspaceId, {
        ignore_whitespace: true
    });
    facts['workspace_isNull'] = workspace === null;
    facts['workspaceRecipients_isZero'] = workspaceCountSubscribed <= 0;

    // Subject //////////
    facts['subject_isEmpty'] = isEmpty(subject, {
        ignore_whitespace: true
    });
    facts['subject_hasInvalidLength'] = !isLength(subject, {
        min: SUBJECT_MIN_LENGTH,
        max: SUBJECT_MAX_LENGTH
    });

    // Preview Text ///////////
    facts['previewText_isEmpty'] = isEmpty(previewText, {
        ignore_whitespace: true
    });
    facts['previewText_hasInvalidLength'] = !isLength(previewText, {
        min: PREVIEW_TEXT_MIN_LENGTH,
        max: PREVIEW_TEXT_MAX_LENGTH
    });

    // Body HTML //////////
    facts['bodyHtml_isEmpty'] = isEmpty(bodyHtml, {
        ignore_whitespace: true
    });

    // Body Plain ///////////
    facts['bodyPlain_isEmpty'] = isEmpty(bodyPlain, {
        ignore_whitespace: true
    });

    // Body HTML and Plain //////////
    // @future : Calc bytes? AWS restricts size to 500KB.

    // Unit Balance //////////
    facts['unitBalance_balance_insufficient'] = workspaceCountSubscribed > unitBalanceBalance;

    // Generate results.
    results['broadcasterValid'] = facts['broadcasterId_isEmpty'] === false &&
        facts['broadcaster_isNull'] === false;

    results['workspaceValid'] = facts['workspaceId_isEmpty'] === false &&
        facts['workspace_isNull'] === false &&
        facts['workspaceRecipients_isZero'] === false;

    results['subjectValid'] = facts['subject_isEmpty'] === false &&
        facts['subject_hasInvalidLength'] === false;

    results['previewTextValid'] = facts['previewText_isEmpty'] ? true : facts['previewText_hasInvalidLength'] === false;

    results['subjectAndPreviewTextValid'] = results['subjectValid'] === true && results['previewTextValid'] === true;

    results['bodyHtmlValid'] = facts['bodyHtml_isEmpty'] === false;

    results['bodyPlainValid'] = facts['bodyPlain_isEmpty'] === false;

    results['contentValid'] = results['bodyHtmlValid'] === true;
    // && results['bodyPlainValid'] === true; // empty bodyPlain is accepted.

    results['unitBalanceValid'] = facts['unitBalance_balance_insufficient'] === false;

    results['safeToSend'] = results['broadcasterValid'] === true &&
        results['workspaceValid'] === true &&
        results['subjectValid'] === true &&
        results['previewTextValid'] === true &&
        results['bodyHtmlValid'] === true &&
        // && results['bodyPlainValid'] === true; // empty bodyPlain is accepted.
        results['unitBalanceValid'] === true;

    // Return data.
    return {
        facts: facts,
        results: results
    };
};

const state = {
    validation: null
};

const getters = {
    validationResult: (state) => (result) => {
        if (result === null) return false;
        if (state.validation === null) return false;
        if (!state.validation.hasOwnProperty('results')) return false;
        if (!state.validation.results.hasOwnProperty(result)) return false;
        return state.validation.results[result];
    },
    // The difference is that this getters relies only on validation result.
    // In any other case returns null.
    // Very useful in cases such as quotas, balances or other background checks
    // where we don't want to show user anything until a result exists.
    validationResultIndeterminate: (state) => (result) => {
        if (result === null || result === undefined) return null;
        if (state.validation === null) return null;
        if (!state.validation.hasOwnProperty('results')) return null;
        if (!state.validation.results.hasOwnProperty(result)) return null;
        return state.validation.results[result];
    },

    // D E P R E C A T E D.
    // This seems to be in wrong place but is the only solution right now
    // in order to keep the sub-module structure clean.
    uiIconClassBasedOnValidationResult: (state) => (result) => {
        const defaultUiClass = 'far fa-check-circle text-muted'; // We assume loading or not yet set. Return check-circle muted.

        if (state.emailCampaignGetting === true || state.emailCampaignUpdating === true) {
            return defaultUiClass;
        }

        if (result === null) {
            return defaultUiClass;
        }
        if (state.validation === null) {
            return defaultUiClass;
        }
        if (!state.validation.hasOwnProperty('results')) {
            return defaultUiClass;
        }
        if (!state.validation.results.hasOwnProperty(result)) {
            return defaultUiClass;
        }

        return state.validation.results[result] ? 'far fa-check-circle text-success' : 'fas fa-exclamation-circle text-warning';
    }
};

const actions = {
    validateEmailCampaignLocally ({ commit, state }) {
        // Validate emailCampaign.
        const validationHelperResult = validationHelper(
            state.emailCampaign,
            state.broadcaster,
            state.workspace,
            state.workspaceCountSubscribed,
            state.unitBalanceBalance);

        // Commit validationHelperResult object.
        commit('setValidation', validationHelperResult);
    },
    resetValidationV0SubModule ({ commit }) {
        commit('setValidation', null);
    }
};

const mutations = {
    setValidation (state, data) {
        Vue.set(state, 'validation', cloneDeep(data));
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
