import Vue from 'vue';

import router from '@/router/index';

import CarbonComponentsVue from '@carbon/vue/src/index';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Avatar from 'vue-avatar';
import VTooltip from 'v-tooltip';
import Toasted from 'vue-toasted';
import BootstrapVue from 'bootstrap-vue';
import Transitions from 'vue2-transitions';

const ToastedDefaultConfiguration = {
    router,
    containerClass: 'ToastedContainer--BottomCenter--FullWidth',
    // className: '',
    // theme: '',
    position: 'bottom-center',
    duration: 6000,
    fullWidth: false,
    action: {
        text: 'Close', // Translation takes place in component.
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
};

const AppDependencies = {
    init () {
        Vue.use(BootstrapVue);
        Vue.use(CarbonComponentsVue, ['CvPagination', 'CvSkeletonText']);
        Vue.component('avatar', Avatar);
        Vue.use(VTooltip);
        Vue.use(ElementUI, { locale });
        Vue.use(Toasted, ToastedDefaultConfiguration);
        Vue.use(Transitions);
    }
};

export default AppDependencies;
