/****************************************************************************************************
 * Application Quotas UI helper store sub-module.
 *
 * This sub-module is used by quotas.submodule.js.
 *
 * It provides information related to UI.
 * - disable or enable Create buttons
 * - disabled Create buttons v-tooltip object
 *
 * @author Dimitris Gkoulis
 * @createdAt 14 March 2020
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/common/plugins/i18n';

const vTooltipObjectPerEntityName = {
    'workspace': {
        placement: 'bottom',
        tcEntity: 'Workspace'
    },
    'emailTemplate': {
        placement: 'bottom',
        tcEntity: 'Email Template'
    },
    'person': {
        placement: 'bottom',
        tcEntity: 'Person'
    },
    'broadcaster': {
        placement: 'bottom',
        tcEntity: 'Broadcaster'
    },
    'senderEmailAddress': {
        placement: 'bottom',
        tcEntity: 'Sender Email Address'
    }
};

const defaultVTooltipObject = {
    // show: false,
    content: 'You\'ve reached the limit of {limit} {itemWord} available for your account',
    placement: 'left',
    trigger: 'hover',
    classes: [],
    targetClasses: [],
    offset: 10,
    delay: {
        show: 200,
        hide: 200
    }
};

const state = {
    quotasUiEntityQluMap: {}
};

const getters = {
    quotasUiEntityQluDisabled: (state) => (entityName) => {
        if (!state.quotasUiEntityQluMap.hasOwnProperty(entityName)) {
            return false;
        }
        return state.quotasUiEntityQluMap[entityName].disabled;
    },
    quotasUiEntityQluVTooltipObject: (state) => (entityName) => {
        if (!state.quotasUiEntityQluMap.hasOwnProperty(entityName)) {
            return null;
        }
        return state.quotasUiEntityQluMap[entityName].vTooltipObject;
    }
};

const actions = {};

const mutations = {
    quotasUiEntityQluSet (state, obj) {
        // @future : Validate obj.
        const entityName = obj.entityName;

        // Get default vTooltip object.
        const vTooltipObject = cloneDeep(defaultVTooltipObject);

        // Get the tc word for i18n translations.
        // The default.
        let tcEntity = 'Item';
        if (vTooltipObjectPerEntityName.hasOwnProperty(entityName) &&
            typeof vTooltipObjectPerEntityName[entityName].tcEntity === 'string') {
            tcEntity = vTooltipObjectPerEntityName[entityName].tcEntity;
        }

        // Change object based on entityQlu.
        const disabled = obj.usage >= obj.limit;
        // vTooltipObject.show = disabled; // Display v-tooltip if operation is disabled.
        // vTooltipObject.content = vTooltipObject.content.replace('{limit}', obj.limit + '');
        // vTooltipObject.content = vTooltipObject.content.replace('{itemWord}', obj.limit === 1 ? 'item' : 'items');
        vTooltipObject.content = i18n.t('quotas_reach_limit_1', {
            limit: obj.limit,
            itemWord: i18n.tc(tcEntity, obj.limit)
        });

        // Set v-tooltip placement. It's not right to set it in here but there is no other solution right now.
        // Get also the tc Entity.
        if (vTooltipObjectPerEntityName.hasOwnProperty(entityName)) {
            vTooltipObject.placement = vTooltipObjectPerEntityName[entityName].placement;
        }

        // Add or update entity obj in quotasUiEntityQluMap.
        // state.quotasUiEntityQluMap[entityName] = null;
        Vue.set(state.quotasUiEntityQluMap, entityName, {
            id: new Date().getTime() + '',
            disabled: disabled,
            vTooltipObject: disabled === true ? cloneDeep(vTooltipObject) : null
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
