<template>
    <off-canvas-panel-skeleton :display-panel="displayPanel"
                               :panel-width="panelWidth"
                               :with-box-shadow-left="withBoxShadowLeft"
                               :with-overlay="withOverlay">
        <div class="UI-Fullscreen-Column Brand-Default-Style">
            <div class="UI-Fullscreen-Column__Container">
                <div class="UI-Fullscreen-Column__Header" v-if="displayPanelHeader">
                    <slot name="header"></slot>
                </div>
                <div class="UI-Fullscreen-Column__Body" v-if="displayPanelBody">
                    <slot name="body"></slot>
                </div>
                <div class="UI-Fullscreen-Column__Footer border-top" v-if="displayPanelFooter">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </off-canvas-panel-skeleton>
</template>

<script>
// import OffCanvasPanelSkeleton from './OffCanvasPanelSkeleton';

const defaultWidth = '20vw';

/**
 * This is a ready to use component.
 * For more flexibility check OffCanvasPanelSkeleton component.
 */
export default {
    name: 'OffCanvasPanel',
    components: {
        // OffCanvasPanelSkeleton
    },
    props: {
        displayPanel: {
            type: Boolean,
            default: false,
            required: false
        },
        panelWidth: {
            type: String,
            default: defaultWidth,
            required: false
        },
        withBoxShadowLeft: {
            type: Boolean,
            default: false,
            required: false
        },
        withOverlay: {
            type: Boolean,
            default: false,
            required: false
        },
        displayPanelHeader: {
            type: Boolean,
            default: true,
            required: false
        },
        displayPanelBody: {
            type: Boolean,
            default: true,
            required: false
        },
        displayPanelFooter: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data () {
        return {
            mainStyle: {
                width: defaultWidth
            }
        };
    },
    beforeMount () {
        this.mainStyle.width = this.panelWidth;
    }
};
</script>
