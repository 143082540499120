/****************************************************************************************************
 * Application service quotas store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 13 March 2020
 * @lastModifiedAt 26 June 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import { QuotasService } from '@/common/services/api.service';

const state = {
    quotasEntityQluMap: {}
};

const getters = {
    quotasEntityQluMap (state) {
        return state.quotasEntityQluMap;
    },
    quotasEntityQlu: (state) => (entityName) => {
        if (!state.quotasEntityQluMap.hasOwnProperty(entityName)) {
            return null;
        }
        return state.quotasEntityQluMap[entityName];
    }
};

const actions = {
    quotasFindOneEntityQlu ({ commit }, entityName) {
        return QuotasService.findOneEntityQlu(entityName).then(({ data }) => {
            commit('quotasEntityQluSet', data);
            commit('quotasUiEntityQluSet', data); // Update quotasUi information.
            return Promise.resolve(data);
        }).catch((reason) => {
            return Promise.reject(reason);
        });
    },
    quotasGetAllEntityQlu ({ commit }) {
        return QuotasService.getAllEntityQlu().then(({ data }) => {
            commit('quotasEntityQluMapSet', data);
            for (const dataItemKey in data) {
                commit('quotasUiEntityQluSet', data[dataItemKey]); // Update quotasUi information for each entity.
            }
            return Promise.resolve(data);
        }).catch((reason) => {
            return Promise.reject(reason);
        });
    }
};

const mutations = {
    quotasEntityQluSet (state, obj) {
        // @future : Validate obj.
        const entityName = obj.entityName;
        state.quotasEntityQluMap[entityName] = {};
        state.quotasEntityQluMap[entityName] = cloneDeep(obj);
    },
    quotasEntityQluMapSet (state, obj) {
        // @future : Validate obj.
        state.quotasEntityQluMap = {};
        state.quotasEntityQluMap = cloneDeep(obj);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
