<template>
    <div class="SimpleStateEmpty" :class="{ 'SimpleStateEmpty--Default': defaultStyle }">
        <slot name="title">
            <p class="mb-0 size-4">
                <slot name="titleText">
                    <span>{{ $t('ssempty_title_1') }}</span>
                </slot>
            </p>
        </slot>
        <slot name="action"></slot>
    </div>
</template>

<script>
/**
 * Simple empty state (usually for empty collection/listing).
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 July 2020
 */
export default {
    name: 'SimpleStateEmpty',
    props: {
        defaultStyle: {
            type: Boolean,
            default: true,
            required: false
        }
    }
};
</script>

<style lang="scss">
    .SimpleStateEmpty--Default {
        position: relative;
        margin-top: 1.5rem;
    }
</style>

<i18n>
{
    "en": {
        "ssempty_title_1": "No results found"
    },
    "el": {
        "ssempty_title_1": "Δε βρέθηκαν αποτελέσματα "
    }
}
</i18n>
