/****************************************************************************************************
 * LegacyGpUiUserRecord logic.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 1 August 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import { LegacyGpUiUserRecordService } from '@/common/services/api.service';

// Fallback lists per key in cases of error,
// no data or any other unexpected situations.
const FallbackListsByKey = {
    person_left_sidebar_summary_card: [
        'labels',
        'email',
        'phoneNumber',
        'websiteUrl',
        'age',
        'preferredLanguageKey',
        'doNotCommunicate'
    ]
};

const LegacyGpUiUserRecordLogic = {};

/**
 * Logic:
 * - Get user customization (LegacyGpUiUserRecord.stringList)
 * - If user customization does not exist, get default customization (per tenant)
 * - If default customization (per tenant) does not exist, get (hard-coded) fallback list
 * - If fallback list does not exist, get empty list
 *
 * Usage (catch is theoretically unreachable):
 * LegacyGpUiUserRecordLogic.provideStringListByKey(key).then((data) => data).catch(() => []);
 *
 * @future 1 August 2020 VERY IMPORTANT:
 * If DfFlatUiCustomization allow user to select no options at all
 * then this function will provide the fallback list.
 * If business logic says that user can have no options that's fine.
 * But if business logic allows user to have no options it can cause some problems.
 * Den einai toso omos ta provlimata oso to mperdema pou tha prokalesei!!!
 * Thelei ligo skepsi opote prosoxi.
 *
 * @param key the LegacyGpUiUserRecord key
 * @param enableFallback if false fallback value will be an empty list
 */
LegacyGpUiUserRecordLogic.provideStringListByKey = function (key, enableFallback = true) {
    if (typeof key !== 'string') return [];
    let fallbackList = [];
    if (enableFallback === true && FallbackListsByKey.hasOwnProperty(key)) {
        fallbackList = cloneDeep(FallbackListsByKey[key]);
    }

    return LegacyGpUiUserRecordService.ensuredGetLegacyGpUiUserRecord(key)
        .then(({ data }) => {
            if (data === null || data === undefined) {
                return Promise.resolve(fallbackList);
            }
            // noinspection JSUnresolvedVariable
            if (!Array.isArray(data.stringList)) {
                return Promise.resolve(fallbackList);
            }
            // noinspection JSUnresolvedVariable
            if (data.stringList.length === 0) {
                return Promise.resolve(fallbackList);
            }
            // noinspection JSUnresolvedVariable
            return Promise.resolve(data.stringList);
        })
        .catch(() => {
            return Promise.resolve(fallbackList);
        });
};

/**
 * Common map function mostly for presentation layer.
 */
LegacyGpUiUserRecordLogic.transformListToObjectForStore = function (list) {
    if (!Array.isArray(list)) return {};
    if (list.length === 0) return {};
    return list
        .map(function (item, index) {
            return {
                name: item,
                displayOrder: index
            };
        })
        .reduce(function (accumulator, current) {
            accumulator[current.name] = current.displayOrder;
            return accumulator;
        }, {});
};

export default LegacyGpUiUserRecordLogic;
