/****************************************************************************************************
 * Application unit balance store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 4 April 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
import { UnitBalanceService } from '@/common/services/api.service';

const state = {
    unitBalanceMap: {}
};

const getters = {
    unitBalanceMap (state) {
        return state.unitBalanceMap;
    },
    unitBalance: (state) => (unitType) => {
        if (!state.unitBalanceMap.hasOwnProperty(unitType)) {
            return null;
        }
        return state.unitBalanceMap[unitType];
    }
};

const actions = {
    unitBalanceFindOneUnitBalance ({ commit }, unitType) {
        const tenantAccountId = getters['tenantAccountId'];
        return UnitBalanceService.getUnitBalance(tenantAccountId, unitType).then(({ data }) => {
            commit('unitBalanceSet', data);
            return Promise.resolve(data);
        }).catch((reason) => {
            return Promise.reject(reason);
        });
    },
    unitBalanceGetAllUnitBalances ({ commit, getters }) {
        const tenantAccountId = getters['tenantAccountId'];
        return UnitBalanceService.getAllUnitBalances(tenantAccountId).then(({ data }) => {
            data = data.reduce(function (accumulator, current) {
                accumulator[current.type] = current;
                return accumulator;
            }, {});
            commit('unitBalanceMapSet', data);
            return Promise.resolve(data);
        }).catch((reason) => {
            return Promise.reject(reason);
        });
    }
};

const mutations = {
    unitBalanceSet (state, obj) {
        // @future : Validate obj.
        const entityName = obj.entityName;
        state.unitBalanceMap[entityName] = {};
        state.unitBalanceMap[entityName] = cloneDeep(obj);
    },
    unitBalanceMapSet (state, obj) {
        // @future : Validate obj.
        state.unitBalanceMap = {};
        state.unitBalanceMap = cloneDeep(obj);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
