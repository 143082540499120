/****************************************************************************************************
 * Provides convenient way to get GenericCriteriaOperator per use case.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 July 2020
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';

const EqualOperator = {
    value: 'EQUAL',
    label: 'equal'
};
const NotEqualOperator = {
    value: 'NOT_EQUAL',
    label: 'not equal'
};
const ContainOperator = {
    value: 'CONTAIN',
    label: 'contain'
};
const NotContainOperator = {
    value: 'NOT_CONTAIN',
    label: 'not contain'
};
const StartWithOperator = {
    value: 'START_WITH',
    label: 'start with'
};
const NotStartWithOperator = {
    value: 'NOT_START_WITH',
    label: 'not start with'
};
const EndWithOperator = {
    value: 'END_WITH',
    label: 'end with'
};
const NotEndWithOperator = {
    value: 'NOT_END_WITH',
    label: 'not end with'
};
const LessThanOperator = {
    value: 'LESS_THAN',
    label: 'less than'
};
const LessThanEqualOperator = {
    value: 'LESS_THAN_EQUAL',
    label: 'less than equal'
};
const GreaterThanOperator = {
    value: 'GREATER_THAN',
    label: 'greater than'
};
const GreaterThanEqualOperator = {
    value: 'GREATER_THAN_EQUAL',
    label: 'greater than equal'
};
const EmptyOperator = {
    value: 'EMPTY',
    label: 'empty'
};
const NotEmptyOperator = {
    value: 'NOT_EMPTY',
    label: 'not empty'
};
const InOperator = {
    value: 'IN',
    label: 'in'
};
const NotInOperator = {
    value: 'NOT_IN',
    label: 'not in'
};
const AllOperator = {
    value: 'ALL',
    label: 'all'
};
const NotAllOperator = {
    value: 'NOT_ALL',
    label: 'not all'
};
const BetweenOperator = {
    value: 'BETWEEN',
    label: 'between'
};
const NotBetweenOperator = {
    value: 'NOT_BETWEEN',
    label: 'not between'
};
const AndOperatorAsAll = {
    value: 'AND',
    label: 'and',
    labelAlt: 'and_all'
};
const OrOperatorAsAny = {
    value: 'OR',
    label: 'or',
    labelAlt: 'or_any'
};

const AllOperatorsForField = [
    { ...EqualOperator, eligible: ['select', 'string', 'number', 'date'] },
    { ...NotEqualOperator, eligible: ['select', 'string', 'number', 'date'] },
    { ...ContainOperator, eligible: ['string'] },
    { ...NotContainOperator, eligible: ['string'] },
    { ...StartWithOperator, eligible: ['string'] },
    { ...NotStartWithOperator, eligible: ['string'] },
    { ...EndWithOperator, eligible: ['string'] },
    { ...NotEndWithOperator, eligible: ['string'] },
    { ...GreaterThanOperator, eligible: ['number', 'date'] },
    { ...GreaterThanEqualOperator, eligible: ['number', 'date'] },
    { ...LessThanOperator, eligible: ['number', 'date'] },
    { ...LessThanEqualOperator, eligible: ['number', 'date'] },
    { ...EmptyOperator, eligible: ['select', 'string', 'date', 'array'] },
    { ...NotEmptyOperator, eligible: ['select', 'string', 'date', 'array'] },
    { ...AllOperator, eligible: ['array'] },
    { ...NotAllOperator, eligible: ['array'] },
    { ...InOperator, eligible: ['array'] },
    { ...NotInOperator, eligible: ['array'] },
    { ...BetweenOperator, eligible: ['range'] },
    { ...NotBetweenOperator, eligible: ['range'] }
];

const AllOperatorsForFieldByEligibility = AllOperatorsForField.reduce(function (accumulator, current) {
    const eligibleList = current.eligible;
    const value = cloneDeep(current);
    delete value['eligible'];
    for (const key of eligibleList) {
        if (accumulator[key] === null || accumulator[key] === undefined) {
            accumulator[key] = [];
        }
        accumulator[key].push(value);
    }
    return accumulator;
}, {});

// RtU: exposes only what's used.
const GcOperatorModel = {
    // For COMMON_GROUP.
    getAndOperatorAsAllValue () {
        return AndOperatorAsAll.value;
    },
    getOperatorsForGroup () {
        return cloneDeep([
            AndOperatorAsAll,
            OrOperatorAsAny
        ]);
    },
    // Specific traits for GC - QUICK ACCESS
    getEqualOperatorValue () {
        return EqualOperator.value;
    },
    getAllOperatorValue () {
        return AllOperator.value;
    },
    getContainOperatorValue () {
        return ContainOperator.value;
    },
    getBetweenOperatorValue () {
        return BetweenOperator.value;
    },
    // For GC lists of operators.
    getOperatorsForSelect () {
        return cloneDeep(AllOperatorsForFieldByEligibility['select']);
    },
    getOperatorsForString () {
        return cloneDeep(AllOperatorsForFieldByEligibility['string']);
    },
    getOperatorsForDateAndDateTime () {
        return cloneDeep(AllOperatorsForFieldByEligibility['date']);
    },
    getOperatorsForNumber () {
        return cloneDeep(AllOperatorsForFieldByEligibility['number']);
    },
    getOperatorsForRange () {
        return cloneDeep(AllOperatorsForFieldByEligibility['range']);
    },
    getOperatorsForArray () {
        return cloneDeep(AllOperatorsForFieldByEligibility['array']);
    }
};

export default GcOperatorModel;
