/****************************************************************************************************
 * Inline Frame Utilities.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe
 *
 * @author Dimitris Gkoulis
 * @createdAt 3 November 2020
 * @lastModifiedAt 5 November 2020
 ****************************************************************************************************/

const InlineFrameUtils = {};

/**
 * Set iframe visibility.
 *
 * Does not throw any exception.
 * It has to be safe in order to be safely called by the rest methods.
 *
 * @param elementId the iframe element ID (DOM attr).
 * @param visible true to make iframe visible, false to make iframe hidden.
 */
InlineFrameUtils.setFrameVisibility = function (elementId, visible = true) {
    if (typeof elementId !== 'string') {
        return;
    }

    if (typeof visible !== 'boolean') {
        visible = true;
    }

    let frame = document.getElementById(elementId);

    if (frame === null) {
        return;
    }

    frame.style.visibility = visible === true ? 'visible' : 'hidden';
};

/**
 * Refresh iframe HTML.
 *
 * @param html the HTML to insert.
 * @param elementId the iframe element ID (DOM attr).
 * @throws Error if the elementId is not string or if the frame element does not exist.
 */
InlineFrameUtils.refreshFrameHtml = function (html, elementId) {
    if (typeof elementId !== 'string') {
        throw new Error('The elementId must be a string!');
    }

    let frame = document.getElementById(elementId);

    if (frame === null) {
        throw new Error('The iframe element does not exist!');
    }

    let frameDocument;
    if (frame.contentWindow) {
        frameDocument = frame.contentWindow.document;
    } else {
        frameDocument = frame.document;
    }

    frameDocument.open();
    frameDocument.writeln(html);
    frameDocument.close();
};

/**
 * Refresh the iframe element's height.
 *
 * @param elementId the iframe element ID (DOM attr).
 * @throws Error if the elementId is not string or if the frame element does not exist.
 */
InlineFrameUtils.refreshFrameHeight = function (elementId) {
    if (typeof elementId !== 'string') {
        throw new Error('The elementId must be a string!');
    }

    let frame = document.getElementById(elementId);

    if (frame === null) {
        throw new Error('The iframe element does not exist!');
    }

    let frameDocument;
    if (frame.contentWindow) {
        frameDocument = frame.contentWindow.document;
    } else {
        frameDocument = frame.document;
    }

    // noinspection UnnecessaryLocalVariableJS
    const frameHeight = frameDocument.body.scrollHeight + 'px';
    frame.style.height = frameHeight;
};

/**
 * Refresh safely the iframe HTML and it's height.
 *
 * @param html the HTML to insert.
 * @param elementId the iframe element ID (DOM attr).
 * @param hiddenUntilHeightIsSet if true, the iframe will remain hidden until the height is set.
 * @param heightDelay the number of ms to wait before refreshing the height.
 * @deprecated The height delay is problematic especially on situations with often changes (multiple per 2-5 sec window).
 */
InlineFrameUtils.refreshSafelyFrameHtmlAndHeightWithDelay = function (html, elementId, hiddenUntilHeightIsSet = false, heightDelay = 500) {
    try {
        if (hiddenUntilHeightIsSet === true) {
            InlineFrameUtils.setFrameVisibility(elementId, false);
        }
        InlineFrameUtils.refreshFrameHtml(html, elementId);
    } catch (ignoredError) {
        if (hiddenUntilHeightIsSet === true) {
            InlineFrameUtils.setFrameVisibility(elementId, true); // Make this visible. But probably does not exist...
        }
    }

    setTimeout(function () {
        try {
            InlineFrameUtils.refreshFrameHeight(elementId);
            if (hiddenUntilHeightIsSet === true) {
                InlineFrameUtils.setFrameVisibility(elementId, true);
            }
        } catch (ignoredError) {
            if (hiddenUntilHeightIsSet === true) {
                InlineFrameUtils.setFrameVisibility(elementId, true); // Make this visible. But probably does not exist...
            }
        }
    }, heightDelay);
};

export default InlineFrameUtils;
