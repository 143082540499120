/**
 * PropertyDefinitionEdit module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 */
const routes = [
    {
        // id format "domain:name"
        path: '/:tenantId/tools/property-definition-edit/:id',
        name: 'property-definition-edit',
        component: () => import(/* webpackChunkName: "propertydefinitionedit" */ '@/modules/PropertyDefinitionEdit/views/PropertyDefinitionEditView.vue')
    }
];

export default routes;
