import fecha from 'fecha';
// eslint-disable-next-line import/no-named-default
// import { default as format } from 'date-fns/format'; // @future : Use this?

const DEFAULT_FORMAT = 'DD-MM-YYYY HH:mm ZZ';

/**
 * Date Literal filter : Format a JS date (or a string that is converted to JS Date) to the provided format.
 *
 * MM-DD-YYYY HH:mm ZZ e.g.:03-05-2001 06:07'
 * DD-MM-YYYY HH:mm ZZ Current format.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt September 2019
 */
export default (theDate, defaultFormat = null, defaultValue = '-') => {
    if (typeof theDate !== 'string' && !(theDate instanceof Date)) return defaultValue;
    if (typeof defaultFormat !== 'string') defaultFormat = DEFAULT_FORMAT;
    try {
        let date;
        if (typeof theDate === 'string') date = new Date(theDate);
        else date = theDate;
        // Check if it's valid date.
        if (date instanceof Date && !isNaN(date)) return fecha.format(date, defaultFormat);
        else return defaultValue;
    } catch (e) {
        return defaultValue;
    }
};
