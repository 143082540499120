import i18n from '@/common/plugins/i18n';

/**
 * Translate filter : provides functionality to use vue-i18n plugin from filter.
 *
 * NOTICE: It is its basic form. @future Implementations:
 * - Validate provided method.
 * - Add params
 * - Implement all methods provided by vue-i18n plugin e.g. tc, etc
 * - For implementation ideas @see https://github.com/chiaweilee/vue-i18n-filter/blob/master/src/index.ts
 *   FOR IDEAS ONLY! DO NOT C_P the above! Many caveats regarding his implementation and its performance.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 3 April 2020
 */
export default (text, method = 't') => {
    return i18n[method](text);
};
