/****************************************************************************************************
 * Vuex store declarations.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 2018
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import Vuex from 'vuex';

import genericListing from './generics/genericListing.module';

import application from './modules/application/application.module';

import search from '@/modules/Search/store/index.module';
import personActivityV0 from '@/modules/PersonActivityV0/store/index.module';
import person from '@/modules/Person/store/index.module';
import personEdit from '@/modules/PersonEdit/store/index.module';
import people from '@/modules/People/store/index.module';
import emailCampaign from '@/modules/EmailCampaign/store/index.module';
import emailCampaignContent from '@/modules/EmailCampaignContent/store/index.module';
import emailTemplate from '@/modules/EmailTemplate/store/index.module';
import smsCampaign from '@/modules/SmsCampaign/store/index.module';
import schemaDefinitionEdit from '@/modules/SchemaDefinitionEdit/store/index.module';
import dfFlatUiCustomization from '@/modules/DfFlatUiCustomization/store/index.module';
import propertyDefinitionEdit from '@/modules/PropertyDefinitionEdit/store/index.module';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    modules: {
        application: application,
        // MODULES
        search: search,
        personActivityV0: personActivityV0,
        person: person,
        personEdit: personEdit,
        people: people,
        emailCampaign: emailCampaign,
        emailCampaignContent: emailCampaignContent,
        emailTemplate: emailTemplate,
        smsCampaign: smsCampaign,
        schemaDefinitionEdit: schemaDefinitionEdit,
        dfFlatUiCustomization: dfFlatUiCustomization,
        propertyDefinitionEdit: propertyDefinitionEdit,
        // VIEWS @deprecated - they will be moved in modules.
        // Leader MS //////////
        workspaces: genericListing({
            microservice: 'leader',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'workspaces',
            getOnePath: null,
            deleteOnePath: null
        }, {
            listingPage: 0,
            listingSize: 40,
            listingSortField: 'lastModifiedDate',
            listingSortType: 'desc'
        }),
        campaigns: genericListing({
            microservice: 'leader',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'campaigns',
            getOnePath: null,
            deleteOnePath: null
        }),
        emailTemplates: genericListing({
            microservice: 'leader',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'emailtemplates',
            getOnePath: null,
            deleteOnePath: null
        }),
        broadcasters: genericListing({
            microservice: 'leader',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'broadcasters',
            getOnePath: null,
            deleteOnePath: null
        }),
        senderEmailAddresses: genericListing({
            microservice: 'leader',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'sender-email-addresses',
            getOnePath: null,
            deleteOnePath: null
        }, {
            listingPage: 0,
            listingSize: 100,
            listingSortField: 'id',
            listingSortType: 'desc'
        }),
        // Accounts MS //////////
        brandings: genericListing({
            microservice: 'accounts',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'brandings',
            getOnePath: null,
            deleteOnePath: null
        }),
        dataPrivacyAndConsents: genericListing({
            microservice: 'accounts',
            createOnePath: null,
            updateOnePath: null,
            getAllPath: 'dataprivacyandconsents',
            getOnePath: null,
            deleteOnePath: null
        })
    }
});
