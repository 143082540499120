/**
 * EmailTemplate module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 */
const routes = [
    {
        path: '/:tenantId/tools/email-templates/email-template/:id',
        name: 'email-template',
        component: () => import(/* webpackChunkName: "emailtemplate" */ '@/modules/EmailTemplate/views/EmailTemplateView')
    }
];

export default routes;
