<template>
    <nav class="navigation-bar navbar navbar-expand-sm navbar-dark bg-dark fixed-top" id="navigation-primary">
        <div :class="navigationContainerClassName">
            <!-- BRAND -->
            <router-link class="navbar-brand" :to="{ name: 'tenantRoot' }">
                <logo></logo>
            </router-link>
            <div class="collapse navbar-collapse" v-if="applicationUiReady">
                <!-- MAIN MENU -->
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" v-bind:class="peopleRouteClass" :to="{ name: 'people-workspace' }">
                            <i class="far fa-user-circle"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'campaigns' }">
                            <i class="far fa-comments"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'tools' }">
                            <i class="fas fa-cog"></i>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <b-dropdown id="navigation-primary-global-create-dropdown"
                                    variant="link"
                                    toggle-class="text-decoration-none nav-link nav-link--global_create"
                                    no-caret
                                    right>
                            <template slot="button-content">
                                <i class="fas fa-plus"></i>
                                <!--
                                <i class="fas fa-plus-square"></i>
                                <i class="fas fa-plus-circle"></i>
                                <i class="far fa-plus-square"></i> NO
                                -->
                            </template>
                            <b-dropdown-item :to="{ name: 'person-edit' }" append>{{ $tc('entity Person', 1) }}</b-dropdown-item>
                            <b-dropdown-item :to="{ query: getGlobalCreatorRouteQuery('person') }"
                                             :title="$t('common_action_create_quick')"
                                             append>
                                {{ $tc('entity Person', 1) }} <span class="text-microcopy--75">{{ $t('quick') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item :to="{ query: getGlobalCreatorRouteQuery('emailCampaign') }">{{ $tc('entity Email Campaign', 1) }}</b-dropdown-item>
                            <b-dropdown-item :to="{ query: getGlobalCreatorRouteQuery('smsCampaign') }">{{ $tc('entity SMS Campaign', 1) }}</b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>

                <!-- GLOBAL SEARCH -->
                <form class="form-inline form-inline--global-search col py-0 px-0">
                    <input class="form-control border-0 no-btstrp-focus w-100"
                           :class="{ 'form-control--active-global-search': displaySearch }"
                           type="text"
                           :placeholder="$t('common_action_search') + '...'"
                           aria-label="Search"
                           v-model="searchTermValue"
                           @blur="onSearchBlur">
                    <span class="form-inline--global-search__cancel"
                          v-if="displaySearch"
                          @click="cancelSearch"><i class="fas fa-times text-black"></i></span>
                </form>

                <!-- USER PROFILE / ACCOUNT OPERATIONS AND SUPPORT MENU (DROP-DOWN) -->
                <b-dropdown id="navigation-primary-user-dropdown"
                            variant="link"
                            toggle-class="text-decoration-none text-success"
                            no-caret
                            right>
                    <template slot="button-content">
                        <avatar :username="profileName"
                                style="min-width: 34px;"
                                :size="34"
                                backgroundColor="#a8abad"
                                color="#212529"></avatar>
                    </template>
                    <b-dropdown-item disabled>
                        <p class="mb-0">{{ profileName | ellipsis(20) }}</p>
                        <p class="mb-0 text-muted small">{{ accountName | ellipsis(24) }}</p>
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <!--
                    <b-dropdown-item :to="{ name: 'getting-started' }">{{ $t('Getting Started') }}</b-dropdown-item>
                    -->
                    <!--
                    <b-dropdown-item>{{ $t('Support') }}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    -->
                    <b-dropdown-item :href="accountsUrl">{{ $t('Accounts') }}</b-dropdown-item>
                    <b-dropdown-item :href="profileUrl">{{ $t('Profile') }}</b-dropdown-item>
                    <b-dropdown-item :href="logoutUrl">{{ $t('common_action_logout') }}</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </nav>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import {
    CLOUTLAYER_WEBAPP_CORE_LOGOUT_URL,
    CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL,
    CLOUTLAYER_WEBAPP_CORE_PROFILE_URL
} from '@/common/config/config';

/**
 * App's Navigation Bar.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 18 July 2020
 */
export default {
    name: 'AppNavigationBar',
    computed: {
        ...mapGetters('application', {
            applicationUser: 'user',
            applicationTenantAccount: 'tenantAccount',
            applicationUiReady: 'uiReady',
            navigationContainerClassName: 'navigationContainerClassName'
        }),
        ...mapGetters('search', {
            searchTerm: 'searchTerm',
            displaySearch: 'displaySearch'
        }),
        searchTermValue: {
            get () {
                return this.searchTerm;
            },
            set (newValue) {
                // Promise's results are handled by store.
                this.$store.dispatch('search/typingSearchEvent', { searchTermParam: newValue })
                    .then(() => void 0).catch(() => void 0);
            }
        },
        peopleRouteClass () {
            let path = this.$route.path;
            let tenantId = this.$route.params.tenantId ? '/' + this.$route.params.tenantId : '';
            let toCompare = tenantId + '/people';
            return path.startsWith(toCompare) ? 'active' : '';
        },
        profileImageUrl () {
            return get(this.applicationUser, 'imageUrl', null);
            // @future imageUrl must be cleaned (e.g. empty string to null) - IT'S BACK-ENDS RESPONSIBILITY.
        },
        profileName () {
            const firstName = get(this.applicationUser, 'firstName', null);
            const lastName = get(this.applicationUser, 'lastName', null);
            const login = get(this.applicationUser, 'login', null);
            if ((firstName === null || firstName === '') && (lastName === null || lastName === '')) {
                return login;
            } else {
                let list = [];
                list.push(firstName);
                list.push(lastName);
                return list.join(' ');
            }
        },
        accountName () {
            return get(this.applicationTenantAccount, 'name', null);
        },
        accountsUrl () {
            return CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL;
        },
        profileUrl () {
            return CLOUTLAYER_WEBAPP_CORE_PROFILE_URL;
        },
        logoutUrl () {
            return CLOUTLAYER_WEBAPP_CORE_LOGOUT_URL;
        }
    },
    methods: {
        cancelSearch () {
            this.$store.dispatch('search/cancelSearch').then(() => void 0).catch(() => void 0);
        },
        onSearchBlur () {
            this.$store.dispatch('search/blurSearchEvent').then(() => void 0).catch(() => void 0);
        }
    }
};
</script>

<style lang="scss">
    // https://stackoverflow.com/questions/8622686/how-come-i-cant-remove-the-blue-textarea-border-in-twitter-bootstrap
    // https://gist.github.com/juliuscsurgo/4440047
    .no-btstrp-focus {
        outline: none;
        box-shadow:none !important;
    }

    .nav-link--global_create,
    .nav-link--global_create:hover,
    .nav-link--global_create:active,
    .nav-link--global_create:focus {
        // color: #cfffcf !important; // Default #46b881 Ideas: #bdf3d9
        color: #59a869 !important;
    }

    .navigation-bar {
        border-bottom: 0;

        .nav-link {
            font-size: 1.39em;
            display: block;
            padding: 0 1rem;
        }
    }

    .form-inline--global-search {
        margin-right: 1em;
        margin-left: 1em;

        .form-control {
            border-radius: 1.5em;
            background-color: #42474c;
            color: #b5b1af;
            caret-color: #42474c;
            transition: background-color 50ms linear, color 50ms linear;
        }
        .form-control::placeholder {
            color: #b5b1af;
        }

        .form-control:focus,
        .form-control--active-global-search {
            background-color: #fbf9f8;
            color: #212529;
        }
        .form-control:focus::placeholder,
        .form-control--active-global-search {
            color: #42474c;
        }
    }
    .form-inline--global-search__cancel {
        position: absolute;
        top: 7px;
        right: 16px;
        cursor: pointer;
    }

    // Fixes for Vue.js Bootstrap.
    #navigation-primary-global-create-dropdown,
    #navigation-primary-user-dropdown {

        // @help : If you are using avatar (vue-avatar), use this to avoid the text-underline effect.
        .btn,
        .btn:hover,
        .btn:active,
        .btn:focus {
            text-decoration: none;

            .vue-avatar--wrapper {
                // Ideas
                // background-color: rgb(114, 119, 125);
                // color: rgb(33, 37, 41);

                span {
                    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    font-size: 14px;
                    line-height: 34px;
                    font-weight: 700;
                }
                span,
                span:hover {
                    text-decoration: none;
                }
            }
        }
    }

    // Reset padding for user button.
    #navigation-primary-user-dropdown__BV_toggle_ {
        padding: 0 !important;
        // padding-left: 0;
        // padding-right: 0;
    }

    // RESPONSIVENESS //////////////////////

    // Fixes for SM devices.
    @media (max-width: 767.98px) {

        #navigation-primary {

            // Hide logo to make more space for nav icons, search and profile avatar.
            .navbar-brand {
                display: none !important;
            }

            // Smaller padding for nav icons.
            .nav-link {
                padding: 0 0.5em 0 0 !important;
            }
            // Remove padding from last nav icon.
            .nav-item:last-child {

                .nav-link {
                    padding: 0 0 0 0 !important;
                }
            }
        }
    }

    // Ensure that navigation does not collapse.
    #navigation-primary {

        .navbar-nav {
            -webkit-box-orient: horizontal !important;
            -webkit-box-direction: normal !important;
            -ms-flex-direction: row !important;
            flex-direction: row !important;
        }
        .navbar-collapse {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -ms-flex-preferred-size: auto !important;
            flex-basis: auto !important;
        }
    }
</style>

<i18n>
{
    "en": {
        "quick": "quick"
    },
    "el": {
        "quick": "γρήγορη"
    }
}
</i18n>
