/****************************************************************************************************
 * Property dynamic SchemaDefinition reusable store sub-module.
 * Used by modules which manage SchemaDefinition itself.
 *
 * @author Dimitris Gkoulis
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';
import { ModelDefinitionService } from '@/common/services/api.service';

export default function () {
    return {
        namespaced: true,
        state: {
            schemaDefinitionName: null,
            schemaDefinition: null,
            schemaDefinitionError: null,
            schemaDefinitionLoading: false
        },
        getters: {
            schemaDefinitionName (state) {
                return state.schemaDefinitionName;
            },
            schemaDefinition (state) {
                return state.schemaDefinition;
            }
            // @help add the rest if necessary.
        },
        actions: {
            getSchemaDefinition ({ state, commit }) {
                commit('setSchemaDefinitionLoading', true);

                if (typeof state.schemaDefinitionName !== 'string') {
                    return Promise.reject(new Error('schemaDefinitionName must be a string!'));
                }
                if (state.schemaDefinitionName.trim() === '') {
                    return Promise.reject(new Error('schemaDefinitionName must not be blank!'));
                }

                // Get SchemaDefinition.
                return ModelDefinitionService.getModelDefinition(state.schemaDefinitionName)
                    .then(({ data }) => {
                        // Set new data and reset error.
                        commit('setSchemaDefinition', data.schemaDefinition);
                        commit('setSchemaDefinitionError', null);
                        return Promise.resolve(data.schemaDefinition);
                    })
                    .catch((error) => {
                        commit('setSchemaDefinition', null);
                        commit('setSchemaDefinitionError', error);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        commit('setSchemaDefinitionLoading', false);
                    });
            },
            resetSchemaDefinitionDynamicSubModule ({ commit }) {
                commit('setSchemaDefinitionName', null);
                commit('setSchemaDefinition', null);
                commit('setSchemaDefinitionError', null);
                commit('setSchemaDefinitionLoading', false);
            }
        },
        mutations: {
            setSchemaDefinitionName (state, data) {
                Vue.set(state, 'schemaDefinitionName', data);
            },
            setSchemaDefinition (state, data) {
                Vue.set(state, 'schemaDefinition', data);
            },
            setSchemaDefinitionError (state, data) {
                Vue.set(state, 'schemaDefinitionError', data);
            },
            setSchemaDefinitionLoading (state, data) {
                Vue.set(state, 'schemaDefinitionLoading', data);
            }
        }
    };
};
