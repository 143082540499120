<template>
    <div v-show="active"
         :class="{ 'ElementLoading--FullScreen': isFullScreen, 'ElementLoading--FullView': isFullView }"
         :style="{ backgroundColor }"
         ref="ElementLoadingRef"
         class="ElementLoading-Overlay">
        <div class="ElementLoading-Spinner">
            <slot name="default">
                <component :is="spinner"
                           :color="color"
                           :size="`${size}px`"
                           :duration="`${duration}s`"/>
            </slot>
            <div v-if="text.length" :style="{ color, ...textStyle }">{{ text }}</div>
        </div>
    </div>
    <!-- <transition name="fade"></transition> -->
</template>

<script>
import Loaders from './Loaders';

/**
 * ElementLoading : provides loading state to parent element.
 *
 * @future implementations:
 * - Create clv-spinner and do the following:
 * -- MAKE SPIN.JS THE ONLY IMPLEMENTATION IN HERE. IMPROVE, DYNAMIC PROPS/OPTS, ETC.
 * -- use spin.js to create complex state indicators - in buttons, sub-views, etc.
 * -- do not stop loading effect while page is reloading (works only with spin.js)
 * -- make props 'isFullScreen', 'isFullView' and 'lockParent' reactive (listen to changes)
 * -- add a nice but stable fade effect (on full screen only!). It's actually very easy.
 * -- remove :size usage. Replace with size as string (tiny, mini, small, medium, large, extra large)
 * ---- tiny is for buttons, inputs, etc.
 *
 * @author Dimitris Gkoulis
 * @createdAt 2019
 * @lastModifiedAt 8 November 2020
 */
export default {
    name: 'ElementLoading',
    components: Loaders,
    props: {
        active: Boolean,
        spinner: {
            type: String,
            default: 'SpinSpinner'
        },
        text: {
            type: String,
            default: ''
        },
        textStyle: {
            type: Object,
            default: function () {
                return {};
            }
        },
        color: {
            type: String,
            default: '#69717d' // Previously was #1070ca
        },
        isFullScreen: {
            type: Boolean,
            default: false
        },
        isFullView: {
            type: Boolean,
            default: false
        },
        lockParent: {
            type: Boolean,
            default: false
        },
        lockBody: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: 'rgba(255, 255, 255, 1)'
        },
        size: {
            type: Number,
            default: 40
        },
        duration: {
            type: String,
            default: '0.7'
        }
    },
    mounted () {
        this.$watch('active', {
            handler: function () {
                this.sync();
            },
            immediate: true
        });
    },
    methods: {
        sync () {
            if (this.active === true) {
                this.$refs.ElementLoadingRef.parentNode.classList.add('ElementLoading-Parent');

                if (this.lockParent === true) {
                    this.$refs.ElementLoadingRef.parentNode.classList.add('ElementLoading-Parent--Locked');
                } else {
                    this.$refs.ElementLoadingRef.parentNode.classList.remove('ElementLoading-Parent--Locked');
                }

                if (this.lockBody === true) {
                    document.body.classList.add('ElementLoading-Body--Locked');
                } else {
                    // Remove lock-scroll class from body.
                    document.body.className = document.body.className.replace('ElementLoading-Body--Locked', '');
                    // document.body.classList.remove('ElementLoading-Body--Locked');
                }
            } else {
                // Remove all.
                this.$refs.ElementLoadingRef.parentNode.classList.remove('ElementLoading-Parent');
                this.$refs.ElementLoadingRef.parentNode.classList.remove('ElementLoading-Parent--Locked');
            }
        }
    }
};
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>

<style lang="scss">
    .ElementLoading-Overlay {
        position: absolute;
        z-index: 1400;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity .3s;
    }
    .ElementLoading-Spinner {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center
    }

    .ElementLoading--FullScreen {
        position: fixed;
        top: 0;
    }
    .ElementLoading--FullView {
        position: fixed;

        /* @see set in styles.scss  */
        /* top: 60px; */
    }

    .ElementLoading-Parent {
        /*position: relative;*/
        /*position: relative !important;*/
    }
    .ElementLoading-Body--Locked {
    }
    .ElementLoading-Parent--Locked,
    .ElementLoading-Body--Locked {
        overflow: hidden;
    }

    .ElementLoading-Placeholder {
        position: relative;
        min-height: 200px;
    }
</style>
