import cloneDeep from 'lodash/cloneDeep';
import i18n from '@/common/plugins/i18n';

/**
 * This object holds information about each Activity Type.
 *
 * Fields:
 * family: helps us categorize Activity Types.
 * label: Activity Type but human readable.
 * faIconClass: font awesome icon class (it can contain color modifiers)
 * includeInPerson: a flag that declared if this Activity Type should be included in Person Activity Wall.
 * propertyName: Some Activity Types are associated with Person properties change events.
 *               This field declares the Person property (human readable).
 *
 * NOTICE: Some getters of ActivityTypeLogic filter ana map this object in order to cover specific business needs.
 */
const ActivityTypesDetails = {
    'NOT_SPECIFIED': {
        family: 'general',
        label: 'Not Specified',
        faIconClass: '',
        includeInPerson: false
    },
    'DECLARED_BUT_NOT_SPECIFIED': {
        family: 'general',
        label: 'Declared But Not Specified',
        faIconClass: '',
        includeInPerson: false
    },

    'PERSON_CREATE': {
        family: 'person',
        label: 'Person Create',
        faIconClass: 'fas fa-user',
        includeInPerson: true
    },
    'PERSON_UPDATE': {
        family: 'person',
        label: 'Person Update',
        faIconClass: 'fas fa-user-edit',
        includeInPerson: true
    },
    'PERSON_DELETE': {
        family: 'person',
        label: 'Person Delete',
        faIconClass: 'fas fa-user-times',
        includeInPerson: false
    },

    'PROPERTY_CHANGE_EMAIL': {
        family: 'person_property',
        label: 'Email Change',
        faIconClass: 'fas fa-user-edit',
        propertyName: 'Email',
        includeInPerson: true
    },
    'PROPERTY_CHANGE_PHONE_NUMBER': {
        family: 'person_property',
        label: 'Phone Number Change',
        faIconClass: 'fas fa-user-edit',
        propertyName: 'Phone Number',
        includeInPerson: true
    },

    'PROPERTY_CHANGE_SUPPORT_STATUS': {
        family: 'person_property_politics',
        label: 'Support Status Change',
        faIconClass: 'fa fa-hand-peace',
        propertyName: 'Support Status',
        includeInPerson: true
    },

    'PROPERTY_CHANGE_SUPPORT_LEVEL': {
        family: 'person_property_politics',
        label: 'Support Level Change',
        faIconClass: 'fa fa-hand-peace',
        propertyName: 'Support Level',
        includeInPerson: true
    },
    'PROPERTY_CHANGE_SUPPORT_INFERRED_LEVEL': {
        family: 'person_property_politics',
        label: 'Inferred Support Level Change',
        faIconClass: 'fa fa-hand-peace',
        propertyName: 'Inferred Support Level',
        includeInPerson: true
    },

    'PROPERTY_CHANGE_VOLUNTEERING_STATUS': {
        family: 'person_property_politics',
        label: 'Volunteering Status Change',
        faIconClass: 'fa fa-hands-helping',
        propertyName: 'Volunteering Status',
        includeInPerson: true
    },
    'PROPERTY_CHANGE_MOBILIZER': {
        family: 'person_property_politics',
        label: 'Mobilizer Change',
        faIconClass: 'fas fa-dot-circle',
        propertyName: 'Mobilizer',
        includeInPerson: true
    },

    'PROPERTY_CHANGE_TAXONOMY_PRIORITY': {
        family: 'person_property',
        label: 'Priority Change',
        faIconClass: 'fas fa-user-tag',
        propertyName: 'Priority',
        includeInPerson: true
    },
    'PROPERTY_CHANGE_TAXONOMY_LABELS': {
        family: 'person_property',
        label: 'Labels Change',
        faIconClass: 'fas fa-user-tag',
        propertyName: 'Labels',
        includeInPerson: true
    },

    'EMAIL_CAMPAIGN_SEND': {
        family: 'campaign_email',
        label: 'Email Campaign Send',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_REJECT': {
        family: 'campaign_email',
        label: 'Email Campaign Reject',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_RENDERING_FAILURE': {
        family: 'campaign_email',
        label: 'Email Campaign Rendering Failure',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_DELIVERY': {
        family: 'campaign_email',
        label: 'Email Campaign Delivery',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_OPEN': {
        family: 'campaign_email',
        label: 'Email Campaign Open',
        faIconClass: 'far fa-envelope-open',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_CLICK': {
        family: 'campaign_email',
        label: 'Email Campaign Click',
        faIconClass: 'far fa-envelope-open',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_BOUNCE': {
        family: 'campaign_email',
        label: 'Email Campaign Bounce',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'EMAIL_CAMPAIGN_COMPLAINT': {
        family: 'campaign_email',
        label: 'Email Campaign Complaint',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },

    'CAMPAIGN_WEB_LINK_CLICK': {
        family: 'campaign',
        label: 'Campaign Web Link Click',
        faIconClass: 'fas fa-link',
        includeInPerson: true
    },

    'SMS_CAMPAIGN_EVENT': {
        family: 'campaign_sms',
        label: 'SMS Campaign',
        faIconClass: 'far fa-comment',
        includeInPerson: true
    },

    'COMMUNICATION_SUBSCRIPTION': {
        family: 'communication',
        label: 'Communication Subscription',
        faIconClass: 'fas fa-map-signs',
        includeInPerson: true
    },

    'NOTE': {
        family: 'organizing',
        label: 'Note',
        faIconClass: 'far fa-edit',
        includeInPerson: true
    },
    'EMAIL_LOG': {
        family: 'organizing',
        label: 'Logged Email',
        faIconClass: 'far fa-envelope',
        includeInPerson: true
    },
    'CALL_LOG': {
        family: 'organizing',
        label: 'Logged Call',
        faIconClass: 'fas fa-phone',
        includeInPerson: true
    },
    'TASK': {
        family: 'organizing',
        label: 'Task',
        faIconClass: 'fas fa-tasks',
        includeInPerson: true
    }
};

/**
 * Resolves the given argument and returns the Activity Type or null.
 */
function resolveActivityOrType (activityOrType) {
    if (activityOrType === null || activityOrType === undefined) return null;
    if (typeof activityOrType === 'string') return activityOrType.trim();
    if (typeof activityOrType === 'object' && typeof activityOrType['type'] === 'string') return activityOrType['type'];
    return null;
}

/**
 * Returns the requested field value from object in ActivityTypesDetails.
 */
function getActivityTypeStringDetail (activityOrType, field, defaultValue = '') {
    const activityType = resolveActivityOrType(activityOrType);
    if (activityType === null) return defaultValue;
    const value = ActivityTypesDetails[activityType][field];
    return typeof value === 'string' ? value : defaultValue;
}

/**
 * Returns the human readable label based on ActivityType enum value (aka key) or the fallback.
 * Fallback is set by calling methods based on their needs.
 */
function getTranslatedLabelOrFallback (key, fallback = 'Unknown Activity Type') {
    const translated = i18n.t(key);
    if (translated === key) return fallback;
    return translated;
}

const ActivityTypeLogic = {
    // Do not use 'humanReadableType' as i18n args. Use activity type instead.
    // 'humanReadableType' aka 'label' field in each activity type.
    // IMPORTANT: This label is a fallback value.
    // Real labels are provided via Vue i18n plugin.
    humanReadableType (activityOrType) {
        return getActivityTypeStringDetail(activityOrType, 'label', '');
    },
    faIconClass (activityOrType, defaultValue = '') {
        return getActivityTypeStringDetail(activityOrType, 'faIconClass', '');
    },
    propertyName (activityOrType, defaultValue = 'field') {
        return getActivityTypeStringDetail(activityOrType, 'propertyName', '');
    },

    /**
     * Returns the details for each ActivityType that should be included in Person view and its sub-views.
     * This method returns cloned items and thus it's safe to edit them directly.
     */
    activityTypesForPersonActivity () {
        const politicsAddOnEnabled = window.cs3w.get('politicsAddOnEnabled', false);
        const activityTypes = []; // The new list.
        for (const [key, value] of Object.entries(ActivityTypesDetails)) {
            // Check if current activityType must not be included.
            if (value.includeInPerson === false) continue;
            if (value.family === 'person_property_politics' && politicsAddOnEnabled === false) continue;

            // Clone value and set required fields.
            const clonedValue = cloneDeep(value);
            clonedValue.key = key;
            clonedValue.checked = true;
            clonedValue.label = getTranslatedLabelOrFallback(key, clonedValue.value); // Translate label or return the English one.

            activityTypes.push(clonedValue);
        }
        return activityTypes;
    }
};

/**
 * Provides business logic, UI details and other information for Activity Types.
 */
export default ActivityTypeLogic;
