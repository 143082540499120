/****************************************************************************************************
 * Element UI Validation Utilities.
 * Provides convenient and quick access to common el-form field rules.
 *
 * @future implementations
 * - Add type validators in order to not repeat code. e.g. typeStringValidator, typeListValidator, etc
 *   Giati allios tha prepei na to elegxoume se kathe validator. Se kapoia einai ypoxreotiko na elegxoume ton tipo.
 *   An kai einai ypervoliko mias oi formes spania allazoyn ton typo.
 *   Mporei na vazoyn null to opoio to kaliptei to requiredValidator
 * - Add important regex patterns to a global config file. E.g. PropertyDefinition name, enum etc.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import isLength from 'validator/es/lib/isLength';
import i18n from '@/common/plugins/i18n';
import ValidationUtils from './validation.utils';

function getTranslatedMessage (key, data = null) {
    return i18n.t(key, data);
}

const ElValidationUtils = {};

// FOR DEVELOPMENT PURPOSES!
ElValidationUtils.alwaysInvalid = function () {
    let validator = (rule, value, callback) => {
        callback(new Error('INVALID FOR DEVELOPMENT PURPOSES!'));
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

ElValidationUtils.requiredValidator = function () {
    return {
        required: true,
        message: getTranslatedMessage('el_val_form_field_required'),
        trigger: 'blur'
    };
};

ElValidationUtils.lengthValidator = function (min = null, max = null) {
    let key = '';
    if (min !== null && max !== null) key = 'el_val_form_field_length_min_max';
    else if (min !== null) key = 'el_val_form_field_length_min';
    else if (max !== null) key = 'el_val_form_field_length_max';

    let validator = (rule, value, callback) => {
        if (typeof value !== 'string') {
            callback(new Error(getTranslatedMessage('el_val_form_field_must_be_string')));
        } else {
            const valid = isLength(value.trim(), {
                min: min,
                max: max
            });
            if (valid) {
                callback();
            } else {
                callback(new Error(getTranslatedMessage(key, {
                    min: min,
                    max: max
                })));
            }
        }
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

ElValidationUtils.lengthValidatorOriginal = function (min = null, max = null) {
    let key = '';
    if (min !== null && max !== null) key = 'el_val_form_field_length_min_max';
    else if (min !== null) key = 'el_val_form_field_length_min';
    else if (max !== null) key = 'el_val_form_field_length_max';

    return {
        min: min,
        max: max,
        message: getTranslatedMessage(key, {
            min: min,
            max: max
        }),
        trigger: 'blur'
    };
};

ElValidationUtils.nonBlankValidator = function () {
    const validator = (rule, value, callback) => {
        if (typeof value !== 'string') callback(new Error(getTranslatedMessage('el_val_form_field_must_be_string')));
        else if (value.trim() === '') callback(new Error(getTranslatedMessage('el_val_form_field_not_blank')));
        else callback();
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

ElValidationUtils.emailValidator = function () {
    const validator = (rule, value, callback) => {
        if (ValidationUtils.validateEmail(value)) callback();
        else callback(new Error(getTranslatedMessage('el_val_form_field_email')));
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

ElValidationUtils.optionalValidator = function () {
    let validator = (rule, value, callback) => {
        callback();
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

ElValidationUtils.upperAsciiValidator = function () {
    let validator = (rule, value, callback) => {
        let regex = RegExp('^[A-Z]*$');
        if (regex.test(value)) callback();
        else callback(new Error(getTranslatedMessage('el_val_form_field_upper_ascii')));
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

/**
 * Validator for SchemaDefinition, PropertyGroup, PropertyDefinition name.
 *
 * @createdAt 26 July 2020
 */
ElValidationUtils.dfPgPdNameValidator = function () {
    let validator = (rule, value, callback) => {
        let regex = RegExp('^[a-zA-Z][a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$');
        if (regex.test(value)) callback();
        else callback(new Error(getTranslatedMessage('el_val_form_field_df_pg_pd_name')));
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

/**
 * Checks if the value exists in a list.
 *
 * If you need to ensure that value has a specific type include a validator right before this one.
 *
 * @beta
 * @createdAt 28 July 2020
 */
ElValidationUtils.dfPgPdUniqueNameInListV0Validator = function (list = []) {
    let validator = (rule, value, callback) => {
        if (!Array.isArray(list)) {
            callback(); // valid.
            return;
        }
        if (list.length === 0) {
            callback(); // valid.
            return;
        }

        if (list.includes(value)) callback(new Error(getTranslatedMessage('el_val_form_field_df_pg_pd_name_string_must_be_unique_in_list')));
        else callback();
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

/**
 * Checks if value is a list.
 *
 * THEORETICALLY IT NEVER RETURNS FALSE.
 * REQUIRED TO CALL SAFELY OTHER LIST OPERATIONS AND VALIDATIONS.
 *
 * @createdAt 29 July 2020
 */
ElValidationUtils.mustBeListValidator = function () {
    let validator = (rule, value, callback) => {
        if (!Array.isArray(value)) callback(new Error('Input must be an array'));
        else callback();
    };

    return {
        validator: validator,
        trigger: 'blur'
    };
};

export default ElValidationUtils;
