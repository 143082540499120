/****************************************************************************************************
 * UI sub-module for EmailTemplate store module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 ****************************************************************************************************/

import Vue from 'vue';
import RandomUtils from '@/common/utils/random.utils';

const state = {
    activeComponentCommonKey: null
};

const getters = {
    activeComponentCommonKey (state) {
        return state.activeComponentCommonKey;
    }
};

const actions = {
    resetUiSubModule ({ commit }) {
        commit('resetActiveComponentCommonKey');
    }
};

const mutations = {
    updateActiveComponentCommonKey (state) {
        Vue.set(state, 'activeComponentCommonKey', RandomUtils.getUniqueId());
    },
    resetActiveComponentCommonKey (state) {
        Vue.set(state, 'activeComponentCommonKey', null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
