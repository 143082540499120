import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import get from 'lodash/get';
import AxiosErrorTransformer from 'vuetiful/src/transformers/axios-error.transformer';
import store from '@/store/index';

const AppApiService = {
    init () {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.withCredentials = true;

        // Intercept failed responses and transform error response.
        Vue.axios.interceptors.response.use(null, (error) => {
            // @future : How to handle 401 or other errors from NUtitlities and MJML Service?
            const statusCode = get(error, 'response.status', 0);
            if (statusCode === 401) {
                // @future : Implement 401 interceptor.
                // Ideas:
                // - Clear cookies - logout - flush everything and redirect to core?
                // - Or clean some of the data and display lock page.
            }

            // Transform error.
            error = AxiosErrorTransformer.flatten(error);
            error = AxiosErrorTransformer.setMessages(error);

            // eslint-disable-next-line
            return Promise.reject(error);
        });

        // Before each request, add Tenant ID.
        Vue.axios.interceptors.request.use(function (config) {
            const tenantAccountId = store.getters['application/tenantAccountId'];
            if (tenantAccountId != null) {
                config.headers['X-Tenant-ID'] = tenantAccountId;
            }
            return config;
        }, null);
    }
};

export default AppApiService;
