<template>
    <div class="people-person-creator position-relative">
        <element-loading :active="loading" :lock-parent="true"></element-loading>

        <!-- SERVICE QUOTA -->
        <div class="alert C-Alert alert-warning" v-if="applicationQuotasUiEntityQluDisabled('person')">
            <p class="mb-0">{{ applicationQuotasUiEntityQluVTooltipObject('person').content }}</p>
        </div>

        <!-- FORM ERRORS -->
        <div class="alert C-Alert alert-danger"
             role="alert"
             v-for="(err, index) in formErrors"
             :key="index">{{ $t(err) }}</div>

        <!-- THE FORM -->
        <el-form :model="fields"
                 ref="newPersonForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 @submit.native.prevent>
            <el-form-item :label="$t('First Name')" prop="firstName" for="firstNameGcPersonCreator">
                <el-input id="firstNameGcPersonCreator" v-model="fields.firstName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Last Name')" prop="lastName" for="lastNameGcPersonCreator">
                <el-input id="lastNameGcPersonCreator" v-model="fields.lastName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Email')" prop="email" for="emailGcPersonCreator">
                <el-input id="emailGcPersonCreator" v-model="fields.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Phone Number')" prop="phoneNumber" for="phoneNumberGcPersonCreator">
                <el-input id="phoneNumberGcPersonCreator" v-model="fields.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item class="mt-2 mb-0">
                <div class="alert alert-warning C-Alert" v-if="gdprAddOnEnabled">
                    <b-form-checkbox id="gdprActionCheckboxPersonCreator"
                                     name="gdprActionCheckboxPersonCreator"
                                     v-model="fields.lawfulBasisExists"
                                     :value="true"
                                     :unchecked-value="false">
                        {{ $t('person_create_edit_gdpr_statement_checkbox') }}
                    </b-form-checkbox>
                </div>
                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="submitForm(false)"
                        :disabled="disableCreate">{{ $t('common_action_create') }}</button>
                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="submitForm(true)"
                        :disabled="disableCreate">{{ $t('common_action_create_and_edit') }}</button>
                <!--
                <button type="button"
                        class="btn btn-primary btn-block"
                        @click="submitForm(true)"
                        :disabled="applicationQuotasUiEntityQluDisabled('person')">{{ $t('common_action_create_and_view') }}</button>
                -->
                <button type="button"
                        class="btn btn-secondary btn-block"
                        @click="cancelForm"
                        v-if="displayCancel">{{ $t('common_action_cancel') }}</button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PersonService } from '@/common/services/api.service';

/**
 * Person Creator (quick creator).
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 10 November 2020 (fixed bug)
 */
export default {
    name: 'PersonCreator',
    props: {
        displayCancel: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            gdprAddOnEnabled: window.cs3w.get('gdprAddOnEnabled', false),

            loading: false,
            formErrors: [],
            fields: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                lawfulBasisExists: false
            }
        };
    },
    computed: {
        ...mapGetters('application', {
            applicationQuotasUiEntityQluDisabled: 'quotasUiEntityQluDisabled',
            applicationQuotasUiEntityQluVTooltipObject: 'quotasUiEntityQluVTooltipObject'
        }),
        disableCreate () {
            return this.applicationQuotasUiEntityQluDisabled('person') ||
                (this.gdprAddOnEnabled === true && this.fields.lawfulBasisExists === false);
        }
    },
    methods: {
        // Form Helpers //////////
        validatePersonForm () {
            return !(this.fields.lastName.trim() === '' &&
                this.fields.phoneNumber.trim() === '' &&
                this.fields.email.trim() === '');
        },
        preparePersonCreateDTO () {
            return {
                id: null,
                properties: {
                    firstName: this.fields.firstName,
                    lastName: this.fields.lastName,
                    phoneNumber: this.fields.phoneNumber,
                    email: this.fields.email,
                    lawfulBasisExists: this.fields.lawfulBasisExists
                }
            };
        },

        // Async Operations //////////
        savePerson (redirect) {
            let dto = this.preparePersonCreateDTO();
            PersonService.createPerson(dto)
                .then(({ data }) => {
                    // Send signal to people module to refresh results.
                    this.$store.dispatch('people/searchPeople');
                    // Update EntityQlu for this entity.
                    // 2020-03-13-@future-reduce-scattered-calls.
                    this.$store.dispatch('application/quotasFindOneEntityQlu', 'person');
                    // Reset form.
                    this.resetForm();
                    this.toastDefaultWithPush('Person created', 'common_action_display', {
                        name: 'person',
                        params: {
                            id: data.id
                        }
                    });
                    // Send signal to active listeners to perform their next actions.
                    this.$emit('create-success', data.id);
                    if (redirect === true) {
                        this.$router.push({ name: 'person-edit', params: { id: data.id } });
                    }
                })
                .catch((reason) => {
                    // @future TEMPORARY Solution.
                    // Back-end: send email/phoneNumber and ID. Check if it's valid. And then check if it's unique.
                    let dataDetail = ''; // dataDetail can be missing.
                    if (typeof reason.dataDetail === 'string') {
                        dataDetail = reason.dataDetail;
                    }
                    if (dataDetail.startsWith('Person with email ')) {
                        const message = this.$t('temp_email_not_unique', {
                            email: this.fields.email
                        });
                        this.toastError(message, false);
                    } else if (dataDetail.startsWith('Person with phoneNumber ')) {
                        const message = this.$t('temp_phoneNumber_not_unique', {
                            phoneNumber: this.fields.phoneNumber
                        });
                        this.toastError(message, false);
                    } else {
                        this.toastErrorFromError(reason);
                    }
                    // Send signal to active listeners to perform their next actions.
                    this.$emit('create-error', true);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        // Form Controls //////////
        submitForm (redirect) {
            // Reset errors.
            this.formErrors = [];
            // Start loader.
            this.loading = true;
            // Check if form is valid.
            if (!this.validatePersonForm()) {
                this.formErrors.push('person_creator_validation_at_least_one_identifying');
                this.loading = false;
                return;
            }
            // Validate form (via Vue.js) and continue to action.
            this.$refs['newPersonForm'].validate((valid) => {
                if (valid) {
                    this.savePerson(redirect);
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newPersonForm'].resetFields();
        },
        cancelForm () {
            this.resetForm();
            // Send signal to active listeners to perform their next actions.
            this.$emit('cancel', true);
        }
    }
};
</script>

<i18n>
{
    "en": {
        "temp_email_not_unique": "Email {email} is already assigned to another Person",
        "temp_phoneNumber_not_unique": "Phone Number {phoneNumber} is already assigned to another Person"
    },
    "el": {
        "temp_email_not_unique": "Το email {email} έχει ήδη ανατεθεί σε άλλο Άτομο",
        "temp_phoneNumber_not_unique": "Ο αριθμός τηλεφώνου {phoneNumber} έχει ήδη ανατεθεί σε άλλο Άτομο"
    }
}
</i18n>
