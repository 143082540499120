<template>
    <span v-bind:class="details.cssClass">{{ $t(details.label) }}</span>
</template>

<script>
export default {
    name: 'CampaignStatusTag',
    props: {
        status: {
            type: String,
            required: false,
            default: 'UNKNOWN'
        }
    },
    computed: {
        details () {
            switch (this.status) {
            case 'DRAFT':
                return {
                    cssClass: 'gk-tag gk-tag-status-draft',
                    label: 'DRAFT'
                };
            case 'IN_PROGRESS':
                return {
                    cssClass: 'gk-tag gk-tag-cyan',
                    label: 'IN_PROGRESS'
                };
            case 'COMPLETED':
                return {
                    cssClass: 'gk-tag gk-tag-status-completed',
                    label: 'COMPLETED'
                };
            default:
                return {
                    // Do not display unknown / none status.
                    cssClass: 'gk-tag gk-tag-status-none d-none',
                    label: 'NONE'
                };
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .gk-tag {
        border-radius: 4px;
        font-size: 0.66rem;
        font-weight: 700;
        text-transform: uppercase;
    }
</style>

<i18n>
{
    "en": {
        "DRAFT": "Draft",
        "IN_PROGRESS": "In Progress",
        "COMPLETED": "Completed"
    },
    "el": {
        "DRAFT": "Πρόχειρο",
        "IN_PROGRESS": "Σε Εξέλιξη",
        "COMPLETED": "Ολοκληρώθηκε"
    }
}
</i18n>
