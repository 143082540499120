/****************************************************************************************************
 * Validations.
 *
 * @future Implement a mechanism like this with dynamic Identifying properties in back-end too.
 *
 * @author Dimitris Gkoulis
 * @createdAt 21 July 2020
 ****************************************************************************************************/

import get from 'lodash/get';

const IdentifyingPersonProperties = [
    'lastName',
    'email',
    'phoneNumber'
];

const state = {};

const getters = {};

const actions = {
    validationsBeforeSave ({ state }) {
        const results = {
            identifyingInformationMissing: false
        };

        // VALIDATION 1: Check if Identifying information is missing.
        let identifyingPersonPropertiesMissingCount = 0;
        for (const identifyingProperty of IdentifyingPersonProperties) {
            const identifyingPropertyValue = get(state.properties, identifyingProperty + '.workingValue', null);
            if (typeof identifyingPropertyValue !== 'string') {
                identifyingPersonPropertiesMissingCount++;
                continue;
            }
            if (identifyingPropertyValue.trim() === '') {
                identifyingPersonPropertiesMissingCount++;
            }
        }
        if (identifyingPersonPropertiesMissingCount >= IdentifyingPersonProperties.length) {
            results.identifyingInformationMissing = true;
        }

        if (Object.values(results).some(item => item)) return Promise.reject(results);
        else return Promise.resolve(results);
    }
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
