/****************************************************************************************************
 * Application context store sub-module.
 *
 * Holds information about the current context (window, device, user-agent, and other kind of information).
 * Some of the properties are computed by application initialization service.
 *
 * @future implementation:
 * - Add everything, calculate everything
 *   (user-agent, referer, IP, window, device, screen, navigational information, touch capabilities, etc).
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 * @lastModifiedAt 5 November 2020
 ****************************************************************************************************/

const state = {
    /**
     * The tenantId from the URL parameters.
     */
    contextTenantId: null,

    /**
     * A flag that declares is the current devices is possibly a mobile device.
     */
    contextDeviceIsMobile: false,

    /**
     * A flag that declares if the current device has small screen (< 768).
     */
    contextDeviceHasSmallScreen: false
};

const getters = {
    contextTenantId (state) {
        return state.contextTenantId;
    },
    contextDeviceIsMobile (state) {
        return state.contextDeviceIsMobile;
    },
    contextDeviceHasSmallScreen (state) {
        return state.contextDeviceHasSmallScreen;
    }
};

const actions = {};

const mutations = {
    contextTenantIdSet (state, data) {
        if (data === null) {
            state.contextTenantId = null;
            return;
        }
        if (typeof data !== 'string') {
            state.contextTenantId = null;
            return;
        }
        state.contextTenantId = data.trim();
    },
    contextDeviceIsMobileSet (state, data) {
        if (typeof data !== 'boolean') {
            state.contextDeviceIsMobile = false;
            return;
        }
        state.contextDeviceIsMobile = data;
    },
    contextDeviceHasSmallScreenSet (state, data) {
        if (typeof data !== 'boolean') {
            state.contextDeviceHasSmallScreen = false;
            return;
        }
        state.contextDeviceHasSmallScreen = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
