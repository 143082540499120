/****************************************************************************************************
 * API Service
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 2018
 * @lastModifiedAt 8 April 2021
 ****************************************************************************************************/

import Vue from 'vue';
import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import UrlService from './url.service';

const DefaultConfiguration = {
    withCredentials: true,
    crossDomain: true
};

/**
 * Parent API Service for all specific domain services.
 */
const ApiService = {
    // setHeader () {},
    head (url, configParam = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configParam);
        return Vue.axios.head(url, config);
    },
    get (url, configParam = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configParam);
        return Vue.axios.get(url, config);
    },
    delete (url, configParam = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configParam);
        return Vue.axios.delete(url, config);
    },
    post (url, data = null, configParam = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configParam);
        return Vue.axios.post(url, data, config);
    },
    put (url, data = null, configParam = {}) {
        const config = Object.assign(cloneDeep(DefaultConfiguration), configParam);
        return Vue.axios.put(url, data, config);
    }
};

export default ApiService;

export const AccountService = {
    /** Returns the current user login or 'anonymous' if no user is authenticated */
    getAccount () {
        let path = 'user-accounts/whoami';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    whoAmI () {
        let path = 'auth/whoami';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

/**
 * Generic BREAD API Consumer Microservices.
 * NOTICE: It's more a helper than a generic implementation.
 *
 * Rules:
 * - URL must not contain params
 * - URL must not have appended the trailing slash '/'.
 *
 * @future : Think about:
 * - The default getAll should contain some search mechanisms (simple queries, simple filters, etc)
 *   OR back-end should provide a standard way to query/search data for all domains.
 * - Simple search (consumer)
 * - Advanced search (consumer)
 * - Analytics or count (consumer)
 */
export const GenericBreadService = {
    createOne (url, instance) {
        return ApiService.post(url, instance);
    },
    updateOne (url, instance) {
        return ApiService.put(url, instance);
    },
    getAll (url, page = 0, size = 10, sortField = 'id', sortType = 'desc') {
        if (sortField === null) {
            sortField = 'id';
        }
        if (sortType === null) {
            sortType = 'desc';
        }
        // @future : Make some validations.
        const sort = sortField + ',' + sortType;
        const config = {
            params: {
                page: page,
                size: size,
                sort: sort
            }
        };
        return ApiService.get(url, config);
    },
    getOne (url, id) {
        url = url + '/' + id;
        return ApiService.get(url);
    },
    deleteOne (url, id) {
        url = url + '/' + id;
        return ApiService.delete(url);
    },
    getMany (url, query) {
        return ApiService.post(url, query);
    }
};

// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
/**
 * A service designed for generic store modules that manage various domains.
 *
 * NOTICES:
 * 1) Avoid using this service outside of generic store modules.
 * 2) "microservice" argument is no longer used and will be removed shortly!
 */
export const GenericBreadMicroserviceConsumer = {
    // noinspection JSUnusedLocalSymbols
    createOne (path, instance, microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.createOne(url, instance);
    },
    // noinspection JSUnusedLocalSymbols
    updateOne (path, instance, microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.updateOne(url, instance);
    },
    // noinspection JSUnusedLocalSymbols
    getAll (path, page = 0, size = 10, sortField = 'id', sortType = 'desc', microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.getAll(url, page, size, sortField, sortType);
    },
    // noinspection JSUnusedLocalSymbols
    getOne (path, id, microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.getOne(url, id);
    },
    // noinspection JSUnusedLocalSymbols
    deleteOne (path, id, microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.deleteOne(url, id);
    },
    // noinspection JSUnusedLocalSymbols
    getMany (path, query, microservice = 'leader') {
        const url = UrlService.urlForKernelVersionedApi(path);
        return GenericBreadService.getMany(url, query);
    }
};

export const AnalyticsFieldValueCounterService = {
    retrieve (name) {
        let path = 'analytics/metrics/field-value-counters/' + name;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const ActivityService = {
    getOneActivity (id) {
        let path = 'activities/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url, id);
    },
    deleteOneActivity (id) {
        let path = 'activities/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url, id);
    },
    retrievePersonActivityFeed (personId = null, activityTypeList = [], page = 0, size = 10, sortField = 'id', sortType = 'desc') {
        if (sortField === null) {
            sortField = 'id';
        }
        if (sortType === null) {
            sortType = 'desc';
        }
        // @future : Make some validations.
        let sort = sortField + ',' + sortType;
        // Build activity type params.
        let activityTypeListParam = '';
        if (activityTypeList !== null) {
            if (activityTypeList.length > 0) {
                for (let activityType of activityTypeList) {
                    activityTypeListParam = activityTypeListParam + '&typeList=' + activityType;
                }
            }
        }
        // Build path.
        let path = '_activities/person-activity-feed' +
            '?personId=' + personId +
            activityTypeListParam +
            // '&activityTypeList=' + activityTypeList +
            '&page=' + page +
            '&size=' + size +
            '&sort=' + sort;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    createNote (dto) {
        let path = '_activities/note';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    },
    createEmailLog (dto) {
        let path = '_activities/emaillog';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    },
    createCallLog (dto) {
        let path = '_activities/calllog';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    },
    createTask (dto) {
        let path = '_activities/task';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    },
    updateNote (id, dto) {
        let path = '_activities/note/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, dto);
    },
    updateEmailLog (id, dto) {
        let path = '_activities/emaillog/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, dto);
    },
    updateCallLog (id, dto) {
        let path = '_activities/calllog/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, dto);
    },
    updateTask (id, dto) {
        let path = '_activities/task/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, dto);
    },
    changeTaskCompletion (id, completed) {
        let literal = !!completed === true ? 'completed' : 'uncompleted';
        let path = '_activities/task/' + id + '/' + literal;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url);
    }
};

export const InternalIdentifierService = {
    getInternalIdentifier (foreignIdentifier, foreignType) {
        let path = 'internal-identifiers/get' +
            '?foreignIdentifier=' + foreignIdentifier +
            '&foreignType=' + foreignType;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    bulkGetInternalIdentifier (foreignIdentifiers, foreignType) {
        let listParam = '';
        if (foreignIdentifiers !== null) {
            if (foreignIdentifiers.length > 0) {
                for (let item of foreignIdentifiers) {
                    listParam = listParam + '&foreignIdentifiers=' + item;
                }
                listParam = listParam.substring(1);
                listParam = '?' + listParam;
            }
        }
        let path = 'internal-identifiers/get/bulk' +
            listParam +
            '&foreignType=' + foreignType;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    searchInternalIdentifier (foreignName, foreignType, page = 0, size = 10) {
        let path = 'internal-identifiers/search' +
            '?foreignName=' + foreignName +
            '&foreignType=' + foreignType +
            '&page=' + page +
            '&size=' + size;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const WorkspaceService = {
    createWorkspace (instance) {
        let path = 'workspaces';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updateWorkspace (instance) {
        let path = 'workspaces';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getWorkspace (id) {
        let path = 'workspaces/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deleteWorkspace (id) {
        let path = 'workspaces/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    }
};

export const WorkspacePeopleService = {
    countWorkspace (id, communicationSubscriptionType = null) {
        let path = 'workspaces/' + id + '/count';
        if (communicationSubscriptionType != null) {
            path = path + '?communicationSubscriptionType=' + communicationSubscriptionType;
        }
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const PersonService = {
    createPerson (instance) {
        let path = 'people';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updatePerson (instance) {
        let path = 'people';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getPerson (id) {
        let path = 'people/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deletePerson (id) {
        let path = 'people/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    },
    searchPeopleAdvanced (searchDetails, withAggregation = true) {
        let path = 'people/search/advanced';
        if (withAggregation === false) path = path + '/no-aggregation';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, searchDetails);
    }
};

export const PersonLifeEventService = {
    createPersonLifeEvent (instance) {
        const path = 'person-life-events';
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updatePersonLifeEvent (instance) {
        const path = 'person-life-events';
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getPersonLifeEvent (id) {
        const path = 'person-life-events/' + id;
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deletePersonLifeEvent (id) {
        const path = 'person-life-events/' + id;
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    },
    getPersonLifeEvents (personId, page = 0, size = 100, sortField = 'date.fullDate', sortType = 'desc') {
        const paginationParams = `?page=${page}&size=${size}&sort=${sortField},${sortType}`;
        const path = 'person-life-events/person/' + personId + paginationParams;
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

// DEPRECATED in favor of PolyglotPersistence. Check 'webapp'.
const ENTITY_SPACE_FOR_PERSON = 'people';
export const ModelDefinitionService = {
    getModelDefinition (id) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}`);
        return ApiService.get(url);
    },
    updateDisplayOrders (id, dto) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/display-orders`);
        return ApiService.post(url, dto);
    },

    savePropertyGroup (id, dto) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/property-groups`);
        return ApiService.post(url, dto);
    },
    deletePropertyGroup (id, name) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/property-groups/${name}`);
        return ApiService.delete(url);
    },

    createPropertyDefinition (id, dto) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/property-definitions`);
        return ApiService.post(url, dto);
    },
    updatePropertyDefinition (id, dto) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/property-definitions`);
        return ApiService.put(url, dto);
    },
    deletePropertyDefinition (id, name) {
        let url = UrlService.urlForKernelVersionedApi(`ppa/entity-spaces/${ENTITY_SPACE_FOR_PERSON}/entity-types/${id}/property-definitions/${name}`);
        return ApiService.delete(url);
    }
};

export const IdentityProfileService = {
    getIdentityProfile (id) {
        let path = 'identityprofiles/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const CommunicationSubscriptionService = {
    createCommunicationSubscription (instance) {
        let path = 'communicationsubscriptions';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    }
};

export const BroadcasterService = {
    createBroadcaster (instance) {
        let path = 'broadcasters';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updateBroadcaster (instance) {
        let path = 'broadcasters';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getBroadcaster (id) {
        let path = 'broadcasters/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deleteBroadcaster (id) {
        let path = 'broadcasters/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    }
};

export const SmsCampaignService = {
    createSmsCampaign (instance) {
        let path = 'smscampaigns';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updateSmsCampaign (instance) {
        let path = 'smscampaigns';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getSmsCampaign (id) {
        let path = 'smscampaigns/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deleteSmsCampaign (id) {
        let path = 'smscampaigns/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    },
    sendSmsCampaign (smsCampaign) {
        let path = '_smscampaigns/send';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, smsCampaign);
    }
};

export const EmailCampaignService = {
    createEmailCampaign (instance) {
        let path = 'emailcampaigns';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updateEmailCampaign (instance) {
        let path = 'emailcampaigns';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getEmailCampaign (id) {
        let path = 'emailcampaigns/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deleteEmailCampaign (id) {
        let path = 'emailcampaigns/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    },
    sendEmailCampaign (emailCampaign) {
        let path = '_emailcampaigns/send';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, emailCampaign);
    },
    sendTestEmailCampaign (emailCampaign) {
        let path = '_emailcampaigns/send-test';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, emailCampaign);
    },
    previewEmailCampaignBodyHtml (id) {
        let path = 'emailcampaigns/' + id + '/preview/html';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const EmailTemplateService = {
    createEmailTemplate (instance) {
        let path = 'emailtemplates';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, instance);
    },
    updateEmailTemplate (instance) {
        let path = 'emailtemplates';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getEmailTemplate (id) {
        let path = 'emailtemplates/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    deleteEmailTemplate (id) {
        let path = 'emailtemplates/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    },
    getAllEmailTemplatesByName (name, page = 0, size = 10) {
        let path = '_emailtemplates/search/by-name';
        path = path + '?name=' + name +
            '&page=' + page +
            '&size=' + size +
            '&sort=id,desc';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const SenderEmailAddressService = {
    createSenderEmailAddress (emailAddress) {
        let path = 'sender-email-addresses/verify/' + emailAddress;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url);
    },
    deleteSenderEmailAddress (id) {
        let path = 'sender-email-addresses/' + id;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.delete(url);
    }
};

export const QuotasService = {
    findOneEntityQlu (entityName) {
        let path = 'quotas/entities/' + entityName;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    getAllEntityQlu () {
        let path = 'quotas/entities';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const UnitBalanceService = {
    getUnitBalance (tenantAccountId, unitType) {
        let path = 'unit-balances/' + tenantAccountId + '/' + unitType;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    getAllUnitBalances (tenantAccountId) {
        let path = 'unit-balances/' + tenantAccountId;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const ResourceSimpleIndexService = {
    searchResources (term, resourceTypeList = [], page = 0, size = 10, sortField = 'id', sortDirection = 'desc') {
        // noinspection JSUnusedGlobalSymbols
        const config = {
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            params: {
                term: term,
                resourceTypes: resourceTypeList,
                page: page,
                size: size,
                sort: sortField + ',' + sortDirection
            }
        };
        let path = 'resource-simple-index/search';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url, config);
    }
};

export const LegacyGpUiUserRecordService = {
    saveLegacyGpUiUserRecord (dto) {
        const path = 'legacy-gp-ui-user-records';
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.post(url, dto);
    },
    ensuredGetLegacyGpUiUserRecord (key) {
        const path = 'legacy-gp-ui-user-records/' + key + '/ensured';
        const url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const NUtilitiesService = {
    buildMjml (mjml) {
        if (typeof mjml === 'string') {
            try {
                mjml = JSON.parse(mjml);
            } catch (reason) {
                mjml = null;
            }
        }
        let data = {
            mjml: mjml
        };
        let url = UrlService.urlForKernel('services/nutilities/api/mjml/build');
        return ApiService.post(url, data);
    },
    convertHtmlToPlainText (html) {
        if (html === null || html === undefined) {
            html = '';
        }
        let data = {
            html: html
        };
        let url = UrlService.urlForKernel('services/nutilities/api/html/convert-to-plain-text');
        return ApiService.post(url, data);
    }
};

export const TenantAccountUiService = {
    checkTenantAccountExistence (tenantId) {
        // tenantId is tenantAccountAlias.
        let path = 'ui/tenant-accounts/' + tenantId + '/check-existence';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    checkTenantAccountUserAccess (tenantId) {
        // tenantId is tenantAccountAlias.
        let path = 'ui/tenant-accounts/' + tenantId + '/check-user-access';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const TenantAccountService = {
    getTenantAccount (tenantId) {
        let path = 'tenant-accounts/' + tenantId;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    },
    updateTenantAccountInformation (tenantAccountUpdateDTO) {
        let path = 'tenant-accounts';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, tenantAccountUpdateDTO);
    },
    setTenantAccountAddOn (tenantId, data) {
        let path = 'tenant-accounts/' + tenantId + '/add-ons';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, data);
    }
};

export const DataPrivacyAndConsentService = {
    saveDataPrivacyAndConsent (instance) {
        let path = 'data-privacy-and-consents';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getDataPrivacyAndConsent (tenantId, languageKey) {
        let path = '_data-privacy-and-consents/' + tenantId + '/' + languageKey;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const BrandingService = {
    saveBranding (instance) {
        let path = 'brandings';
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.put(url, instance);
    },
    getBranding (tenantId, languageKey) {
        let path = '_brandings/' + tenantId + '/' + languageKey;
        let url = UrlService.urlForKernelVersionedApi(path);
        return ApiService.get(url);
    }
};

export const StorageStoredObjectService = {
    uploadContentStoredObject (file, fileName = null) {
        let formData = new FormData();
        if (typeof fileName !== 'string') {
            fileName = file.name;
        }
        formData.append('file', file);
        const url = UrlService.urlForMicroServiceStorageApi('stored-objects/content-uploads');
        return ApiService.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
                // ...formData.getHeaders()
            },
            withCredentials: true,
            crossDomain: true
            // @future do we need them?
            // timeout: 100000,
            // maxContentLength: 3000000,
        });
    }
};
