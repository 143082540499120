/****************************************************************************************************
 * Views Routes.
 *
 * @author Dimitris Gkoulis
 * @createdAt 10 November 2020
 ****************************************************************************************************/

const ViewsRoutes = [
    {
        path: '/:tenantId/people/workspaces',
        name: 'workspaces',
        component: () => import(/* webpackChunkName: "workspaces" */ '@/views/WorkspacesView.vue')
    },
    {
        path: '/:tenantId/campaigns',
        name: 'campaigns',
        component: () => import(/* webpackChunkName: "campaigns" */ '@/views/CampaignsView.vue')
    },
    {
        path: '/:tenantId/campaigns/broadcasters',
        name: 'broadcasters',
        component: () => import(/* webpackChunkName: "broadcasters" */ '@/views/BroadcastersView.vue')
    },
    // Tools //////////
    {
        path: '/:tenantId/tools',
        name: 'tools',
        component: () => import(/* webpackChunkName: "tools" */ '@/views/ToolsView.vue')
    },
    {
        path: '/:tenantId/tools/email-templates',
        name: 'email-templates',
        component: () => import(/* webpackChunkName: "emailtemplates" */ '@/views/EmailTemplatesView.vue')
    },
    {
        path: '/:tenantId/tools/sender-email-addresses',
        name: 'sender-email-addresses',
        component: () => import(/* webpackChunkName: "senderemailaddresses" */ '@/views/SenderEmailAddressesView.vue')
    },
    {
        path: '/:tenantId/tools/subscriptions-portal',
        name: 'subscriptions-portal',
        component: () => import(/* webpackChunkName: "subscriptionsportal" */ '@/views/SubscriptionsPortalView.vue')
    },
    {
        path: '/:tenantId/tools/account',
        name: 'tenant-account-update-information',
        component: () => import(/* webpackChunkName: "tenantaccountupdateinformation" */ '@/views/TenantAccountUpdateInformationView.vue')
    },
    {
        path: '/:tenantId/tools/brandings',
        name: 'branding',
        component: () => import(/* webpackChunkName: "brandings" */ '@/views/BrandingView.vue')
    },
    {
        path: '/:tenantId/tools/data-privacy-and-consent',
        name: 'data-privacy-and-consent',
        component: () => import(/* webpackChunkName: "dataprivacyandconsent" */ '@/views/DataPrivacyAndConsentView.vue')
    },
    {
        path: '/:tenantId/tools/quotas',
        name: 'quotas',
        component: () => import(/* webpackChunkName: "quotas" */ '@/views/QuotasView.vue')
    },
    {
        path: '/:tenantId/tools/credits',
        name: 'credits',
        component: () => import(/* webpackChunkName: "creditsbalance" */ '@/views/CreditsView.vue')
    },
    {
        path: '/:tenantId/tools/limitations',
        name: 'limitations',
        component: () => import(/* webpackChunkName: "limitations" */ '@/views/LimitationsView.vue')
    },
    {
        path: '/:tenantId/ui',
        name: 'ui',
        component: () => import(/* webpackChunkName: "ui" */ '@/views/UiView.vue')
    }
];

export default ViewsRoutes;
