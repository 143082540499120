/****************************************************************************************************
 * Pagination sub-module of people store module.
 ****************************************************************************************************/

// State.
const state = {};

// Getters.
const getters = {
    paginationPagePlusOne (state) {
        return state.workspaceWorking.searchDetails.page + 1;
    },
    paginationSize (state) {
        return state.workspaceWorking.searchDetails.size;
    }
};

// Actions.
const actions = {
    paginationChangeSize ({ dispatch, commit }, data) {
        if (typeof data !== 'number') return Promise.reject(new Error('pagination size must be a number!'));
        if (data !== 30 && data !== 50) return Promise.reject(new Error('pagination size must be either 30 or 50!'));
        commit('setPaginationPage', 0); // important to reset page
        commit('setPaginationSize', data);
        return dispatch('searchPeople'); // Search people.
    },
    paginationChangeFromCarbon ({ dispatch, commit }, { start, length }) {
        // @future Validate 'start' and 'length'.
        // @future 'length' should be either 30 or 50.
        const size = length;
        const page = (start - 1) / size;
        commit('setPaginationPage', page);
        commit('setPaginationSize', size);
        return dispatch('searchPeople'); // Search people.
    },
    paginationChangeFromBootstrap ({ dispatch, commit }, { page }) {
        // @future Validate 'page'.
        let actualPage = page - 1;
        commit('setPaginationPage', actualPage);
        // commit('setPaginationSize', size);
        return dispatch('searchPeople'); // Search people.
    }
};

// Mutations.
const mutations = {
    setPaginationPage (state, data) {
        if (typeof data !== 'number') return;
        // auto-correct. We choose to proceed with search but with the right value.
        if (data < 0) data = 0;
        state.workspaceWorking.searchDetails.page = data;
    },
    setPaginationSize (state, data) {
        if (typeof data !== 'number') return;
        // auto-correct. We choose to proceed with search but with the right value.
        if (data !== 30 && data !== 50) data = 30;
        state.workspaceWorking.searchDetails.size = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
