<template>
    <div class="EmailCampaignCreator position-relative">
        <element-loading :active="loading" :lock-parent="true"></element-loading>
        <el-form :model="fields"
                 :rules="fieldsRules"
                 ref="newInstanceForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 @submit.native.prevent>
            <el-form-item :label="$t('Name')" prop="name" for="nameGcEmailCampaignCreator">
                <el-input id="nameGcEmailCampaignCreator" v-model="fields.name"></el-input>
            </el-form-item>
            <!--
            <el-form-item :label="$t('Description')" prop="description">
                <el-input type="textarea" v-model="fields.description"></el-input>
            </el-form-item>
            -->
            <el-form-item class="mt-2 mb-4">
                <button type="button" class="btn btn-primary btn-block" @click="submitForm(true)">{{ $t('common_action_create') }}</button>
                <button type="button" class="btn btn-secondary btn-block" @click="cancelForm" v-if="displayCancel">{{ $t('common_action_cancel') }}</button>
            </el-form-item>
        </el-form>
        <p class="text-muted small mb-0">* {{ $t('email_campaign_creator_notice_1') }}</p>
        <p class="text-muted small mb-0">* {{ $t('email_campaign_creator_notice_2') }}</p>
        <p class="text-muted small mb-0">* {{ $t('email_campaign_creator_notice_3') }}</p>
    </div>
</template>

<script>
import { EmailCampaignService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import DomainModel from '@/common/logic/domain-models';

/**
 * EmailCampaign Creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 14 July 2020
 */
export default {
    name: 'EmailCampaignCreator',
    props: {
        displayCancel: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            loading: false,
            fields: {
                name: '',
                description: ''
            },
            fieldsRules: {
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 100)
                ],
                description: []
            }
        };
    },
    methods: {
        prepareInstanceFromForm () {
            let instance = DomainModel.getEmailCampaign();
            instance.name = this.fields.name;
            instance.description = this.fields.description;
            instance.settings.languageKey = window.cs3w.get('defaultLanguageKey');
            return instance;
        },
        saveInstance (redirect) {
            this.loading = true;
            let instanceToSave = this.prepareInstanceFromForm();
            EmailCampaignService.createEmailCampaign(instanceToSave)
                .then(({ data }) => {
                    // Update campaigns listing (in case user is in this view).
                    this.$store.dispatch('campaigns/getData');
                    // Reset form.
                    this.resetForm();
                    this.toastDefault('Email Campaign created');
                    // Emit data to receiver components.
                    this.$emit('create-success', data.id);
                    // Redirect if needed to the Email Campaign.
                    if (redirect === true) {
                        this.$router.push({ name: 'email-campaign', params: { id: data.id } });
                    }
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                    this.$emit('create-error', true);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // Form Controls //////////
        submitForm (redirect) {
            this.loading = true;
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance(redirect);
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newInstanceForm'].resetFields();
        },
        cancelForm () {
            this.resetForm();
            this.$emit('cancel', true);
        }
    }
};
</script>
