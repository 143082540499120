<template>
    <div class="SimpleStateNotFound" :class="{ 'SimpleStateNotFound--Default': defaultStyle, 'SimpleStateNotFound--View': forView }">
        <slot name="title">
            <p class="mb-0 size-4">
                <slot name="titleText">
                    <span>{{ $t('ssnotfound_title_1') }}</span>
                </slot>
            </p>
        </slot>
        <slot name="description"></slot>
        <slot name="action"></slot>
    </div>
</template>

<script>
/**
 * Simple not-found state (usually for a single resource).
 *
 * @author Dimitris Gkoulis
 * @createdAt 19 July 2020
 */
export default {
    name: 'SimpleStateNotFound',
    props: {
        defaultStyle: {
            type: Boolean,
            default: true,
            required: false
        },
        forView: {
            type: Boolean,
            default: false,
            required: false
        }
    }
};
</script>

<style lang="scss">
    .SimpleStateNotFound--Default {
        position: relative;
        margin-top: 1.5rem;
    }
    .SimpleStateNotFound--View {
        text-align: center;
        margin-top: 0;
        padding-top: 3rem;
    }
</style>

<i18n>
{
    "en": {
        "ssnotfound_title_1": "Not found"
    },
    "el": {
        "ssnotfound_title_1": "Δε βρέθηκε"
    }
}
</i18n>
