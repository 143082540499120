<template>
    <div class="global-select position-relative" v-if="foreignType">

        <!-- LOADER -->
        <element-loading :active="initializing" :size="24" background-color="rgba(255, 255, 255, 0.8)"></element-loading>

        <!-- ERROR -->
        <p class="text-danger" v-if="error">{{ error.messageForUser }}</p>

        <!-- SELECT -->
        <el-select class="g-width-100"
                   :size="elemSize"

                   v-model="selectedItemIdentifier"
                   :disabled="elemDisabled"
                   :value-key="'foreignIdentifier'"
                   :clearable="elemClearable"
                   :placeholder="elemPlaceholder"
                   filterable
                   remote
                   :loading="fetching"
                   :loading-text="elemLoadingText"
                   :no-match-text="elemNoMatchText"
                   :no-data-text="elemNoDataText"
                   :remote-method="fetch"
                   v-on:change="onSelectChange">

            <!-- OPTIONS -->
            <el-option v-for="result in results"
                       :key="result.foreignIdentifier"
                       :label="result.foreignName"
                       :value="result.foreignIdentifier">{{ result.foreignName }}</el-option>

        </el-select>
        <!-- END SELECT -->

    </div>
</template>

<script>
import { InternalIdentifierService } from '@/common/services/api.service';
import i18n from '@/common/plugins/i18n';

function initialTranslation (param) {
    if (i18n.te(param)) {
        return i18n.t(param);
    }
    return param;
}

/**
 * Global Select
 *
 * - Event : 'select' - sends the id of the selected item.
 * - Event : 'select-with-data' - sends the selected object.
 * Important: This ('select-with-data') must never be used for persistence or to make important decisions!
 *
 * @author Dimitris Gkoulis
 * @createdAt 2018
 * @lastModifiedAt 14 November 2020
 */
export default {
    name: 'GlobalSelect',
    props: {
        // UI Element Properties //////////
        elemSize: {
            type: String,
            required: false,
            default: ''
        },
        elemDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        elemClearable: {
            type: Boolean,
            required: false,
            default: true
        },
        elemPlaceholder: {
            type: String,
            required: false,
            default: function () {
                return initialTranslation('Please enter a keyword');
            }
        },
        elemLoadingText: {
            type: String,
            required: false,
            default: function () {
                return (initialTranslation('common_state_loading') + '...');
            }
        },
        elemNoMatchText: {
            type: String,
            required: false,
            default: function () {
                return initialTranslation('No matching data');
            }
        },
        elemNoDataText: {
            type: String,
            required: false,
            default: function () {
                return initialTranslation('No data');
            }
        },
        // Data Properties //////////
        foreignIdentifier: {
            type: String,
            default: null,
            required: false
        },
        foreignType: {
            type: String,
            default: null,
            required: true
        }
    },
    data () {
        return {
            // Selected Item //////////
            selectedItemIdentifier: null,
            // Loaders //////////
            initializing: false,
            fetching: false,
            // Request Results //////////
            results: [],
            // Errors //////////
            error: null // This is a shared error for presentation purposes.
        };
    },
    mounted () {
        this.initialize();
    },
    methods: {
        fetch (query) {
            // Validate query (user input).
            if (query === null) {
                return;
            }
            // if (query.trim() === '') {
            //     return;
            // }
            this.fetchRemote(query);
        },
        fetchRemote (query) {
            // Start fetching loader.
            this.fetching = true;
            // Fetch data.
            InternalIdentifierService.searchInternalIdentifier(query, this.foreignType)
                .then(({ data }) => {
                    this.results = data;
                    this.error = null;
                })
                .catch((error) => {
                    this.results = [];
                    this.error = error;
                })
                .finally(() => {
                    this.fetching = false;
                });
        },
        initialize () {
            // Check foreignIdentifier. If it is null, there is no need to initialize at all.
            if (this.foreignIdentifier === null) {
                // In this case, pre-fetch some results to improve the UX.
                this.fetchRemote(''); // Fetch some results (by default on mount).
                return;
            }
            // Start initializing loader.
            this.initializing = true;
            InternalIdentifierService.getInternalIdentifier(this.foreignIdentifier, this.foreignType)
                .then(({ data }) => {
                    // Set results and reset error.
                    this.results.push(data);
                    this.selectedItemIdentifier = data.foreignIdentifier;
                    this.error = null;

                    // Send parent the selected item.
                    this.$emit('select-with-data', data);
                })
                .catch((error) => {
                    this.selectedItemIdentifier = null;
                    this.error = error;

                    // Send parent the selected item which is null.
                    this.$emit('select-with-data', null);
                })
                .finally(() => {
                    this.initializing = false;
                });
        },
        // Event Handlers //////////
        onSelectChange () {
            if (this.selectedItemIdentifier === null) {
                this.$emit('select', null);
                this.$emit('select-with-data', null);
                return;
            }
            if (this.selectedItemIdentifier.trim() === '') {
                this.$emit('select', null);
                this.$emit('select-with-data', null);
                return;
            }
            this.$emit('select', this.selectedItemIdentifier);

            // Find the object.
            let objToSend = null;
            for (let i = 0; i < this.results.length; i++) {
                // Get the item.
                let item = this.results[i];

                // Check item.
                if (item === null) {
                    continue;
                }

                // Check if this item is the selected.
                if (item.foreignIdentifier === this.selectedItemIdentifier) {
                    objToSend = item;
                    break;
                }
            }
            this.$emit('select-with-data', objToSend);
        }
    }
};
</script>
