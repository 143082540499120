<template>
    <div class="BroadcasterCreator position-relative">
        <element-loading :active="stateIsChanging" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found v-if="notFound" key="broadcaster-not-found">
            <div class="mt-2" slot="action">
                <p class="mb-0 cursor-pointer text-primary" @click="cancelFormWithoutReset">
                    <i class="fas fa-chevron-left"></i> {{ $t('common_action_close') }}
                </p>
            </div>
        </simple-state-not-found>

        <div v-else key="broadcaster-form">
            <el-form :model="fields"
                     :rules="fieldsRules"
                     ref="newInstanceForm"
                     :label-position="'top'"
                     class="cltlr-form-label-style-1"
                     @submit.native.prevent>
                <el-form-item :label="$t('Name')" prop="name" for="nameGcBroadcasterCreator">
                    <el-input id="nameGcBroadcasterCreator" v-model="fields.name"></el-input>
                </el-form-item>
                <!--
                <el-form-item :label="$t('Description')" prop="description" for="descriptionGcBroadcasterCreator">
                    <el-input id="descriptionGcBroadcasterCreator" type="textarea" v-model="fields.description"></el-input>
                </el-form-item>
                -->
                <el-form-item :label="$t('From Name')" prop="emailCampaignFromName" for="emailCampaignFromNameGcBroadcasterCreator">
                    <el-input id="emailCampaignFromNameGcBroadcasterCreator" v-model="fields.emailCampaignFromName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('From Email')" prop="emailCampaignFromEmail" for="emailCampaignFromEmailGcBroadcasterCreator">
                    <el-input id="emailCampaignFromEmailGcBroadcasterCreator" v-model="fields.emailCampaignFromEmail"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Reply To Name')" prop="emailCampaignReplyToName" for="emailCampaignReplyToNameGcBroadcasterCreator">
                    <el-input id="emailCampaignReplyToNameGcBroadcasterCreator" v-model="fields.emailCampaignReplyToName"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Reply To Email')" prop="emailCampaignReplyToEmail" for="emailCampaignReplyToEmailGcBroadcasterCreator">
                    <el-input id="emailCampaignReplyToEmailGcBroadcasterCreator" v-model="fields.emailCampaignReplyToEmail"></el-input>
                </el-form-item>
                <el-form-item :label="$t('SMS Name')" prop="smsCampaignFromName" for="smsCampaignFromNameGcBroadcasterCreator">
                    <el-input id="smsCampaignFromNameGcBroadcasterCreator" v-model="fields.smsCampaignFromName"></el-input>
                </el-form-item>
                <el-form-item class="mt-2 mb-0">
                    <button type="button" class="btn btn-primary btn-block" @click="submitForm(false)" v-html="type === 1 ? $t('common_action_save') : $t('common_action_create')"></button>
                    <button type="button" class="btn btn-secondary btn-block" @click="cancelForm" v-if="displayCancel">{{ $t('common_action_cancel') }}</button>
                    <button type="button" class="btn btn-link btn-block text-danger" @click="deleteForm" v-if="displayDeleteButton">{{ $t('common_action_delete') }}</button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { BroadcasterService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * Broadcaster Creator.
 *
 * @author Dimitris Gkoulis
 * @createdAt February 2020
 * @lastModifiedAt 14 July 2020
 */
export default {
    name: 'BroadcasterCreator',
    props: {
        id: {
            type: String,
            default: null,
            required: false
        },
        hideDeleteButton: {
            type: Boolean,
            default: false,
            required: false
        },
        displayCancel: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data () {
        return {
            type: 0, // 0 is create, 1 is edit.
            notFound: false,
            fetching: false,
            loading: false,
            deleting: false,
            fields: {
                id: null,
                name: '',
                description: '',
                emailCampaignFromName: '',
                emailCampaignFromEmail: '',
                emailCampaignReplyToName: '',
                emailCampaignReplyToEmail: '',
                smsCampaignFromName: ''
            },
            fieldsRules: {
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100)
                ],
                description: [],
                emailCampaignFromName: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 255)
                ],
                emailCampaignFromEmail: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 255),
                    ElValidationUtils.emailValidator()
                ],
                emailCampaignReplyToName: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 255)
                ],
                emailCampaignReplyToEmail: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 255),
                    ElValidationUtils.emailValidator()
                ],
                smsCampaignFromName: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 10),
                    ElValidationUtils.upperAsciiValidator()
                ]
            }
        };
    },
    computed: {
        stateIsChanging () {
            return this.loading || this.fetching || this.deleting;
        },
        displayDeleteButton () {
            return !this.hideDeleteButton && this.type === 1;
        }
    },
    beforeMount () {
        if (this.id) {
            this.fetching = true;
            BroadcasterService.getBroadcaster(this.id).then(({ data }) => {
                this.fields.id = data.id;
                this.fields.name = data.name;
                this.fields.description = data.description;
                this.fields.emailCampaignFromName = data.emailCampaignFromName;
                this.fields.emailCampaignFromEmail = data.emailCampaignFromEmail;
                this.fields.emailCampaignReplyToName = data.emailCampaignReplyToName;
                this.fields.emailCampaignReplyToEmail = data.emailCampaignReplyToEmail;
                this.fields.smsCampaignFromName = data.smsCampaignFromName;

                // Set type to update.
                this.type = 1;
            }).catch((ignoredError) => {
                this.notFound = true;
            }).finally(() => {
                this.fetching = false;
            });
        } else {
            // Set type to create.
            this.type = 0;
        }
    },
    methods: {
        prepareInstanceFromForm () {
            return cloneDeep(this.fields);
        },
        saveInstance (redirect) {
            this.loading = true;
            let instanceToSave = this.prepareInstanceFromForm();
            let methodToCall = 'createBroadcaster';
            if (this.type === 1) {
                methodToCall = 'updateBroadcaster';
            }
            BroadcasterService[methodToCall](instanceToSave)
                .then(({ data }) => {
                    // Update campaigns listing (in case user is in this view).
                    this.$store.dispatch('broadcasters/getData');
                    if (methodToCall === 'createBroadcaster') {
                        // Update EntityQlu for this entity.
                        // 2020-03-13-@future-reduce-scattered-calls.
                        this.$store.dispatch('application/quotasFindOneEntityQlu', 'broadcaster');
                    }
                    // Reset form.
                    this.resetForm();
                    if (methodToCall === 'createBroadcaster') this.toastDefault('Broadcaster created');
                    else if (methodToCall === 'updateBroadcaster') this.toastDefault('Broadcaster updated');
                    // Emit data to receiver components.
                    this.$emit('create-success', data.id);
                    // Redirect if needed to the Email Campaign.
                    if (redirect === true) {
                        this.$router.push({ name: 'broadcaster', params: { id: data.id } });
                    }
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                    this.$emit('create-error', true);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deleteInstance () {
            this.deleting = true;
            BroadcasterService.deleteBroadcaster(this.id).then(() => {
                // Update campaigns listing (in case user is in this view).
                this.$store.dispatch('broadcasters/getData');
                // Update EntityQlu for this entity.
                // 2020-03-13-@future-reduce-scattered-calls.
                this.$store.dispatch('application/quotasFindOneEntityQlu', 'broadcaster');
                // Reset form.
                this.resetForm();
                this.toastDefault('Broadcaster deleted');
                // Emit data to receiver components.
                this.$emit('delete-success', null);
            }).catch((reason) => {
                this.toastErrorFromError(reason);
                this.$emit('delete-error', true);
            }).finally(() => {
                this.deleting = false;
            });
        },
        // Form Controls //////////
        submitForm (redirect) {
            this.loading = true;
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance(redirect);
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['newInstanceForm'].resetFields();
        },
        cancelForm () {
            this.resetForm();
            this.$emit('cancel', true);
        },
        cancelFormWithoutReset () {
            this.$emit('cancel', true);
        },
        deleteForm () {
            let resp = confirm(this.$t('Delete Broadcaster?'));
            if (!resp) {
                return;
            }
            this.deleteInstance();
        }
    }
};
</script>
