export default {};

// Authorities //////////
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_CLTLR_PREFIX = 'ROLE_CLTLR_';
export const ROLE_CLTLR_SUFFIX = '_TENANT';
export const ROLE_CLTLR_USER_SUFFIX = '_TENANT_USER';
export const ROLE_CLTLR_ADMIN_SUFFIX = '_TENANT_ADMIN';

// Web Applications URLs
export const CLOUTLAYER_WEBAPP_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_URL;
export const CLOUTLAYER_WEBAPP_CORE_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_CORE_URL;
export const CLOUTLAYER_WEBAPP_CORE_LOGIN_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/login';
export const CLOUTLAYER_WEBAPP_CORE_LOGOUT_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/logout';
export const CLOUTLAYER_WEBAPP_CORE_SIGN_UP_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/sign-up';
export const CLOUTLAYER_WEBAPP_CORE_PROFILE_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/profile';
export const CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/accounts';
export const CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL;
export const CLOUTLAYER_WEBAPP_BASE_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_BASE_URL;

// Back-end URLs
export const CLOUTLAYER_KERNEL_BASE_URL = process.env.VUE_APP_CLOUTLAYER_KERNEL_BASE_URL;
export const CLOUTLAYER_KERNEL_API_VERSION = process.env.VUE_APP_CLOUTLAYER_KERNEL_API_VERSION;

export const SILENT_TRANSLATION_WARNINGS = (process.env.VUE_APP_SILENT_TRANSLATION_WARNINGS === 'true');
export const SILENT_FALLBACK_WARNINGS = (process.env.VUE_APP_SILENT_FALLBACK_WARNINGS === 'true');
