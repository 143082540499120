/****************************************************************************************************
 * Operations sub-module for EmailCampaignContent store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 ****************************************************************************************************/

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { EmailCampaignService } from '@/common/services/api.service';

const state = {
    emailCampaignBase: null,
    emailCampaign: null,
    // State Indicators //////////
    emailCampaignGetting: false,
    emailCampaignUpdating: false,
    // Status //////////
    emailCampaignNotFound: false,
    // Errors //////////
    emailCampaignGetError: null,
    emailCampaignUpdateError: null
};

const getters = {
    emailCampaign (state) {
        return state.emailCampaign;
    },
    // State Indicators //////////
    emailCampaignGetting (state) {
        return state.emailCampaignGetting;
    },
    emailCampaignUpdating (state) {
        return state.emailCampaignUpdating;
    },
    // Status //////////
    emailCampaignNotFound (state) {
        return state.emailCampaignNotFound;
    },
    // Errors //////////
    emailCampaignGetError (state) {
        return state.emailCampaignGetError;
    },
    emailCampaignUpdateError (state) {
        return state.emailCampaignUpdateError;
    }
};

const actions = {
    getEmailCampaign ({ commit, state }, { id }) {
        // Start loader.
        commit('setEmailCampaignGetting', true);

        // Reset state indicators and statuses.
        commit('setEmailCampaignNotFound', false);
        // @future : Proposition for UI/UX improvement: Perform reset in here and not in then-catch? Maybe yes!

        // Get EmailCampaign.
        return EmailCampaignService.getEmailCampaign(id)
            .then(({ data }) => {
                // Set data.
                commit('setEmailCampaignBase', cloneDeep(data)); // Clone is required!
                commit('setEmailCampaign', data);
                commit('setEmailCampaignGetError', null);
                commit('setEmailCampaignNotFound', false);

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                commit('setEmailCampaignBase', null);
                commit('setEmailCampaign', null);
                commit('setEmailCampaignGetError', reason);
                commit('setEmailCampaignNotFound', true);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignGetting', false);
            });
    },
    updateEmailCampaign ({ commit, state }) {
        // Start loader.
        commit('setEmailCampaignUpdating', true);

        // Update EmailCampaign.
        return EmailCampaignService.updateEmailCampaign(state.emailCampaign)
            .then(({ data }) => {
                // Set data.
                commit('setEmailCampaignBase', cloneDeep(data)); // Clone is required!
                commit('setEmailCampaign', data);
                commit('setEmailCampaignUpdateError', null);

                return Promise.resolve(data);
            })
            .catch((reason) => {
                // Set data.
                // commit('setEmailCampaign', null);
                commit('setEmailCampaignUpdateError', reason);

                return Promise.reject(reason);
            })
            .finally(() => {
                // Stop loader.
                commit('setEmailCampaignUpdating', false);
            });
    },

    /**
     * Discards changes related to content (only).
     *
     * There is no state indicator for this (high-level) action.
     */
    discardEmailCampaignContentChanges ({ commit, state }) {
        commit('setEmailCampaignField', { field: 'editor', value: state.emailCampaignBase.editor });
        commit('setEmailCampaignField', { field: 'bodyHtml', value: state.emailCampaignBase.bodyHtml });
        commit('setEmailCampaignField', { field: 'builderDataSerialized', value: state.emailCampaignBase.builderDataSerialized });
        // @future (2020-11-05) Reset plain-text version?
        // The thing is that plain-text version is totally different.
        // I think we should not reset the plain-text version.
    },

    /**
     * Reset content (builder data, HTML, etc) and switches the editor.
     *
     * There is no state indicator for this (high-level) action.
     */
    resetEmailCampaignContentAndSwitchEditor ({ commit, state }) {
        commit('setEmailCampaignField', {
            field: 'bodyHtml',
            value: ''
        });
        commit('setEmailCampaignField', {
            field: 'builderDataSerialized',
            value: null
        });
        commit('setEmailCampaignField', {
            field: 'editor',
            value: (state.emailCampaign.editor === 'BUILDER' ? 'HTML' : 'BUILDER')
        });
        // @future (2020-11-05) Reset plain-text version?
        // The thing is that plain-text version is totally different.
        // I think we should not reset the plain-text version.
    },

    resetOperationsSubModule ({ commit }) {
        commit('setEmailCampaignBase', null);
        commit('setEmailCampaign', null);
        // State Indicators
        commit('setEmailCampaignGetting', false);
        commit('setEmailCampaignUpdating', false);
        // Status
        commit('setEmailCampaignNotFound', false);
        // Errors
        commit('setEmailCampaignGetError', null);
        commit('setEmailCampaignUpdateError', null);
    }
};

const mutations = {
    setEmailCampaignBase (state, data) {
        Vue.set(state, 'emailCampaignBase', data);
    },
    setEmailCampaign (state, data) {
        Vue.set(state, 'emailCampaign', data);
    },
    // Mutations for EmailCampaign fields //////////
    setEmailCampaignField (state, { field, value }) {
        Vue.set(state.emailCampaign, field, value);
    },
    // State Indicators //////////
    setEmailCampaignGetting (state, data) {
        Vue.set(state, 'emailCampaignGetting', data);
    },
    setEmailCampaignUpdating (state, data) {
        Vue.set(state, 'emailCampaignUpdating', data);
    },
    // Status //////////
    setEmailCampaignNotFound (state, data) {
        Vue.set(state, 'emailCampaignNotFound', data);
    },
    // Errors //////////
    setEmailCampaignGetError (state, data) {
        Vue.set(state, 'emailCampaignGetError', data);
    },
    setEmailCampaignUpdateError (state, data) {
        Vue.set(state, 'emailCampaignUpdateError', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
