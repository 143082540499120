const defaultLen = 64;
const defaultTail = '...';

/**
 * Text Ellipsis transformation filter.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 2019
 */
export default (text, len = defaultLen, tail = defaultTail) => {
    if (!text) {
        return '';
    }
    if (typeof text !== 'string') {
        return '';
    }
    if (!len) {
        len = defaultLen;
    }
    if (typeof len !== 'number') {
        len = defaultLen;
    }
    if (text.length <= len) {
        return text;
    }
    if (tail === null) {
        tail = defaultTail;
    }
    if (typeof tail !== 'string') {
        tail = defaultTail;
    }
    return text.substring(0, len) + tail;
};
