/****************************************************************************************************
 * Workspace sub-module of people store module.
 ****************************************************************************************************/

import cloneDeep from 'lodash/cloneDeep';
// import isEqual from 'lodash/isEqual';
import { WorkspaceService } from '@/common/services/api.service';

const DEFAULT_WORKSPACE = {
    id: null,
    name: 'Default',
    view: 'DEFAULT',
    searchDetails: {
        genericCriteria: null,
        sorters: [],
        page: 0,
        size: 30
    },
    // AbstractAuditingEntity //////////
    meta: {
        createdBy: '',
        createdDate: null,
        lastModifiedBy: '',
        lastModifiedDate: null
    }
};

function getDefaultWorkspace () {
    return cloneDeep(DEFAULT_WORKSPACE);
}

// State.
const state = {
    workspaceFetching: false,
    workspaceUpdating: false,
    workspaceCreating: false,
    workspaceDeleting: false,
    workspaceFetchError: false,
    workspaceUpdateError: false,
    workspaceCreateError: false,
    workspaceDeleteError: false,
    workspaceBase: getDefaultWorkspace(),
    workspaceWorking: getDefaultWorkspace()
};

// Getters.
const getters = {
    /*
    workspaceStateChanging (state) {
        return state.workspaceCreating || state.workspaceUpdating || state.workspaceFetching || state.workspaceDeleting;
    },
    */
    workspaceBase (state) {
        return state.workspaceBase;
    },
    workspaceWorking (state) {
        return state.workspaceWorking;
    },
    /*
    workspaceChanged (state) {
        return !isEqual(state.workspaceWorking, state.workspaceBase);
    },
    */

    // Helpers //////////
    workspaceWorkingIsDefault (state) {
        return state.workspaceWorking.id === null;
    },
    workspaceWorkingName (state) {
        return state.workspaceWorking.name;
    },
    workspaceWorkingView (state) {
        return state.workspaceWorking.view;
    }
};

// Actions.
const actions = {
    createWorkspace ({ commit, state }) {
        // Start Loader.
        commit('setWorkspaceCreating', true);

        // Reset error.
        commit('setWorkspaceCreateError', null);

        return WorkspaceService.createWorkspace(state.workspaceWorking)
            .then(({ data }) => {
                commit('setWorkspaceBase', data);
                commit('setWorkspaceWorking', data);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setWorkspaceCreateError', error);
                // We do not reset the workspace because we expect that a workspace is already loaded.
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setWorkspaceCreating', false);
            });
    },
    updateWorkspace ({ commit, state }) {
        // Start Loader.
        commit('setWorkspaceUpdating', true);

        // Reset error.
        commit('setWorkspaceUpdateError', null);

        return WorkspaceService.updateWorkspace(state.workspaceWorking)
            .then(({ data }) => {
                commit('setWorkspaceBase', data);
                commit('setWorkspaceWorking', data);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setWorkspaceUpdateError', error);
                // We do not reset the workspace because we expect that a workspace is already loaded.
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setWorkspaceUpdating', false);
            });
    },
    deleteWorkspace ({ commit, state }) {
        // Start Loader.
        commit('setWorkspaceDeleting', true);

        // Reset error.
        commit('setWorkspaceDeleteError', null);

        return WorkspaceService.deleteWorkspace(state.workspaceBase.id)
            .then(() => {
                commit('setWorkspaceBase', getDefaultWorkspace());
                commit('setWorkspaceWorking', getDefaultWorkspace());
                return Promise.resolve(); // You have to redirect... No data exist in store anymore.
            })
            .catch((error) => {
                commit('setWorkspaceDeleteError', error);
                // We do not reset the workspace because we expect that a workspace is already loaded.
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setWorkspaceDeleting', false);
            });
    },
    getWorkspace ({ dispatch, commit, state }, id) {
        // Start Loader.
        commit('setWorkspaceFetching', true);

        // Reset error.
        commit('setWorkspaceFetchError', null);

        return WorkspaceService.getWorkspace(id)
            .then(({ data }) => {
                commit('setWorkspaceBase', data);
                commit('setWorkspaceWorking', data);
                return Promise.resolve(data);
            })
            .catch((error) => {
                // Reset the workspace to ensure integrity.
                // This is temporary because we expect some component to catch the error and redirect.
                commit('setWorkspaceBase', getDefaultWorkspace());
                commit('setWorkspaceWorking', getDefaultWorkspace());
                commit('setWorkspaceFetchError', error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setWorkspaceFetching', false);
            });
    },
    getWorkspaceDefault ({ commit }) {
        commit('setWorkspaceFetchError', null);
        commit('setWorkspaceBase', getDefaultWorkspace());
        commit('setWorkspaceWorking', getDefaultWorkspace());
        commit('setWorkspaceFetching', false);
    },
    resetWorkspaceSubModule ({ commit }) {
        commit('setWorkspaceFetching', false);
        commit('setWorkspaceUpdating', false);
        commit('setWorkspaceCreating', false);
        commit('setWorkspaceDeleting', false);

        commit('setWorkspaceCreateError', null);
        commit('setWorkspaceUpdateError', null);
        commit('setWorkspaceDeleteError', null);
        commit('setWorkspaceFetchError', null);

        commit('setWorkspaceBase', getDefaultWorkspace());
        commit('setWorkspaceWorking', getDefaultWorkspace());
    }
};

// Mutations.
const mutations = {
    setWorkspaceFetching (state, value) {
        state.workspaceFetching = value;
    },
    setWorkspaceUpdating (state, value) {
        state.workspaceUpdating = value;
    },
    setWorkspaceCreating (state, value) {
        state.workspaceCreating = value;
    },
    setWorkspaceDeleting (state, value) {
        state.workspaceDeleting = value;
    },

    setWorkspaceFetchError (state, value) {
        state.workspaceFetchError = value;
    },
    setWorkspaceUpdateError (state, value) {
        state.workspaceUpdateError = value;
    },
    setWorkspaceCreateError (state, value) {
        state.workspaceCreateError = value;
    },
    setWorkspaceDeleteError (state, value) {
        state.workspaceDeleteError = value;
    },

    setWorkspaceBase (state, value) {
        state.workspaceBase = cloneDeep(value);
    },
    setWorkspaceWorking (state, value) {
        state.workspaceWorking = cloneDeep(value);
    },

    // Specifics
    setWorkspaceWorkingName (state, value) {
        if (typeof value !== 'string') return;
        if (value.trim() === '') return;
        state.workspaceWorking.name = value;
    },
    setWorkspaceWorkingView (state, value) {
        if (typeof value !== 'string') return;
        if (value !== 'DEFAULT' && value !== 'COMPACT') return;
        state.workspaceWorking.view = value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
