/**
 * EmailCampaignContent module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 */
const routes = [
    {
        path: '/:tenantId/campaigns/email-campaign-content/:id',
        name: 'email-campaign-content',
        component: () => import(/* webpackChunkName: "emailcampaigncontent" */ '@/modules/EmailCampaignContent/views/EmailCampaignContentView.vue')
    }
];

export default routes;
