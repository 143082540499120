/****************************************************************************************************
 * Application user store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 * @lastModifiedAt 26 June 2020
 ****************************************************************************************************/

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { AccountService } from '@/common/services/api.service';

const state = {
    // Persistence //////////
    userFetching: false,
    user: null,
    userErrorGet: null
};

const getters = {
    // Persistence //////////
    userFetching (state) {
        return state.userFetching;
    },
    user (state) {
        return state.user;
    },
    userLogin (state) {
        return get(state.user, 'login', null);
    },
    userErrorGet (state) {
        return state.userErrorGet;
    }
};

const actions = {
    userFetch ({ commit }) {
        // Start loader.
        commit('userFetchingSet', true);

        return AccountService.getAccount()
            .then(({ data }) => {
                // Set new data and reset error.
                commit('userSet', data);
                commit('userErrorGetSet', null);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('userSet', null);
                commit('userErrorGetSet', error);
                return Promise.reject(error);
            })
            .finally(() => {
                // Stop loader.
                commit('userFetchingSet', false);
            });
    }
};

const mutations = {
    userFetchingSet (state, data) {
        // @future : Validate.
        state.userFetching = data;
    },

    userSet (state, data) {
        state.user = cloneDeep(data);
    },

    userErrorGetSet (state, data) {
        state.userErrorGet = cloneDeep(data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
