import router from '@/router/index';
import Application from './Application.init';

/**
 * AppRouter : registers business logic for all router lifecycle stages.
 */
const AppRouter = {
    init () {
        /**
         * Ensure that Application is initialized.
         * If Application is initialized, perform authentication/authorization checks.
         */
        router.beforeEach((to, from, next) => {
            // Get tenantId from params.
            let tenantId = to.params.tenantId;

            // Check if app is initialized. If not, initialize.
            Application.initialize(tenantId).then((result) => {
                if (result.valid) {
                    Application.beforeRouteCheck(to).then((result2) => {
                        if (!result2.valid) {
                            window.location.replace(Application.getRedirectBasedOnState(to, result2));
                        }
                    });
                } else {
                    window.location.replace(Application.getRedirectBasedOnState(to, result));
                }
            });

            next();
        });
    }
};

export default AppRouter;
