/****************************************************************************************************
 * Application TenantAccount store sub-module.
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 * @lastModifiedAt Thursday 16 December 2021
 ****************************************************************************************************/

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { TenantAccountService } from '@/common/services/api.service';

const state = {
    // Persistence //////////
    tenantAccountFetching: false,
    tenantAccount: null,
    tenantAccountErrorGet: null
};

const getters = {
    // Persistence //////////
    tenantAccountFetching (state) {
        return state.tenantAccountFetching;
    },
    tenantAccount (state) {
        return state.tenantAccount;
    },
    // @future SOS Rename to: tenantAccountTenantId
    tenantAccountId (state) {
        return get(state.tenantAccount, 'tenantId', null);
    },
    tenantAccountAlias (state) {
        return get(state.tenantAccount, 'alias', null);
    },
    tenantAccountDefaultLanguageKey (state) {
        return get(state.tenantAccount, 'defaultLanguageKey', 'EN');
    },
    tenantAccountErrorGet (state) {
        return state.tenantAccountErrorGet;
    }
};

const actions = {
    /**
     * Fetch tenantAccount instance by contextTenantId (the tenantId from URL params).
     */
    tenantAccountFetch ({ state, commit }) {
        // Check content tenantId.
        if (state.contextTenantId === null) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject(null); // @future : Reject null?
        }

        // Start loader.
        commit('tenantAccountFetchingSet', true);

        return TenantAccountService.getTenantAccount(state.contextTenantId)
            .then(({ data }) => {
                // Set new data and reset error.
                commit('tenantAccountSet', data);
                commit('tenantAccountErrorGetSet', null);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('tenantAccountSet', null);
                commit('tenantAccountErrorGetSet', error);
                return Promise.reject(error);
            })
            .finally(() => {
                // Stop loader.
                commit('tenantAccountFetchingSet', false);
            });
    }
};

const mutations = {
    tenantAccountFetchingSet (state, data) {
        // Clean data.
        if (data === null) {
            data = false;
        }
        if (typeof data !== 'boolean') {
            data = false;
        }
        state.tenantAccountFetching = data;
    },

    tenantAccountSet (state, data) {
        // data can be null.
        state.tenantAccount = cloneDeep(data);
    },

    tenantAccountErrorGetSet (state, data) {
        // data can be null.
        state.tenantAccountErrorGet = cloneDeep(data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
