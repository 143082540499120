/****************************************************************************************************
 * Application Store Module.
 *
 * Help: How to detect where application.module.js is used?
 * - Ctrl + F : application/
 * - Ctrl + F : ...mapGetters('application
 * - Ctrl + F : ...mapFields('application
 * - Ctrl + F : ...mapMultiRowFields('application
 * - Ctrl + F : commit('application
 * - Ctrl + F : dispatch('application
 * - Ctrl + F : getters['application
 *
 * @author Dimitris Gkoulis
 * @createdAt 15 February 2020
 * @lastModifiedAt 26 July 2020
 ****************************************************************************************************/

import context from './context.submodule';
import user from './user.submodule';
import access from './access.submodule';
import tenantAccount from './tenantAccount.submodule';
import quotasUi from './quotasUi.submodule';
import quotas from './quotas.submodule';
import unitBalance from './unitBalance.submodule';
import ui from './ui.submodule';
import navigation from './navigation.submodule';
import signals from './signals.submodule';

const state = {
    ...context.state,
    ...user.state,
    ...access.state,
    ...tenantAccount.state,
    ...quotasUi.state,
    ...quotas.state,
    ...unitBalance.state,
    ...ui.state,
    ...navigation.state,
    ...signals.state
};

const getters = {
    ...context.getters,
    ...user.getters,
    ...access.getters,
    ...tenantAccount.getters,
    ...quotasUi.getters,
    ...quotas.getters,
    ...unitBalance.getters,
    ...ui.getters,
    ...navigation.getters,
    ...signals.getters
};

const actions = {
    ...context.actions,
    ...user.actions,
    ...access.actions,
    ...tenantAccount.actions,
    ...quotasUi.actions,
    ...quotas.actions,
    ...unitBalance.actions,
    ...ui.actions,
    ...navigation.actions,
    ...signals.actions
};

const mutations = {
    ...context.mutations,
    ...user.mutations,
    ...access.mutations,
    ...tenantAccount.mutations,
    ...quotasUi.mutations,
    ...quotas.mutations,
    ...unitBalance.mutations,
    ...ui.mutations,
    ...navigation.mutations,
    ...signals.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
