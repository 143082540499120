/****************************************************************************************************
 * Person sub-module for Person module
 * Handles Person instance in case of edit.
 ****************************************************************************************************/
import Vue from 'vue';
import {
    PersonService
} from '@/common/services/api.service';

const state = {
    personId: null, // The personId from URL params.
    person: null,
    personError: null,
    personLoading: false
};

const getters = {
    person (state) {
        return state.person;
    }
};

const actions = {
    getPerson ({ commit, state }) {
        commit('setPersonLoading', true);

        const id = state.personId;
        if (id === null) return Promise.reject(new Error('personId must not be null!'));

        // Get Person.
        return PersonService.getPerson(id)
            .then(({ data }) => {
                // Set new data and reset error.
                commit('setPerson', data);
                commit('setPersonError', null);
                return Promise.resolve(data);
            })
            .catch((error) => {
                commit('setPerson', null);
                commit('setPersonError', error);
                return Promise.reject(error);
            })
            .finally(() => {
                commit('setPersonLoading', false);
            });
    },
    resetPersonSubModule ({ commit }) {
        commit('setPersonId', null);
        commit('setPerson', null);
        commit('setPersonError', null);
        commit('setPersonLoading', false);
    }
};

const mutations = {
    setPersonId (state, data) {
        Vue.set(state, 'personId', data);
    },
    setPerson (state, data) {
        Vue.set(state, 'person', data);
    },
    setPersonError (state, data) {
        Vue.set(state, 'personError', data);
    },
    setPersonLoading (state, data) {
        Vue.set(state, 'personLoading', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
