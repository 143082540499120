/****************************************************************************************************
 * Person store module.
 *
 * IMPORTANT: this modules has a sub-module that uses genericListing.module.js
 * Be careful with state keys!
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import Vue from 'vue';

import schemaDefinitionSpecificProvider from '@/store/shared/df-specific.submodule';

import person from './person.submodule';
import details from './details.submodule';
import personLifeEvents from './person-life-events.submodule';

const schemaDefinition = schemaDefinitionSpecificProvider('person');

const state = {
    ...schemaDefinition.state,
    ...person.state,
    ...details.state,
    ...personLifeEvents.state,

    initializing: false
};

const getters = {
    ...schemaDefinition.getters,
    ...person.getters,
    ...details.getters,
    ...personLifeEvents.getters,

    initializing (state) {
        return state.initializing;
    },

    // UI state and flags
    stateIsChanging (state) {
        return state.initializing || state.saving;
    },
    displayEmptyState (state) {
        return state.initializing === false && state.person === null && state.personError !== null;
    },
    displayMain (state) {
        return state.initializing === false && state.person !== null;
    }
};

const actions = {
    ...schemaDefinition.actions,
    ...person.actions,
    ...details.actions,
    ...personLifeEvents.actions,

    async initializeModule ({ dispatch, commit }) {
        commit('setInitializing', true);

        const schemaDefinition = await dispatch('getSchemaDefinition').then((data) => data).catch(() => null);
        const person = await dispatch('getPerson').then((data) => data).catch(() => null);

        // Check person.
        if (schemaDefinition === null || person === null) {
            commit('setInitializing', false);
            // @future IDEA: Return both schemaDefinitionError and personError.
            return Promise.reject(new Error('schemaDefinition and person must not be null'));
        }

        // Init 'details' sub-module.
        await dispatch('initializeDetailsSubModule').then(() => void 0).catch(() => void 0);

        commit('setInitializing', false);

        // Init 'personLifeEvents' sub-module (but don't wait for it).
        dispatch('getPersonLifeEvents').then(() => void 0).catch(() => void 0);

        return Promise.resolve({
            schemaDefinition: schemaDefinition,
            person: person
        });
    },

    async resetModule ({ dispatch, commit }) {
        await dispatch('resetSchemaDefinitionSpecificSubModule');
        await dispatch('resetPersonSubModule');
        await dispatch('resetDetailsSubModule');
        await dispatch('resetPersonLifeEventsSubModule');

        // Reset index state.
        commit('setInitializing', false);
    }
};

const mutations = {
    ...schemaDefinition.mutations,
    ...person.mutations,
    ...details.mutations,
    ...personLifeEvents.mutations,

    setInitializing (state, data) {
        Vue.set(state, 'initializing', data);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
