import cloneDeep from 'lodash/cloneDeep';
import { getField, updateField } from 'vuex-map-fields';
import { GenericBreadMicroserviceConsumer } from '@/common/services/api.service';

// Actions [TYPES].
export const ACTION_GET_DATA = 'getData'; // @future : rename : 'ACTION_GET_ALL_DATA'
export const ACTION_GET_MANY_DATA = 'getManyData';
export const ACTION_GET_MANY_DATA_DIRECT = 'getManyDataDirect';

// Mutations.
export const START_FETCHING = 'startFetching';
export const STOP_FETCHING = 'stopFetching';

export const SET_DATA_COUNT = 'setDataCount';
export const RESET_DATA_COUNT = 'resetDataCount';

export const SET_DATA = 'setData';
export const RESET_DATA = 'resetData';

export const SET_ERROR = 'setError';
export const RESET_ERROR = 'resetError';

export const SET_LISTING_PAGE = 'setListingPage';
export const RESET_LISTING_PAGE = 'setListingPage';

export const SET_LISTING_SIZE = 'setListingSize';
export const RESET_LISTING_SIZE = 'setListingSize';

export const SET_LISTING_SORT_FIELD = 'setListingSortField';
export const RESET_LISTING_SORT_FIELD = 'setListingSortField';

export const SET_LISTING_SORT_TYPE = 'setListingSortType';
export const RESET_LISTING_SORT_TYPE = 'setListingSortType';

export const SET_MANY_QUERY = 'setManyQuery';
// export const RESET_MANY_QUERY = 'setManyQuery';

const defaultListingPage = 0;
const defaultListingSize = 10;
const defaultListingSortField = 'id';
const defaultListingSortType = 'desc';

const defaultListingDetails = {
    listingPage: defaultListingPage,
    listingSize: defaultListingSize,
    listingSortField: defaultListingSortField,
    listingSortType: defaultListingSortType
};

// Defaults and helpers.
const defaultResourcePaths = {
    // API Specific.
    microservice: null,
    createOnePath: null,
    updateOnePath: null,
    getAllPath: null,
    getOnePath: null,
    deleteOnePath: null,
    getManyPath: null
};

export default function (resourcePaths = defaultResourcePaths, listingDetails = defaultListingDetails) {
    // @future : Validate resourcePaths.
    return {
        namespaced: true,
        state: {
            // Persistence
            fetching: false,
            data: [],
            dataCount: 0,
            listingPage: listingDetails.listingPage,
            listingSize: listingDetails.listingSize,
            listingSortField: listingDetails.listingSortField,
            listingSortType: listingDetails.listingSortType,
            error: null,
            manyQuery: null
        },
        getters: {
            fetching (state) {
                return state.fetching;
            },
            data (state) {
                return state.data;
            },
            dataCount (state) {
                return state.dataCount;
            },
            error (state) {
                return state.error;
            },
            listingPage (state) {
                return state.listingPage;
            },
            listingSize (state) {
                return state.listingSize;
            },
            listingSortField (state) {
                return state.listingSortField;
            },
            listingSortType (state) {
                return state.listingSortType;
            },
            manyQuery (state) {
                return state.manyQuery;
            },
            // UI/UX Helpers //////////
            displayListing (state) {
                return state.fetching === false && state.data.length > 0 && state.error === null;
            },
            displayEmptyState (state) {
                return state.fetching === false && state.data.length === 0 && state.error === null;
            },
            displayError (state) {
                return state.fetching === false && state.error !== null;
            },

            /**
             * Use this only on components where the page size cannot be changed.
             */
            displayPagination (state) {
                return state.fetching === false && state.data.length > 0 && state.error === null && state.dataCount > state.listingSize;
            },

            getField
        },
        actions: {
            [ACTION_GET_DATA] ({ commit, state }) {
                // Check if URL has been initialized.
                if (resourcePaths.getAllPath == null) {
                    return;
                }

                commit(START_FETCHING);
                return GenericBreadMicroserviceConsumer.getAll(resourcePaths.getAllPath, state.listingPage, state.listingSize, state.listingSortField, state.listingSortType, resourcePaths.microservice)
                    .then((data) => {
                        // Set new data and reset error.
                        commit(SET_DATA_COUNT, parseInt(data.headers['x-total-count'], 10));
                        commit(SET_DATA, data.data);
                        commit(RESET_ERROR);
                        return Promise.resolve(data.data);
                    })
                    .catch((error) => {
                        // Reset data and set error.
                        commit(RESET_DATA_COUNT);
                        commit(RESET_DATA);
                        commit(SET_ERROR, error);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        commit(STOP_FETCHING);
                    });
            },
            [ACTION_GET_MANY_DATA] ({ dispatch, commit, state }) {
                // Check if URL has been initialized.
                if (resourcePaths.getManyPath == null) {
                    return;
                }

                // If the query is null, dispatch and return ACTION_GET_DATA.
                if (!state.manyQuery) {
                    return dispatch(ACTION_GET_DATA);
                }

                commit(START_FETCHING);
                return GenericBreadMicroserviceConsumer.getMany(resourcePaths.getManyPath, state.manyQuery, resourcePaths.microservice)
                    .then((data) => {
                        // Set new data and reset error.
                        commit(SET_DATA_COUNT, parseInt(data.headers['x-total-count'], 10));
                        commit(SET_DATA, data.data);
                        commit(RESET_ERROR);
                        return Promise.resolve(data.data);
                    })
                    .catch((error) => {
                        // Reset data and set error.
                        commit(RESET_DATA_COUNT);
                        commit(RESET_DATA);
                        commit(SET_ERROR, error);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        commit(STOP_FETCHING);
                    });
            },
            [ACTION_GET_MANY_DATA_DIRECT] ({ dispatch, commit, state }, query) {
                // Check if URL has been initialized.
                if (resourcePaths.getManyPath == null) {
                    return;
                }

                // If the query is null, dispatch and return ACTION_GET_DATA.
                if (!query) {
                    return dispatch(ACTION_GET_DATA);
                }

                commit(START_FETCHING);
                return GenericBreadMicroserviceConsumer.getMany(resourcePaths.getManyPath, query, resourcePaths.microservice)
                    .then((data) => {
                        // Set new data and reset error.
                        commit(SET_DATA_COUNT, parseInt(data.headers['x-total-count'], 10));
                        commit(SET_DATA, data.data);
                        commit(RESET_ERROR);
                        return Promise.resolve(data.data);
                    })
                    .catch((error) => {
                        // Reset data and set error.
                        commit(RESET_DATA_COUNT);
                        commit(RESET_DATA);
                        commit(SET_ERROR, error);
                        return Promise.reject(error);
                    })
                    .finally(() => {
                        commit(STOP_FETCHING);
                    });
            }
        },
        mutations: {
            [START_FETCHING] (state) {
                state.fetching = true;
            },
            [STOP_FETCHING] (state) {
                state.fetching = false;
            },

            [SET_DATA] (state, data) {
                state.data = cloneDeep(data);
            },
            [RESET_DATA] (state) {
                state.data = [];
            },

            [SET_DATA_COUNT] (state, data) {
                state.dataCount = data;
            },
            [RESET_DATA_COUNT] (state) {
                state.dataCount = 0;
            },

            [SET_ERROR] (state, data) {
                // state.error = cloneDeep(data); BUG it's Error and cloneDeep not working.
                state.error = data;
            },
            [RESET_ERROR] (state) {
                state.error = null;
            },

            [SET_LISTING_PAGE] (state, data) {
                state.listingPage = data;
            },
            [RESET_LISTING_PAGE] (state) {
                state.listingPage = defaultListingPage;
            },

            [SET_LISTING_SIZE] (state, data) {
                state.listingSize = data;
            },
            [RESET_LISTING_SIZE] (state) {
                state.listingSize = defaultListingSize;
            },

            [SET_LISTING_SORT_FIELD] (state, data) {
                state.listingSortField = data;
            },
            [RESET_LISTING_SORT_FIELD] (state) {
                state.listingSortField = defaultListingSortField;
            },

            [SET_LISTING_SORT_TYPE] (state, data) {
                state.listingSortType = data;
            },
            [RESET_LISTING_SORT_TYPE] (state) {
                state.listingSortType = defaultListingSortType;
            },

            [SET_MANY_QUERY] (state, data) {
                state.manyQuery = cloneDeep(data);
            },
            [RESET_LISTING_SORT_TYPE] (state) {
                state.manyQuery = null;
            },

            updateField
        }
    };
};
