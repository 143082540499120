/**
 * EmailCampaign module routes.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
const routes = [
    {
        path: '/:tenantId/campaigns/email-campaign/:id',
        name: 'email-campaign',
        component: () => import(/* webpackChunkName: "emailcampaign" */ '@/modules/EmailCampaign/views/EmailCampaignView.vue')
    },
    {
        path: '/:tenantId/campaigns/email-campaign/:id/redirect',
        name: 'email-campaign-redirect',
        component: () => import(/* webpackChunkName: "emailcampaign" */ '@/modules/EmailCampaign/views/EmailCampaignRedirectView.vue')
    }
];

export default routes;
