/****************************************************************************************************
 * People store module.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 3 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import schemaDefinitionSpecificProvider from '@/store/shared/df-specific.submodule';

import workspace from './workspace.submodule';
import people from './people.submodule';
import bgc from './bgc.submodule';
import wsSdPaginationV0 from './ws-sd-pagination.v0.submodule';
import wsSdSortingV0 from './ws-sd-sorting.v0.submodule';
import ui from './ui.submodule';

const schemaDefinition = schemaDefinitionSpecificProvider('person');

const state = {
    ...workspace.state,
    ...schemaDefinition.state,
    ...people.state,
    ...bgc.state,
    ...wsSdPaginationV0.state,
    ...wsSdSortingV0.state,
    ...ui.state
};

const getters = {
    ...workspace.getters,
    ...schemaDefinition.getters,
    ...people.getters,
    ...bgc.getters,
    ...wsSdPaginationV0.getters,
    ...wsSdSortingV0.getters,
    ...ui.getters,

    initializing (state) {
        return state.workspaceCreating ||
            state.workspaceUpdating ||
            state.workspaceFetching ||
            state.workspaceDeleting;
    }
};

const actions = {
    ...workspace.actions,
    ...schemaDefinition.actions,
    ...people.actions,
    ...bgc.actions,
    ...wsSdPaginationV0.actions,
    ...wsSdSortingV0.actions,
    ...ui.actions,

    async resetModule ({ dispatch }) {
        await dispatch('resetWorkspaceSubModule'); // err is not expected
        // bgc sub-module is handled by PeopleView.
        await dispatch('resetPeopleSubModule'); // err is not expected
        // wsSdPaginationV0 - It uses 'workspace' sub-module. No need to reset.
        // wsSdSortingV0 - It uses 'workspace' sub-module. No need to reset.
        // ui - Do not reset. Returning user should see what left behind (per application load).
    }
};

const mutations = {
    ...workspace.mutations,
    ...schemaDefinition.mutations,
    ...people.mutations,
    ...bgc.mutations,
    ...wsSdPaginationV0.mutations,
    ...wsSdSortingV0.mutations,
    ...ui.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
