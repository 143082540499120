<template>
    <div class="core-loader" v-bind:class="{ [paddingClass]: withPadding }">
        <div class="app-preload-loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</template>

<script>
/**
 * Use it only for page loading.
 * CSS exists in public/libs/preload/preload.css
 *
 * @author Dimitris Gkoulis
 * @createdAt 2018
 * @lastModifiedAt 10 November 2020
 */
export default {
    name: 'CoreLoader',
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false
        },
        withPadding: {
            type: Boolean,
            default: false,
            required: false
        },
        paddingClass: {
            type: String,
            default: 'my-5',
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
    .core-loader {
        position: relative;
        width: 100%;
    }
    .app-preload-loader {
        margin: 0 auto;
    }
</style>
